import axios from "axios";

const CURRENT_USER_KEY = "current_user";
const APP_USER_KEY_PREFIX = "app_user_";
const LOGIN_URL = process.env.REACT_APP_URL + "/idengager/login";

// Send auth header

function setAuthHeader(token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

function removeAuthHeader() {
    delete axios.defaults.headers.common["Authorization"];
}

// Store Session Token for admin user

function setCurrentUser(user) {
    localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(user));
}

function removeCurrentUser() {
    localStorage.removeItem(CURRENT_USER_KEY);
}

function getCurrentUser() {
    const user = localStorage.getItem(CURRENT_USER_KEY);

    if (!user) return null;

    return JSON.parse(user);
}

// Store Session Token for specific app user

function setAppUser(appId, user) {
    localStorage.setItem(`${APP_USER_KEY_PREFIX}${appId}`, JSON.stringify(user));
}

function removeAppUser(appId) {
    localStorage.removeItem(`${APP_USER_KEY_PREFIX}${appId}`);
}

function getAppUser(appId) {
    const user = localStorage.getItem(`${APP_USER_KEY_PREFIX}${appId}`);

    if (!user) return null;

    return JSON.parse(user);
}

// Send login request

function handleLoginResponse({ data }, remembered, appId) {
    if (remembered) {
        if (appId) {
            setAppUser(appId, data);
        } else {
            setCurrentUser(data);
        }
    }

    //setAuthHeader(data.sessionToken);
    return data;
}

function login(data, appId = null, appURL = null, appName = null, appMasterKey = null) {
    const config = {
        url: appURL ? `${appURL}/idengager/login` : LOGIN_URL,
        method: "POST",
        data: { username: data.username, password: data.password },
        headers: {
            'Content-Type': 'application/json',
            "Idengager-Application-Id": appName || process.env.REACT_APP_AUTH_APP_ID,
            "Idengager-Master-Key": appMasterKey || process.env.REACT_APP_AUTH_MASTER_KEY
        }
    };

    return axios(config).then(res => handleLoginResponse(res, data.remembered, appId));
}

function logout(appId = null) {
    removeAuthHeader();
    if (appId) {
        removeAppUser(appId);
    } else {
        removeCurrentUser();
    }
}

export {
    login,
    logout,
    getCurrentUser,  // Admin user
    setCurrentUser,  // Admin user
    removeCurrentUser,  // Admin user
    getAppUser,  // Specific app user
    setAppUser,  // Specific app user
    removeAppUser,  // Specific app user
    setAuthHeader,
    removeAuthHeader
}
