export const columns = [
    {
        Header: 'ID',
        accessor: 'objectId', // accessor is the "key" in the data
    },
    {
        Header: 'pushTime',
        accessor: 'pushTime',
    },
    {
        Header: 'status',
        accessor: 'status',
    },
    {
        Header: 'numSent',
        accessor: 'numSent',
    },
    {
        Header: 'payload',
        accessor: 'payload',
        Cell: (props) => {
            return (
                JSON.stringify(props.value)
            );
        }
    },
    {
        Header: 'Created At',
        accessor: 'createdAt',
    },
    {
        Header: 'Audiance',
        accessor: 'query',
        Cell: (props) => {
            return (
                JSON.stringify(props.value)
            );
        }
    },
];