import { Layout, Menu } from 'antd';
import * as ROUTES from '../../constants/routes';
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from 'react';
import homeIcon from '../../icons/dog-house-2.svg';
import appSettingIcon from '../../icons/app-settings.svg';
import editionIcon from '../../icons/edition.svg';
import cloudComputingIcon from '../../icons/cloud-computing.svg';
import spreadSheetIcon from '../../icons/spreadsheet-app.svg';
import releaseIcon from '../../icons/release-app.svg';

function SideMenu() {
    const location = useLocation();
    const [selectedKeys, setSelectedKeys] = useState([]);
    const navigate = useNavigate();

    // Handle Current Route Active
    useEffect(() => {
        if (!ROUTES.INTERNAL_LINKS.includes(location.pathname))
            setSelectedKeys([location.pathname]);
    }, [location.pathname]);

    return (
        <Layout.Sider className="side-menu" trigger={null} collapsible collapsed={true}>
            <Menu className="menu" theme="dark" selectedKeys={selectedKeys} mode="inline">
                <Menu.Item
                    className="menu-item"
                    onClick={() => navigate(ROUTES.CONFIGURE_APP)}
                    key={ROUTES.CONFIGURE_APP}
                    icon={<img src={homeIcon} alt="home-icon" />}
                ></Menu.Item>
                <Menu.Item
                    className="menu-item"
                    onClick={() => navigate(ROUTES.GENERAL_SETTING)}
                    key={ROUTES.GENERAL_SETTING}
                    icon={<img src={appSettingIcon} alt="app-settings-icon" />}
                ></Menu.Item>
                <Menu.Item
                    className="menu-item"
                    onClick={() => navigate(ROUTES.APP_DESIGN)}
                    key={ROUTES.APP_DESIGN}
                    icon={<img src={editionIcon} alt="edition-icon" />}
                ></Menu.Item>
                <Menu.Item
                    className="menu-item"
                    onClick={() => navigate(ROUTES.APP_BACKEND)}
                    key={ROUTES.APP_BACKEND}
                    icon={<img src={cloudComputingIcon} alt="cloud-computing-icon" />}
                ></Menu.Item>
                <Menu.Item
                    className="menu-item"
                    onClick={() => navigate(ROUTES.APP_ANALYTICS)}
                    key={ROUTES.APP_ANALYTICS}
                    icon={<img src={spreadSheetIcon} alt="spread-sheet-icon" />}
                ></Menu.Item>
                <Menu.Item
                    className="menu-item"
                    onClick={() => navigate(ROUTES.RELEASE_APP)}
                    key={ROUTES.RELEASE_APP}
                    icon={<img src={releaseIcon} alt="release-icon" />}
                ></Menu.Item>
            </Menu>
        </Layout.Sider>
    );
}

export default SideMenu;