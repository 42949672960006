import Table from './../../components/Table';
import DataGrid from './../../components/DataGrid';
import MainForm from './../../components/MainForm';
import React, { useCallback, useEffect, useState } from 'react';
import { default_table_item_per_pages } from './../../constants/table_constants';
import { FormattedMessage } from 'react-intl';
import { Form, Modal, Spin } from 'antd';
import useCreateUser from '../../hooks/create_user';
import useGetAllUsers from '../../hooks/get_all_users';
import { withPrivate } from "../../components/PrivateRoute";
import useUpdateUser from '../../hooks/update_user';
import useDeleteUser from '../../hooks/delete_user';
import { toast } from 'react-toastify';
import { user_columns, getUserFields } from '../../constants/users_datagrid';


function ListUsers() {
    const { users, loading, setReLoadUsers } = useGetAllUsers();
    const { storeResponse, storeError, setAddUserData } = useCreateUser(null);
    const { updateError, updateResponse, setUpdatedUserData } = useUpdateUser(null);
    const { setUserId, deleteResponse, deleteError } = useDeleteUser(null);
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [fields, setFields] = useState([]);
    const [editableUser, setEditableUser] = useState(false);
    const [formRef] = Form.useForm();

    const handlePageClick = useCallback((newPageNumber, itemsPerPage) => {
        const newOffset = (newPageNumber * itemsPerPage) % users.length;
        const endOffset = newOffset + itemsPerPage;
        setPageCount(Math.ceil(users.length / itemsPerPage));
        setCurrentItems(users.slice(newOffset, endOffset));
    }, [users]);

    useEffect(() => {
        if (users && users.length > 0) {
            handlePageClick(0, default_table_item_per_pages);
        } else {
            setCurrentItems([]);
        }
    }, [users, handlePageClick]);

    const openModal = () => {
        setFields(getUserFields());
        setIsVisibleModal(true);
        setEditableUser(null);
        formRef.resetFields();
    }

    // Handle User Requests Errors
    useEffect(() => {
        if (storeError) {
            toast(storeError, { type: 'error' });
        }
    }, [storeError]);

    useEffect(() => {
        if (updateError) {
            toast(updateError, { type: 'error' });
        }
    }, [updateError]);

    useEffect(() => {
        if (deleteError) {
            toast(deleteError, { type: 'error' });
        }
    }, [deleteError]);


    // Handle User Requests Responses
    useEffect(() => {
        if (storeResponse) {
            setReLoadUsers(true);
            toast(<FormattedMessage id='store-success' values={{ name: <FormattedMessage id='user' defaultMessage="User" /> }} defaultMessage="Success" />, { type: 'success' });
            setIsVisibleModal(false);
            setPageCount(0);
        }
    }, [storeResponse, setReLoadUsers]);

    useEffect(() => {
        if (updateResponse) {
            setReLoadUsers(true);
            toast(<FormattedMessage id='update-success' values={{ name: <FormattedMessage id='user' defaultMessage="User" /> }} defaultMessage="Success" />, { type: 'success' });
            setIsVisibleModal(false);
            setPageCount(0);
        }
    }, [updateResponse, setReLoadUsers]);

    useEffect(() => {
        if (deleteResponse) {
            setReLoadUsers(true);
            toast(<FormattedMessage id='delete-success' values={{ name: <FormattedMessage id='user' defaultMessage="User" /> }} defaultMessage="Success" />, { type: 'success' });
            setPageCount(0);
        }
    }, [deleteResponse, setReLoadUsers]);


    // Handle Call Back Actions From Table Grid
    const onSubmitCallBack = (formValues) => {
        if (editableUser) {
            formValues.objectId = editableUser.objectId
            setUpdatedUserData(formValues);
        } else {
            setAddUserData(formValues);
        }
    }

    const onDeleteCallBack = (user) => {
        setUserId(user.objectId);
    }

    const onEditCallBack = (user) => {
        formRef.setFieldsValue(user);
        setIsVisibleModal(true);
        setEditableUser(user);
        setFields(getUserFields(true));
    }

    return (
        <DataGrid
            header={<FormattedMessage id='dataGrid-users-management' defaultMessage="User Management" />}
            addNewBTN={true}
            addNewBtnOnClick={openModal}
            content={
                loading ?
                    <div className="spinner">
                        <Spin size="large" />
                    </div>
                    :
                    <>
                        <Table
                            columns={user_columns}
                            data={currentItems}
                            pageCount={pageCount}
                            total={users.length}
                            onPageChange={handlePageClick}
                            initItemPerPages={default_table_item_per_pages}
                            actions={{
                                delete: true,
                                deleteCallBack: onDeleteCallBack,
                                deleteModelName: <FormattedMessage id='user' defaultMessage="User" />,
                                edit: true,
                                editCallBack: onEditCallBack
                            }}
                        />
                        <Modal
                            title={<FormattedMessage id='dataGrid-add-btn-title' defaultMessage="Add New" />}
                            visible={isVisibleModal}
                            footer={null}
                            onCancel={() => setIsVisibleModal(false)}>
                            <MainForm
                                formRef={formRef}
                                fields={fields}
                                onSubmit={onSubmitCallBack}
                            />
                        </Modal>
                    </>
            }>
        </DataGrid>
    );
}

export default withPrivate(ListUsers);