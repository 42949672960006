const labels_columns = [
    {
        Header: 'ID',
        accessor: 'objectId',
    },
    {
        Header: "Description",
        accessor: "description"
    },
    {
        Header: 'Label',
        accessor: 'label',
    },
    {
        Header: 'Language Id',
        accessor: 'languageId',
    }
];


const label_setup_form_fields = [
    {
        name: 'label',
        label: 'Label',
        type: 'text',
        rules: [
            {
                required: true,
                message: 'Please enter label',
            },
        ],
        value: ''
    }
];

export {
    labels_columns,
    label_setup_form_fields
}