import { useEffect, useState } from "react";
import useFetchAll from "./fetch_all";
import { getActiveApp } from "../utils/storage";

export default function useGetSetupLabels() {
    const { data, loading, setRoute, setHeaders } = useFetchAll();
    const [labels, setLabels] = useState([]);
    const [reLoadLabels, setReLoadLabels] = useState(true);

    useEffect(() => {
        if (data && data.params && data.params.Labels) {
            setLabels(data.params.Labels);
        }
    }, [data]);

    useEffect(() => {
        if (loading === false) {
            setReLoadLabels(false);
        }
    }, [loading]);

    useEffect(() => {
        const app = getActiveApp();
        if (reLoadLabels && app) {
            const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/config";
            const headers = {
                "Idengager-Application-Id": app.backendAppName || app.applicationID,
                "Idengager-Master-Key": app.masterKey,
            };
            setHeaders(headers);
            setRoute(url);
        }
    }, [reLoadLabels, setRoute, setHeaders]);

    return { data, labels, loading, setReLoadLabels }
}