import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { getActiveApp } from "../utils/storage";

export default function usePushNotification() {
    const [notificationData, setNotificationData] = useState(null);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const callAPI = useCallback(async (notificationData) => {
        const app = getActiveApp();
        setResponse(null);
        setError(null);
        if (app) {
            try {
                const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/push";
                const headers = {
                    "Idengager-Application-Id": app.backendAppName || app.applicationID,
                    "Idengager-Master-Key": app.masterKey
                };
                setLoading(true);
                notificationData._ApplicationId = app.backendAppName || app.applicationID;
                notificationData._MasterKey = app.masterKey;
                const response = await axios.post(url, notificationData, { headers: headers });
                setResponse(response.data);
                setError(null);
            } catch (err) {
                if (err.response) {
                    setError(err.response.data.error);
                }
            } finally {
                setLoading(false);
                setNotificationData(null);
            }
        }
    }, []);

    useEffect(() => {
        if (notificationData) {
            console.log(notificationData);
            if (notificationData.message_type === 'json') {
                let jsonConv = {};
                try {
                    jsonConv = JSON.parse(notificationData.message)
                } catch (error) {
                    console.log(error);
                }
                callAPI(jsonConv);
            } else {
                callAPI({
                    data: {
                        alert: notificationData.message
                    },
                    where: notificationData.audience,
                });
            }
        }
    }, [notificationData, callAPI])

    return { response, error, loading, setNotificationData }
}