import Table from './../../components/Table';
import DataGrid from './../../components/DataGrid';
import MainForm from './../../components/MainForm';
import { columns } from './../../constants/events_table_columns';
import React, { useCallback, useEffect, useState } from 'react';
import { default_table_item_per_pages } from './../../constants/table_constants';
import { form_fields } from './../../constants/form_add_event_fields';
import { FormattedMessage } from 'react-intl';
import { Modal, Spin } from 'antd';
import useCreateEvent from '../../hooks/create_event';
import useGetAllEvents from '../../hooks/get_all_events';
import { withPrivate } from "../../components/PrivateRoute";


function ListEvents() {
    const { events, loading, setReLoadEvents } = useGetAllEvents();
    const { response, error, setAddEventData, reSetEmpty } = useCreateEvent(null);
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [visible, setVisible] = useState(false);
    const [alertOb, setAlertOb] = useState(null);
    const [fields] = useState(form_fields);
    const [reInitForm, setReInitForm] = useState(false);

    const handlePageClick = useCallback((newPageNumber, itemsPerPage) => {
        const newOffset = (newPageNumber * itemsPerPage) % events.length;
        const endOffset = newOffset + itemsPerPage;
        setPageCount(Math.ceil(events.length / itemsPerPage));
        setCurrentItems(events.slice(newOffset, endOffset));
    }, [events]);

    useEffect(() => {
        if (events && events.length > 0) {
            handlePageClick(0, default_table_item_per_pages);
        }
    }, [events, handlePageClick]);

    function openModal() {
        setVisible(true);
        setAlertOb(null);
    }

    useEffect(() => {
        if (response) {
            setAlertOb({
                type: 'success',
                message: "Event Stored Successfully."
            });
            setPageCount(0);
            setReInitForm(true);
            setReLoadEvents(true);
            reSetEmpty();
            setTimeout(() => {
                setVisible(false);
                setReLoadEvents(false);
            }, 2000);
        }
    }, [response, setReLoadEvents, setReInitForm, reSetEmpty]);

    useEffect(() => {
        setAlertOb({
            type: 'error',
            message: error
        });
    }, [error]);

    function onSubmitCallBack(formValues) {
        setAddEventData(formValues);
    }

    return (
        <DataGrid
            header={<FormattedMessage id='dataGrid-events-management' defaultMessage="Event Management" />}
            addNewBTN={true}
            addNewBtnOnClick={openModal}
            content={
                loading ?
                    <div className="spinner">
                        <Spin size="large" />
                    </div>
                    :
                    <>
                        <Table
                            columns={columns}
                            data={currentItems}
                            pageCount={pageCount}
                            total={events.length}
                            onPageChange={handlePageClick}
                            initItemPerPages={default_table_item_per_pages}
                        />
                        <Modal
                            title={<FormattedMessage id='dataGrid-add-btn-title' defaultMessage="Add New" />}
                            visible={visible}
                            footer={null}
                            onCancel={() => setVisible(false)}>
                            <MainForm
                                formName="store-event"
                                reInitialize={reInitForm}
                                setReInitialize={setReInitForm}
                                alertOb={alertOb}
                                fields={fields}
                                onSubmit={onSubmitCallBack}
                            />
                        </Modal>
                    </>
            }>
        </DataGrid>
    );
}

export default withPrivate(ListEvents);