import colorsHeaderImg from './../../assets/images/colors-header-img.svg';
import Header from '../../components/Header';
import ConfigOverviewSlider from '../../components/ConfigOverviewSlider';
import * as ROUTES from '../../constants/routes';
import { Space } from 'antd';
import { useState } from 'react';
import sliderImage1 from './../../assets/images/colors-slider-1.png';
import { withPrivate } from "../../components/PrivateRoute";


function Logs() {
    const [sliderElemnts] = useState([
        {
            title: "App Logs",
            image: sliderImage1
        }
    ]);

    return (
        <>
            <Header
                background="#9A71D1"
                title="idEngager Logs"
                buttonTitle="Get Start"
                buttonUrl={ROUTES.LIST_LOGS}
                img={colorsHeaderImg}
            />
            <Space className="overview-slider-container">
                <ConfigOverviewSlider
                    elements={sliderElemnts}
                />
            </Space>
        </>
    );
}

export default withPrivate(Logs);