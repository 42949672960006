const device_columns = [
    {
        Header: 'ID',
        accessor: 'objectId',
    },
    {
        Header: 'App Name',
        accessor: 'appName',
    },
    {
        Header: 'Device Type',
        accessor: 'deviceType',
    },
    {
        Header: 'Installation ID',
        accessor: 'installationId',
    },
    {
        Header: 'Device Token',
        accessor: 'deviceToken',
    },
    {
        Header: 'Time Zone',
        accessor: 'timeZone',
    },
    {
        Header: 'App ID',
        accessor: 'appIdentifier',
    },
    {
        Header: 'App Version',
        accessor: 'appVersion',
    },
    {
        Header: 'Created At',
        accessor: 'createdAt'
    },
    {
        Header: 'User',
        accessor: 'user',
        Cell: (props) => {
            return (
                JSON.stringify(props?.value?.username || "No User Attached")
            );
        },
    },
];


const getDeviceFields = (editMode = false) => {
    let formFields = [
        {
            name: 'appName',
            label: 'App Name',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'Please enter application name',
                },
            ],
            value: ''
        },
        {
            name: 'appIdentifier',
            label: 'App Identifier',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'Please enter application identifier',
                },
            ],
            value: ''
        },
        {
            name: 'appVersion',
            label: 'App Version',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'Please enter application version',
                },
            ],
            value: ''
        },
        {
            name: 'badge',
            label: 'badge',
            type: 'number',
            rules: [
                {
                    required: true,
                    message: 'Please enter badge',
                },
            ],
            value: ''
        },
        {
            name: 'deviceType',
            label: 'Device Type',
            type: 'dropdown',
            rules: [
                {
                    required: true,
                    message: 'Please enter device type',
                },
            ],
            options: [
                {
                    key: "ios",
                    value: "IOS"
                },
                {
                    key: "iosx",
                    value: "IOSX"
                },
                {
                    key: "iosx",
                    value: "IOSX"
                },
                {
                    key: "tvos",
                    value: "TVOS"
                },
                {
                    key: "android",
                    value: "Android"
                },
                {
                    key: "windows_phone",
                    value: "Windows Phone"
                }
            ],
            value: 'ios'
        },
        {
            name: 'installationId',
            label: 'Installation Id',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'Please enter installation id',
                },
            ],
            value: ''
        },
        {
            name: 'localeIdentifier',
            label: 'Locale Identifier',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'Please enter locale identifier',
                },
            ],
            value: ''
        },
        {
            name: 'timeZone',
            label: 'Time Zone',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'Please enter time zone',
                },
            ],
            value: ''
        },
        {
            name: 'deviceToken',
            label: 'Device Token',
            type: 'text',
            rules: [],
            value: ''
        },
        {
            name: 'pushType',
            label: 'Push Type',
            type: 'text',
            rules: [],
            value: ''
        },
        {
            name: 'user',
            label: 'User',
            type: 'textarea',
            rules: [],
            value: null
        },
    ];

    // if (editMode === true) {
    //     formFields.forEach(element => {
    //         if (element.name == 'password') {
    //             element.rules = [];
    //         }
    //     });
    // }
    return formFields;
}

export {
    device_columns,
    getDeviceFields
}