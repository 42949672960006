import { useEffect, useState } from "react";
import useFetchAll from "./fetch_all";
import { useAuth } from "../components/AuthProvider";

export default function useGetAllApps() {
    const { data, loading, setRoute, setHeaders } = useFetchAll();
    const [apps, setApps] = useState([]);
    const [reLoadApps, setReLoadApps] = useState(true);
    const { user } = useAuth();

    useEffect(() => {
        if (data && data.results) {
            setApps(data.results);
        }
    }, [data]);

    useEffect(() => {
        if (loading === false) {
            setReLoadApps(false);
        }
    }, [loading]);

    useEffect(() => {
        if (reLoadApps) {
            const url = process.env.REACT_APP_URL + "/idengager/classes/apps?where=" + `{"users":{"__type":"Pointer","className":"_User","objectId":"${user?.objectId}"}}&include=user`//JSON.stringify({ userId: user.objectId });
            const headers = {
                "Idengager-Application-Id": process.env.REACT_APP_AUTH_APP_ID,
                "Idengager-Master-Key": process.env.REACT_APP_AUTH_MASTER_KEY
            };
            setHeaders(headers);
            setRoute(url);
        }
    }, [reLoadApps, setRoute, setHeaders]);

    return { apps, loading, setReLoadApps }
}