import { useEffect, useState } from "react";
import { getActiveApp } from "../utils/storage";
import useFetchAll from "./fetch_all";

export default function useGetAllHooks() {
     /**
   * Fetches hooks
   * lines - maximum number of lines to fetch
   * since - only fetch lines since this Date
   */

    const { data, loading, setRoute, setHeaders } = useFetchAll();
    const [hooks, setHooks] = useState([]);
    const [reLoadHooks, setReLoadHooks] = useState(true);

    useEffect(() => {
        if (data) {
            setHooks(data);
        }
            // console.hook("data"+JSON.stringify([data[0]]));
    }, [data]);

    useEffect(() => {
        if (loading === false) {
            setReLoadHooks(false);
        }
    }, [loading]);

    useEffect(() => {
        const app = getActiveApp();
        if (reLoadHooks && app) {
            const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + '/hooks/functions' ;
            const headers = {
                "Idengager-Application-Id": app.backendAppName || app.applicationID,
                "Idengager-Master-Key": app.masterKey
            };
            setHeaders(headers);
            setRoute(url);
        }
    }, [reLoadHooks, setRoute, setHeaders]);

    return { hooks, loading, setReLoadHooks }
}