import React, { useState, useEffect } from 'react';
import { Modal, List, Button, message, Badge } from 'antd';
import { DeleteOutlined, CheckOutlined } from '@ant-design/icons';
import { useUpdateLetterOfGuarantee, useCreateLetterOfGuarantee } from '../hooks/useLetterOfGuarantee';
import { useUpdateLetterOfCredit, useCreateLetterOfCredit } from '../hooks/useLetterOfCredit';
import { useUpdateLoan, useCreateLoan } from '../hooks/useLoan';

const NotificationModal = ({ visible, onClose }) => {
  const [pendingRequests, setPendingRequests] = useState([]);
  const { update: updateLG } = useUpdateLetterOfGuarantee();
  const { update: updateLC } = useUpdateLetterOfCredit();
  const { update: updateLoan } = useUpdateLoan();
  const { create: createLG, isLoading: isLoadingLG } = useCreateLetterOfGuarantee();
  const { create: createLC, isLoading: isLoadingLC } = useCreateLetterOfCredit();
  const { create: createLoan, isLoading: isLoadingLoan } = useCreateLoan();

  useEffect(() => {
    if (visible) {
      const requests = JSON.parse(localStorage.getItem('pendingRequests')) || [];
      setPendingRequests(requests);
    }
  }, [visible]);

  const handleUpdate = async (request) => {
    try {
      let payload = {
        id: request.id,
        type: request.type,
        action: request.action,
      };

      if (request.action === 'repayment') {
        payload.rePaymentAmount = request.rePaymentAmount;
      } else if (request.action === 'increase amount') {
        if (request.type === 'LG Statement') {
          payload.amount = request.amount;
          payload.bankCharges = request.bankCharges;
        } else {
          payload.totalAmount = request.amount;
        }
      } else if (request.action === 'extend date') {
        if (request.type === 'LC Statement') {
          payload.expiryDate = request.expiryDate;
          payload.bankCharges = request.bankCharges;
        } else if (request.type === 'LG Statement') {
          payload.expirationDate = request.expirationDate;
          payload.bankCharges = request.bankCharges;
        } else {
          payload.finalDueDate = request.finalDueDate;
          payload.bankCharges = request.bankCharges;
        }
      }

      switch (request.type) {
        case 'LG Statement':
          await updateLG(request.id, payload);
          message.success('Request confirmed and LG updated successfully');
          break;
        case 'LC Statement':
          await updateLC(request.id, payload);
          message.success('Request confirmed and LC updated successfully');
          break;
        case 'Loan Statement':
          await updateLoan(request.id, payload);
          message.success('Request confirmed and Loan updated successfully');
          break;
        default:
          throw new Error('Unknown request type');
      }
      removePendingRequest(request.id);
    } catch (error) {
      message.error('Failed to confirm request');
    }
  };

  const handleCreate = async (request) => {
    try {
      if (request.type === 'LC') {
        await createLC(request);
        message.success('Request confirmed and LC created successfully');
      } else if (request.type === 'Loan') {
        await createLoan(request);
        message.success('Request confirmed and Loan created successfully');
      } else {
        await createLG(request);
        message.success('Request confirmed and LG created successfully');
      }
      removePendingRequest(request.id);
    } catch (error) {
      message.error('Failed to confirm request');
    }
  };

  const handleConfirm = (request) => {
    if (request.action === 'extend date' || request.action === 'increase amount' || request.action === 'repayment') {
      handleUpdate(request);
    } else {
      handleCreate(request);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const removePendingRequest = (id) => {
    const currentRequests = JSON.parse(localStorage.getItem('pendingRequests')) || [];
    const updatedRequests = currentRequests.filter(req => req.id !== id);
    localStorage.setItem('pendingRequests', JSON.stringify(updatedRequests));
    setPendingRequests(updatedRequests);
    window.dispatchEvent(new Event('storage'));
    if (updatedRequests.length === 0) {
      onClose();
    }
  };

  const getDescription = (item) => {
    if (item.action === 'increase amount') {
      return (
        <>
          <p>New Total Amount: {item.amount}</p>
        </>
      );
    } else if (item.action === 'extend date') {
      const dateField =
        item.type === 'Loan Statement' ? 'finalDueDate' :
        item.type === 'LC Statement' ? 'expiryDate' : 'expirationDate';
      return `New Date: ${new Date(item[dateField]).toLocaleDateString()}, Bank Charges: ${item.bankCharges}`;
    } else if (item.action === 'repayment') {
      return (
        <>
          <p>Repayment Amount: {item.rePaymentAmount}</p>
          <p>Finance Expense: {item.financeExpense}</p>
          <p>VAT: {item.vat}</p>
          <p>Bank Charges: {item.bankCharges}</p>
          <p>Total Repayment: {item.totalRepayment}</p>
        </>
      );
    } else if (item.type === 'Loan') {
      return (
        <>
          <p>Total Amount: {item.totalAmount}</p>
          <p>Bank Charges: {item.bankCharges}</p>
          <p>VAT: {item.VAT}</p>
        </>
      );
    } else if (item.type === 'LG') {
      return (
        <>
          <p>Margin Amount: {item.marginAmount}</p>
          <p>Bank Charges: {item.bankCharges}</p>
          <p>VAT: {item.VAT}</p>
        </>
      );
    } else if (item.type === 'LC') {
      return (
        <>
          <p>Margin Amount: {item.marginAmount}</p>
          <p>Bank Charges: {item.bankCharges}</p>
          <p>VAT: {item.VAT}</p>
        </>
      );
    }
    return 'No additional details';
  };

  return (
    <Modal
      title="Pending Requests"
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <List
        dataSource={pendingRequests}
        renderItem={item => (
          <List.Item
            actions={[
              <Button
                type="primary"
                icon={<CheckOutlined />}
                onClick={() => handleConfirm(item)}
                loading={item.type === 'LC' ? isLoadingLC : item.type === 'Loan' ? isLoadingLoan : isLoadingLG}
                style={{ backgroundColor: '#52c41a', borderColor: '#52c41a' }}
              >
                Confirm
              </Button>,
              <Button
                type="primary"
                danger
                icon={<DeleteOutlined />}
                onClick={() => removePendingRequest(item.id)}
              >
                Remove
              </Button>
            ]}
          >
            <List.Item.Meta
              title={`${item.type} - ${item.action}`}
              description={getDescription(item)}
            />
          </List.Item>
        )}
      />
      <Button onClick={handleCancel} style={{ marginTop: '10px' }}>Cancel</Button>
    </Modal>
  );
};

export default NotificationModal;
