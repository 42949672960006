import colorsHeaderImg from './../../assets/images/colors-header-img.svg';
import Header2 from '../../components/Header2';
import ConfigOverviewSlider from '../../components/ConfigOverviewSlider';
import * as ROUTES from '../../constants/routes';
import { Space } from 'antd';
import { useState } from 'react';
import sliderImage1 from './../../assets/images/colors-slider-1.png';
import { withPrivate } from "../../components/PrivateRoute";


function Hooks() {
    const [sliderElemnts] = useState([
        {
            title: "App Hooks",
            image: sliderImage1
        }
    ]);

    return (
        <>
            <Header2
                background="#9A71D1"
                title="idEngager Hooks"
                buttonTitle="Get Started"
                // buttonTitle2="Trigger"
                buttonUrl={ROUTES.LIST_HOOKS}
                buttonUrl2={ROUTES.LIST_TRIGGERS}
                img={colorsHeaderImg}
            />
            <Space className="overview-slider-container">
                <ConfigOverviewSlider
                    elements={sliderElemnts}
                />
            </Space>
        </>
    );
}

export default withPrivate(Hooks);