import { getActiveApp } from "./storage";
import { getAppUser } from './auth';

export const getApiUrlAndHeaders = (endpoint) => {
  
  const app = getActiveApp();
  if (!app) {
    throw new Error("Active app is not set. Please ensure the app is correctly initialized.");
  }

  const url = ((app.appURL ? app.appURL + "/idengager" : null) || app.tenantUrl) + endpoint;
  const user = getAppUser(app.objectId); // Get the user for the specific app
  const headers = {
    "Idengager-Application-Id": app.backendAppName || app.applicationID,
    // "Idengager-Master-Key": app.masterKey,
  };
  if (user && user.sessionToken) {
    headers['X-Idengager-Session-Token'] = user.sessionToken;
  }
  return { url, headers };
}
