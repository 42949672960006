export const clamp = (min, value, max) => {
    return Math.max(min, Math.min(value, max));
}

export const isEmpty = value => (
    value === undefined || 
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.length === 0)
);

export const convertArrayToMapById = arr => {
    return arr.reduce((acc, el) => {
        acc.set(el.id, el);
        return acc;
    }, new Map())
}