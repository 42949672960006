import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { getActiveApp } from "../utils/storage";

export default function useCreateObject() {
    const [objectData, setObjectData] = useState(null);
    const [storeObjResponse, setStoreObjResponse] = useState(null);
    const [storeObjError, setStoreObjError] = useState(null);
    const [loadingObj, setLoadingObj] = useState(false);

    const callAPI = useCallback(async (objectData) => {
        setStoreObjResponse(null);
        setStoreObjError(null);
        const app = getActiveApp();
        if (app) {
            try {
                const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/classes/" + objectData.object_name;
                const headers = {
                    "Idengager-Application-Id": app.backendAppName || app.applicationID,
                    "Idengager-Master-Key": app.masterKey,
                };
                setLoadingObj(true);
                const response = await axios.post(url, objectData.columns, { headers: headers });
                setStoreObjResponse(response.data);
                setObjectData(null);
            } catch (err) {
                if (err.response) {
                    setStoreObjError(err.response.data.error);
                }
            } finally {
                setLoadingObj(false);
            }
        }
    }, []);

    useEffect(() => {
        if (objectData) {
            objectData.columns = {};
            objectData.object_columns.forEach((column) => {
                objectData.columns[column] = "";
            });
            callAPI(objectData);
        }
    }, [objectData, callAPI])

    return { storeObjResponse, storeObjError, loadingObj, setObjectData }
}