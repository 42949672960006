export const columns = [

    {
        Header: 'level',
        accessor: 'level',
    },
    {
        Header: 'message',
        accessor: 'message',
    },
    {
        Header: 'timestamp',
        accessor: 'timestamp',
    }
];