import SideMenu from './SideMenu';
import TopNav from './TopNav';
import { Layout } from 'antd';

function AppLayout(props) {
    const { Content } = Layout;

    return (
        <div id="config-app-layout">
            <Layout style={{ minHeight: '100vh' }}>
                <SideMenu />
                <Layout className="site-layout">
                    <Content>
                        {props.content}
                    </Content>
                </Layout>
            </Layout>
        </div>
    );
}

export default AppLayout;