const CURRENT_ACTIVE_APP_KEY = "active_app";

export function setActiveApp(app) {
    localStorage.setItem(CURRENT_ACTIVE_APP_KEY, JSON.stringify(app));
}

export function getActiveApp() {
    const app = localStorage.getItem(CURRENT_ACTIVE_APP_KEY);
    
    if (!app) return null;

    return JSON.parse(app);
}