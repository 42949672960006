import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { getActiveApp } from "../utils/storage";

export default function useCreateAudience() {
    const [audienceData, setAudienceData] = useState(null);
    const [createAudienceResponse, setCreateAudienceResponse] = useState(null);
    const [audienceError, setAudienceError] = useState(null);

    const callAPI = useCallback(async (audienceData) => {
        const app = getActiveApp();
        if (app) {
            try {
                const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/push_audiences";
                const headers = {
                    "Idengager-Application-Id": app.backendAppName || app.applicationID,
                    "Idengager-Master-Key": app.masterKey,
                };
                const response = await axios.post(url, audienceData, { headers: headers });
                setCreateAudienceResponse(response.data);
                setAudienceError(null);
            } catch (err) {
                if (err.response) {
                    setAudienceError(err.response.data.error);
                }
            } finally {
                setAudienceData(null);
            }
        }
    }, []);

    useEffect(() => {
        if (audienceData) {
            console.log(audienceData);
            delete audienceData.createdAt
            audienceData.query = JSON.stringify(audienceData.query);
            callAPI(audienceData);
        }
    }, [audienceData, callAPI])

    return { createAudienceResponse, audienceError, setAudienceData }
}