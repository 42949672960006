import Table from './../../components/Table';
import DataGrid from './../../components/DataGrid';
import { columns } from './../../constants/pushMessages_table_columns';
import React, { useCallback, useEffect, useState } from 'react';
import { default_table_item_per_pages } from './../../constants/table_constants';
import { FormattedMessage } from 'react-intl';
import useGetAllPushMessages from '../../hooks/get_all_pushMessages';
import { withPrivate } from "../../components/PrivateRoute";
import { Spin } from 'antd';

function PushMessages() {
    const { pushMessages, loading } = useGetAllPushMessages();
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);

    const handlePageClick = useCallback((newPageNumber, itemsPerPage) => {
        const newOffset = (newPageNumber * itemsPerPage) % pushMessages.length;
        const endOffset = newOffset + itemsPerPage;
        setPageCount(Math.ceil(pushMessages.length / itemsPerPage));
        setCurrentItems(pushMessages.slice(newOffset, endOffset));
    }, [pushMessages]);

    useEffect(() => {
        if (pushMessages && pushMessages.length > 0) {
            setPageCount(0);
            handlePageClick(0, default_table_item_per_pages);
        }
    }, [pushMessages, handlePageClick]);


    return (
        <DataGrid
            header={<FormattedMessage id='dataGrid-pushMessages-configuration' defaultMessage="PushMessages Configuration" />}
            content={
                loading ?
                    <div className="spinner">
                        <Spin size="large" />
                    </div>
                    :
                    <Table
                        columns={columns}
                        data={currentItems}
                        pageCount={pageCount}
                        total={pushMessages.length}
                        onPageChange={handlePageClick}
                        initItemPerPages={default_table_item_per_pages}
                    />
            }>
        </DataGrid>
    );
}

export default withPrivate(PushMessages);