import { Card } from 'antd';


function ConfigOverviewCard(props) {
    return (
        <Card className="config-overview-card" size="small" style={{ background: props.background }}>
            <img src={props.icon} alt="Application-Icon" />
            <h4 className="card-title">{props.title}</h4>
            <p className="card-desc">{props.description}</p>
        </Card>
    );
}

export default ConfigOverviewCard;