import { Col, Row } from 'antd';
import appInfoImg from '../../../assets/images/app-info-img.png';
import MainForm from '../../../components/MainForm';

function AppInfo({ formRef, onSubmitAppInfo }) {
    return (
        <div className='app-info'>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={16}>
                    <MainForm
                        className="mainform2"
                        onSubmit={onSubmitAppInfo}
                        formRef={formRef}
                        fields={[
                            {
                                name: 'appName',
                                label: 'App Name',
                                type: 'text',
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please enter your name!',
                                    },
                                ],
                                value: ''
                            },
                            {
                                name: 'AppInfo',
                                label: 'App Info',
                                type: 'textarea',
                                rules: [],
                                value: ''
                            },
                        ]}
                    />
                </Col>
                <Col xs={24} md={8}>
                    <img src={appInfoImg} alt="theme-temp" />
                </Col>
            </Row>
        </div>
    );
}

export default AppInfo;