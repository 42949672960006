import { Carousel, Col, Row } from 'antd';

function ConfigOverviewSlider(props) {
    return (
        <Carousel className="config-overview-slider" dots={{ className: "slider-dots" }}>
            {
                props.elements &&
                props.elements.map((element, i) =>
                    <div key={i}>
                        <Row>
                            <Col xs={24} sm={8} className="flex">
                                <h2 className="flex slider-title">{element.title}</h2>
                            </Col>
                            <Col xs={24} sm={16}>
                                <img className="slider-image" src={element.image} alt="slider-img-url" />
                            </Col>
                        </Row>
                    </div>
                )
            }
        </Carousel>
    );
}

export default ConfigOverviewSlider;