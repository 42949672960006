import React from "react";
import useAsync from "../hooks/useAsync";
import { useAuth } from "./AuthProvider";
import { Spin, Checkbox } from "antd";

const initialFormData = {
    username: "",
    password: "",
    remembered: true
};

function LoginForm({ ...delegated }) {
    const [formData, setFormData] = React.useState(initialFormData);
    const { isLoading, isError, run, error, setError } = useAsync();
    const { login } = useAuth();

    const handleSubmit = e => {
        e.preventDefault();

        run(
            login(formData)
        );
    }

    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    return (
        <form className="login-form" onSubmit={handleSubmit} {...delegated}>
            {isError && <div className="error-msg">{error.message}</div>}
            <div className="login-form-group">
                <label>Username*</label>
                <input
                    id="username"
                    name="username"
                    placeholder=""
                    required
                    type="text"
                    value={formData.value}
                    onChange={handleChange}
                />
            </div>

            <div className="login-form-group">
                <label htmlFor="password">Password*</label>
                <input
                    id="password"
                    name="password"
                    placeholder=""
                    required
                    type="password"
                    value={formData.password}
                    onChange={handleChange}
                />
            </div>

            <div className="login-form-group">
                <Checkbox
                    disabled
                    checked={formData.remembered}
                    onChange={e => setFormData({ ...formData, remembered: e.target.checked })}
                >
                    Remember me
                </Checkbox>
            </div>

            <button
                disabled={isLoading}
                className="submit-btn"
            >
                {
                    isLoading ? <Spin size="medium" className="spinner" /> : "Login"
                }
            </button>
        </form>
    )
}

export default LoginForm;