import React from "react";
import { Spin, Modal, Form, Input, Row, Col, Select, Button } from "antd";
import CheckIcon from "../../assets/images/check.svg";
import PlayIcon from "../../assets/images/play-icon.svg";
import useGetAllJobs from "../../hooks/get_all_jobs";
import { withPrivate } from "../../components/PrivateRoute";
import { SearchBar, Table, Tabs, ScheduleDropdown, useJob, JobProvider } from "../../components/Integration";
import "../../assets/css/integration-list.css";

const filterItems = [
    { label: "All", value: "all" },
    { label: "Daily", value: "daily" },
];

function getTime(iso){
    const date = new Date(iso);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const days = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${days}/${month}/${year} ${hours}:${minutes}`
}

const jobColumns = [
    {
        title: "",
        width: "32px",
        render: (_, job) => (
            <div className="icon-wrapper enabled-status">
                <img src={CheckIcon} />
            </div>
        )
    },
    {
        title: "Job Name",
        width: "240px",
        render: (_, { jobName }) => (
            <div className="box gray-box">{jobName}</div>
        )
    },
    {
        title: "Last Run",
        width: "215px",
        render: (_, job) => (
            <div className="box white-box">{job.finishedAt ? getTime(job.finishedAt.iso) : "..."}</div>
        )
    },
    {
        title: "Schedule",
        width: "135px",
        render: (_, job) => (
            <ScheduleDropdown className="box white-box schedules" />
        )
    },
    {
        title: "Run Condition",
        width: "200px",
        render: (_, job) => (
            <div className="box black-box">02:30 am</div>
        )
    },
    {
        title: "",
        width: "24px",
        render: (_, job) => (
            <div className="icon-wrapper run-status-icon">
                <img src={CheckIcon} />
            </div>
        )
    },
    {
        title: "",
        width: "130px",
        render: (_, { status }) => (
            <RunJobStatus status={status} />
        )
    },
    {
        title: "",
        width: "24px",
        render: (_, job) => (
            <RunJobButton />
        )
    },
    {
        title: "",
        width: "135px",
        render: (_, job) => (
            <RunDetails jobName={job.jobName} />
        )
    },
    {
        title: "",
        width: "135px",
        render: () => (
            <JobParameters />
        )
    }
];

const jobTriggerColumns = [
    {
        title: "",
        width: "32px",
        render: (_, job) => (
            <div className="icon-wrapper enabled-status">
                <img src={CheckIcon} />
            </div>
        )
    },
    {
        title: "Trigger Name",
        width: "240px",
        render: (_, job) => (
            <div className="box gray-box">Trigger 1</div>
        )
    },
    {
        title: "Class Name",
        width: "260px",
        render: (_, job) => (
            <div className="box gray-box">Order</div>
        )
    },
    {
        title: "Event",
        width: "215px",
        render: (_, job) => (
            <div className="box white-box">After Save</div>
        )
    },
    {
        title: "Last Run Status",
        width: "130px",
        render: (_, { status }) => {
            return (
                <div className={`box run-status ${status}`}>{status}</div>
            )
        }
    }
]

const types = ["string", "boolean", "number"];


function JobParameters(){
    const [isOpened, setIsOpened] = React.useState(false);
    const { jobParameters, updateParameter, addParameter, removeParameter } = useJob();

    const handleUpdate = (id, field) => e => {
        updateParameter(id, { [field]: e.target.value });
    }

    return (
        <>
            <div className="box white-box pointer" onClick={() => setIsOpened(true)}>
                Job Parameters
            </div>

            <Modal 
                title="Add Job Parameters"
                visible={isOpened}
                onCancel={() => setIsOpened(false)}
                onOk={() => setIsOpened(false)}
                width={600}
            >
                <Form
                    name="basic"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 14 }}
                    labelAlign="left"
                >
                    <Row style={{marginBottom: "16px"}} gutter={[8, 0]}>
                        <Col span={5}>Key:</Col>
                        <Col span={9}>Value:</Col>
                        <Col span={9}>Type:</Col>
                    </Row>

                    {
                        jobParameters.map(({ key, value, type, id }) => (
                            <Row style={{marginBottom: "12px"}} gutter={[8, 0]}>
                                <Col span={5}>
                                    <Input value={key} placeholder="Key" onChange={handleUpdate(id, "key")} />
                                </Col>

                                <Col span={9}>
                                    <Input id={key} onChange={handleUpdate(id, "value")} value={value} />
                                </Col>

                                <Col span={9}>
                                    <Select value={type} style={{width: "100%"}} onChange={value => updateParameter(id, { type: value })}>
                                        {
                                            types.map(t => (
                                                <Select.Option value={t} key={t}>{t}</Select.Option>
                                            ))
                                        }
                                    </Select>
                                </Col>
                                <Col span={1}>
                                    <span onClick={() => removeParameter(id)} className="close-btn">
                                        <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" color="hsl(0deg, 0%, 75%)"><path d="M0 0h24v24H0z" fill="none"></path><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path></svg>
                                    </span>
                                </Col>
                            </Row>
                        ))
                    }

                    <Button style={{width: "100%"}} type="dashed" onClick={addParameter}>
                        Add Parameter
                    </Button>
                </Form>
            </Modal>
        </>
    )
}

function RunJobButton(){
    const { runJob } = useJob();

    return (
        <div className="icon-wrapper play-icon pointer" onClick={runJob}>
            <img src={PlayIcon} />
        </div>
    )
}

function RunJobStatus({ status }){
    const { isLoading } = useJob();

    return (
        <div className={`box run-status ${status}`} key={isLoading ? "loading" : ""}>{isLoading ? "loadig..." : status}</div>
    )
}

function RunDetails({ jobName }){
    const [isOpened, setIsOpened] = React.useState(false);

    return (
        <>
            <div className="run-details-btn pointer" onClick={() => setIsOpened(true)}>Run History</div>

            <Modal
                title="Run Details"
                visible={isOpened}
                footer={null}
                onCancel={() => setIsOpened(false)}
                width={1200}
            >
                {isOpened && <RunDetailsTable jobName={jobName} key={Date.now()} />}
            </Modal>
        </>
    )
}

function RunDetailsTable({ jobName }){
    const { jobs, loading } = useGetAllJobs();

    if(loading){
        return (
            <div className="loader">
                <Spin size="large" />
            </div>
        )
    }

    const filteredJobs = jobs.filter(j => j.jobName === jobName);

    return (
        <Table
            columns={
                [
                    ...jobColumns.filter((_, index) => ![7, 8, 9].includes(index)),
                    {
                        title: "",
                        width: "130px",
                        render: (_, job) => (
                            <DisplayJobParameters params={job.params} />
                        ) 
                    }
                ]
            } 
            dataSource={filteredJobs} 
            rowKey="job-trigger" 
        />
    )
}

function DisplayJobParameters({ params }){
    const [isOpened, setIsOpened] = React.useState(false);

    return (
        <>
            <div className="box white-box pointer" onClick={() => setIsOpened(true)}>
                Show Parameters
            </div>

            <Modal
                visible={isOpened}
                title="Run Parameters"
                onCancel={() => setIsOpened(false)}
                footer={null}
            >
                <p>
                    {JSON.stringify(params)}
                </p>
            </Modal>
        </>
    )
}

// works as antd table 

function filterJobs(jobs){
    const result = [];
    const hash = {};

    for(let job of jobs){
        if(hash[job.jobName]) continue;

        result.push(job);

        hash[job.jobName] = true;
    }

    return result
}

function ListJobs(){
    const [tab, setTab] = React.useState("schedules");
    const [searchValue, setSearchValue] = React.useState("");
    const [filterValue, setFilterValue] = React.useState("all");

    const { jobs, loading } = useGetAllJobs();

    if(loading){
        return (
            <div className="loader">
                <Spin size="large" />
            </div>
        )
    }

    const filteredJobs = filterJobs(jobs);

    return (
        <div className="list-jobs integration-list">
            <h2>Job Management</h2>

            <Tabs
                tab={tab}
                onChangeTab={setTab}
            />

            <div className="content">
                <SearchBar
                    searchValue={searchValue}
                    filterValue={filterValue}
                    onSearchChange={setSearchValue}
                    onFilterChange={setFilterValue}
                    filterItems={filterItems}
                />

                {
                    tab === "schedules" ?
                        <Table 
                            columns={jobColumns} 
                            dataSource={filteredJobs} 
                            rowKey="job" 
                            key="schedules" 
                            renderRow={
                                (children, job) => 
                                    <JobProvider job={job}>
                                        <div className="row">{children}</div>
                                    </JobProvider>
                            } 
                        />
                        :
                        <Table columns={jobTriggerColumns} dataSource={filteredJobs} rowKey="job-trigger" key="trigger" />
                }
            </div>
        </div>
    )
}

export default withPrivate(ListJobs);