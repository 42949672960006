const user_columns = [
    {
        Header: 'ID',
        accessor: 'objectId', // accessor is the "key" in the data
    },
    {
        Header: 'Username',
        accessor: 'username',
    },
    {
        Header: 'email',
        accessor: 'email',
    },
    {
        Header: 'phone',
        accessor: 'phone',
    },
    {
        Header: "Type",
        accessor: "type",
        Cell: ({ value }) => value || "..."
    },
    {
        Header: 'Created At',
        accessor: 'createdAt',
    }
];


const getUserFields = (editMode = false) => {
    let formFields = [
        {
            name: 'username',
            label: 'Username',
            type: 'text',
            rules: [
                {
                    required: true,
                    message: 'Please enter your name!',
                },
            ],
            value: ''
        },
        {
            name: 'email',
            label: 'Email',
            type: 'email',
            rules: [
                {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                },
                {
                    required: true,
                    message: 'Please enter your E-mail!',
                },
            ],
            value: ''
        },
        {
            name: 'phone',
            label: 'Phone',
            type: 'text',
            rules: [],
            value: ''
        },
        {
            name: 'gender',
            label: 'Gender',
            type: 'dropdown',
            rules: [],
            options: [
                {
                    key: "Male",
                    value: "Male"
                },
                {
                    key: "Female",
                    value: "Female"
                }
            ],
            value: 'Male'
        },
        {
            name: 'password',
            label: 'Password',
            type: 'password',
            rules: [
                {
                    required: true,
                    message: 'Please enter your password!',
                },
                {
                    min: 6,
                    message: 'Password must be at least 6 characters',
                },
            ],
            value: ''
        },
        {
            name: 'type',
            label: 'Type',
            type: 'dropdown',
            rules: [],
            options: [
                {
                    key: "internal",
                    value: "Internal"
                },
                {
                    key: "external",
                    value: "External"
                }
            ],
            value: 'internal'
        },
        {
            name: 'send_newsletters',
            label: 'Send Newsletters',
            type: 'boolean',
            rules: [
            ],
            value: true
        },
    ];

    if (editMode === true) {
        formFields.forEach(element => {
            if (element.name == 'password') {
                element.rules = [];
            }
        });
    }
    return formFields;
}

export {
    getUserFields,
    user_columns
}