import { Space, Button } from 'antd';
import { useNavigate } from "react-router-dom";


function Header(props) {
    const navigate = useNavigate();

    // Handle Routes Redirects Navigations
    function redirect(route) {
        if (route)
            navigate(route);
    }

    return (
        <div className="header" style={{ background: props.background }}>
            <Space className="header-container" size={15}>
                <div className="header-content">
                    <h2 className="title">{props.title}</h2>
                    <p>{props.description}</p>
                    <Button id="get-start-btn" onClick={e => redirect(props.buttonUrl)} className="header-button" type="primary" shape="round" size={15}>
                        {props.buttonTitle}
                    </Button>
                </div>
                <img className="header-img" src={props.img} alt="header-main" />
            </Space>
        </div>
    );
}

export default Header;