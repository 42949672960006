import React from 'react';
import { Table, Spin, Alert } from 'antd';
// import { useGetIntegrationData } from '../../hooks/useIntegrationData';

const LGIntegrationTable = ({ lcId }) => {
//   const { data, loading, error } = useGetIntegrationData(lcId);

//   if (loading) return <Spin />;
//   if (error) return <Alert message="Error" description={error.message} type="error" showIcon />;

  const columns = [
    { title: 'Event', dataIndex: 'event', key: 'event' },
    { title: 'Integrate', dataIndex: 'integrate', key: 'integrate' },
    { title: 'Journal Link', dataIndex: 'journalLink', key: 'journalLink', render: (link) => <a href={link}>View</a> },
  ];
//   dataSource={data}
  return <Table columns={columns}  rowKey="id" />;
};

export default LGIntegrationTable;
