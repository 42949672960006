import React from "react";
import { Dropdown, Menu, Button } from "antd";
import FilterIcon from "../assets/images/filter-icon.svg";
import ChevronDown from "../assets/images/chevron-down.svg";
import useRunJob from "../hooks/run_job";
import { notification } from "antd";

const defaultRenderRow = children => <div className="row">{children}</div>

function Table({ columns, dataSource, rowKey, renderRow = defaultRenderRow }){
    return (
        <div className="table jobs-table">
            <div className="row table-head">
                {
                    columns.map(({ title, width, key }) => (
                        <div key={key} style={{width}} className="column">
                            {title}
                        </div>
                    ))
                }
            </div>

            <div className="table-body">
                {
                   dataSource.map(data => {
                       const children = (
                            columns.map(({ dataIndex, render, index, width }) => (
                                <div className="column" key={`${rowKey}-${index}`} style={{width}}>
                                    {render(data?.[dataIndex], data)}
                                </div>
                            ))
                       );

                       return renderRow(children, data);
                    })
                }
            </div>
        </div>
    )
}

function SearchBar({ 
    onSearchChange, 
    onFilterChange,
    filterValue,
    searchValue,
    filterItems
}){
    const handleSearchChange = React.useCallback(
        e => onSearchChange(e.target.value), []
    );

    const handleFilterChange = React.useCallback(
        ({ key }) => onFilterChange(key), []
    );

    const filterMenu = (
        <Menu className="filter-menu" onSelect={handleFilterChange} selectedKeys={[filterValue]}>
            {
                filterItems.map(({ label, value }) => (
                    <Menu.Item key={value}>{label}</Menu.Item>
                ))
            }
        </Menu>
    )

    return (
        <div className="search-bar">
            <Dropdown overlay={filterMenu}>
                <Button className="filter-btn" icon={<img src={FilterIcon} />}>Filter</Button>
            </Dropdown>

            <input 
                type="search"
                onChange={handleSearchChange}
                value={searchValue}
                className="search-input"
            />
        </div>
    )
}

function Tabs({ tab: selectedTab, onChangeTab }){
    const tabs = React.useMemo(() => ["schedules", "triggers"]);

    return (
        <div className="tabs">
            {
                tabs.map(tab => (
                    <div className={tab === selectedTab ? "tab selected" : "tab"} onClick={() => onChangeTab(tab)}>
                        {tab}
                    </div>
                ))
            }
        </div>
    )
}

const options = [
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" }
];

function ScheduleDropdown({ ...delegated }){
    const [option, setOption] = React.useState(options[0]);

    const filteredOptions = options.filter(({ value }) => value !== option.value);

    const menu = (
        <Menu>
            {
                filteredOptions.map(({ value, label }) => (
                    <Menu.Item key={value} onClick={() => setOption({ value, label })}>
                        {label}
                    </Menu.Item>
                ))
            }
        </Menu>
    )

    return (
        <Dropdown overlay={menu}>
            <div {...delegated}>
                <span className="label">{option.label}</span>
                <span className="caret-icon">
                    <img src={ChevronDown} />
                </span>
            </div>
        </Dropdown>
    )
}

// So the job elements can share state with each other

const JobContext = React.createContext();

// we will use the job to run the job

function getObjectFromParameters(params){
    const result = {};

    const types = {string: String, number: Number, boolean: Boolean};

    for(let param of params){
        const Type = types[param.type];
        result[param.key] = Type(param.value);
    }

    return result;
}

let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
}

function JobProvider({ job, children }){
    const [jobParameters, setJobParameters] = React.useState(() => {
        return Object.entries(job.params || {}).reduce((acc, [key, value]) => {
            acc.push({
                key,
                value,
                type: typeof value,
                id: s4()
            });

            return acc;
        }, [])
    });
    
    const { run, isLoading, isSuccess } = useRunJob(job.jobName)

    const runJob = () => {
        if(jobParameters.length === 0){
            notification.error({
                message: "Please set job parameters before running the job"
            });
        }else{
            run(getObjectFromParameters(jobParameters));
        }
    }

    const updateParameter = (id, values) => {
        const newParameters = jobParameters.map(param => param.id === id ? { ...param, ...values } : param);
        setJobParameters(newParameters);
    }

    const addParameter = () => {
        setJobParameters([...jobParameters, {
            key: "",
            value: "",
            type: "string",
            id: s4()
        }])
    }

    const removeParameter = (id) => {
        const filteredParameters = jobParameters.filter(param => param.id !== id);
        setJobParameters(filteredParameters);
    }

    const value = React.useMemo(
        () => ({
            jobParameters,
            runJob,
            isLoading,
            isSuccess,
            updateParameter,
            addParameter,
            removeParameter
        }),
        [jobParameters, isLoading, isSuccess]
    )

    return (
        <JobContext.Provider value={value}>{children}</JobContext.Provider>
    )
}

function useJob(){
    const context = React.useContext(JobContext);

    if(!context){
        throw new Error("useJob must be used inside a job context.")
    }

    return context;
}

export {
    SearchBar,
    Table,
    Tabs,
    ScheduleDropdown,
    useJob,
    JobProvider
}