import { BulbFilled, CheckOutlined } from "@ant-design/icons";
import { Popover, Progress, Skeleton } from "antd";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";

export const removeCamelCase = (str) => {
    return str.replace(/^\w|[A-Z]/g, (m) => " " + m.toUpperCase()).trim();
};

const urls = {
    contractCreation: "/contracts/new?tour=new_contract",
    customerCreation: "/customers/new",
    unitCreation: "/units/new?tour=new_unit",
    projectCreation: "/projects/new?tour=new_project",
    propertyCreation: "/properties/new?tour=new_property",
    reservationCreation: "/reservations/new",
};

const orderedList = [
    "projectCreation",
    "propertyCreation",
    "unitCreation",
    "customerCreation",
    "reservationCreation",
    "contractCreation",
];

function Checklist({ onClose }) {
    const data = {
        projectCreation: false,
        propertyCreation: false,
        unitCreation: false,
        customerCreation: false,
        reservationCreation: false,
        contractCreation: false,
    }

    const checklist = Object.entries(data);

    const progress = Math.round(
        (checklist.reduce((acc, [key, value]) => {
            if (value) acc += 1;
            return acc;
        }, 0) /
            checklist.length) *
            100
    );

    return (
        <div style={{ width: "400px", padding: "12px 8px" }}>
            <h3
                style={{
                    textAlign: "center",
                    fontSize: "30px",
                    fontWeight: "600",
                    margin: "0 0 8px",
                    color: "color: rgb(249, 115, 22)",
                }}
            >
                Welcome on board
            </h3>

            <div
                style={{
                    color: "color: rgb(249, 115, 22)",
                    textAlign: "center",
                    marginBottom: "8px",
                }}
            >
                Here is your current progress
            </div>

            <div style={{ marginBottom: "12px" }}>
                <Progress percent={progress} />
            </div>

            <div>
                {orderedList.map((key) => {
                    const href = urls[key];
                    const isChecked = data[key];

                    return (
                        <Link
                            key={key}
                            to={href}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                paddingTop: "8px",
                                marginBottom: "8px",
                            }}
                            onClick={onClose}
                        >
                            {isChecked ? (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: "24px",
                                        height: "24px",
                                        color: "white",
                                        borderRadius: "50%",
                                    }}
                                >
                                    <CheckOutlined
                                        className="text-[14px]"
                                        style={{ fontSize: "14px", background: "rgb(34, 197, 94)" }}
                                    />
                                </div>
                            ) : (
                                <div
                                    style={{
                                        width: "24px",
                                        height: "24px",
                                        backgroundColor: "rgb(249, 115, 22)",
                                        borderRadius: "50%",
                                    }}
                                ></div>
                            )}

                            <div style={{ margin: "0 8px", fontSize: "18px" }}>
                                {removeCamelCase(key)}
                            </div>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
}

function GetStartedButton() {
    const [isOpened, setIsOpened] = useState(false);

    useEffect(() => {
        if (isOpened) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }
    }, [isOpened]);

    return (
        <Popover
            trigger={["click"]}
            content={
                isOpened ? (
                    <Checklist onClose={() => setIsOpened(false)} />
                ) : null
            }
            placement="topRight"
            open={isOpened}
            onOpenChange={(open) => setIsOpened(open)}
        >
            <button
                onClick={() => setIsOpened(!isOpened)}
                style={{
                    transition: "all 0.3s ease",
                    borderRadius: "30px",
                    minWidth: "120px",
                    height: "48px",
                    background: "rgb(99, 102, 241)",
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)",
                    position: "fixed",
                    left: "10px",
                    bottom: "10px",
                    zIndex: "50",
                    fontSize: "16px",
                    fontWeight: "600",
                    padding: "0 16px",
                    border: "none"
                }}
            >
                {isOpened ? (
                    "Close"
                ) : (
                    <>
                        <BulbFilled />
                        <span
                            className="ltr:ml-2 rtl:mr-2"
                            style={{
                                marginLeft: "8px",
                            }}
                        >
                            Get Started
                        </span>
                    </>
                )}
            </button>
        </Popover>
    );
}

export default GetStartedButton;
