export const columns = [
    {
        Header: 'ID',
        accessor: 'objectId',
    },
    {
        Header: 'User',
        accessor: 'user',
        Cell: (props) => {
            return (
                JSON.stringify(props?.value?.username || "No User Attached")
            );
        },
    },
    {
        Header: 'Expires At',
        accessor: 'expiresAt',
        Cell: (props) => {
            return (
                JSON.stringify(props.value.iso)
            );
        },
    },
    {
        Header: 'Created With',
        accessor: 'createdWith',
        Cell: (props) => {
            return (
                JSON.stringify(props.value)
            );
        },
    },
    {
        Header: 'Device Id',
        accessor: 'installationId'
    },
    {
        Header: 'Created At',
        accessor: 'createdAt'
    },
    {
        Header: 'Restricted',
        accessor: 'restricted',
        Cell: (props) => {
            return (
                JSON.stringify(props.value)
            );
        },
    }
];