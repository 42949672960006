import { DeleteOutlined, EyeOutlined, FormOutlined } from '@ant-design/icons';
import { Empty, Switch, Button, Modal, Table as AntTable } from 'antd';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTable } from 'react-table'
import Paginate from './Paginate';

function adaptColumnsToAntColumn(columns){
    return columns.map(({ Header, accessor, Cell, ...delegated }) => ({
        title: Header,
        dataIndex: accessor,
        key: accessor,
        render: Cell ? (_, record) => Cell({ value: record[accessor] }, record) : undefined,
        ...delegated
    }));
}


function Table({ columns, data, pageCount, total, onPageChange, initItemPerPages, size, actions = { edit: false, delete: false, deleteModelName: "record", view: false, switchAction: false } }) {
    const [selectedIndex, setSelectedIndex] = useState(false);
    const [isVisibleDelModal, setIsVisibleDelModal] = useState(false);
    // const {
    //     getTableProps,
    //     getTableBodyProps,
    //     headerGroups,
    //     rows,
    //     prepareRow,
    // } = useTable({ columns, data })

    const confirmDelete = (index) => {
        setSelectedIndex(index);
        setIsVisibleDelModal(true);
    }

    const deleteRecord = () => {
        actions.deleteCallBack(data[selectedIndex]);
        setIsVisibleDelModal(false);
    }

    function addActionColumn(columns){
        const hasActions = actions.edit || actions.delete || actions.view || actions.switchAction;
    
        if(!hasActions) return columns;
    
        return [...columns, {
            title: "Actions",
            key: "actions",
            render: (_, record, index) => (
                <div className="actions flex justify-space-around table-actions">
                    {actions.edit && <FormOutlined className="edit-icon" onClick={() => actions.editCallBack(record, index)} />}
                    {actions.delete && <DeleteOutlined className="delete-icon" onClick={() => confirmDelete(index)} />}
                    {actions.view && <EyeOutlined className="view-icon" onClick={() => actions.viewCallBack(record, index)} />}
                    {actions.switchAction && <Switch onChange={(checked) => { actions.switchActionCallBack(record, checked) }} defaultChecked={record.status === true ? true : false} />}
                </div>
            )
        }]
    }

    return (
        <>
            {
                (data && data.length > 0)
                    ?
                    <>
                        {/* <div className='overflow-x-auto main-scroll'>
                            <table className="table" {...getTableProps()}>
                                <thead>
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                            {headerGroup.headers.map(column => (
                                                <th {...column.getHeaderProps()}>
                                                    {column.render('Header')}
                                                </th>
                                            ))}
                                            {
                                                (actions.edit || actions.delete || actions.view || actions.switchAction) &&
                                                <th>
                                                    <FormattedMessage id='datagrid-table-actions' defaultMessage="Actions" />
                                                </th>
                                            }
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {rows.map((row, index) => {
                                        prepareRow(row)
                                        return (
                                            <tr {...row.getRowProps()}>
                                                {row.cells.map(cell => {
                                                    return (
                                                        <td {...cell.getCellProps()}>
                                                            {cell.render('Cell')}
                                                        </td>
                                                    )
                                                })}
                                                {
                                                    (actions.edit || actions.delete || actions.view || actions.switchAction) &&
                                                    <td>
                                                        <div className="actions flex justify-space-around">
                                                            {actions.edit && <FormOutlined className="edit-icon" onClick={() => actions.editCallBack(row.original, index)} />}
                                                            {actions.delete && <DeleteOutlined className="delete-icon" onClick={() => confirmDelete(index)} />}
                                                            {actions.view && <EyeOutlined className="view-icon" onClick={() => actions.viewCallBack(row.original, index)} />}
                                                            {actions.switchAction && <Switch onChange={(checked) => { actions.switchActionCallBack(row.original, checked) }} defaultChecked={row.original.status === true ? true : false} />}
                                                        </div>
                                                    </td>
                                                }
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div> */}
                         <div className="overflow-x-auto main-scroll">
                           <AntTable 
                                size={size}
                               pagination={false}
                               columns={
                                   addActionColumn(
                                        adaptColumnsToAntColumn(columns)
                                   )
                               }
                               dataSource={data}
                            />
                        </div>
                        <Paginate
                            pageCount={pageCount}
                            total={total}
                            onPageChange={onPageChange}
                            initItemPerPages={initItemPerPages}
                        />
                        {/* Start Delete Modal */}
                        {
                            actions.delete &&
                            <Modal
                                className='main-delete-modal'
                                title={<FormattedMessage id='dataGrid-table-deleteMessage' values={{ name: actions.deleteModelName }} defaultMessage="Do you want to delete this record" />}
                                visible={isVisibleDelModal}
                                footer={null}
                                onCancel={() => setIsVisibleDelModal(false)}>
                                <Button onClick={() => deleteRecord()} type="danger" className="main-delete-btn">
                                    <FormattedMessage id='dangerBTN-deleteTitle' defaultMessage="Delete" />
                                </Button>
                            </Modal>
                        }
                        {/* End Delete Modal */}
                    </>
                    :
                    <Empty />
            }

        </>
    );
}

export default Table;