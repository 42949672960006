import { Button, Input } from 'antd';
import filterIcon from './../icons/filter-icon.svg';
import searchIcon from './../icons/search-color-icon.svg';

function DataGrid(props) {
    return (
        <div className="datagrid">
            <h2 className="datagrid-heading">{props.header}</h2>
            <div className="table-container">
                {
                    (props.addNewBTN) &&
                    <div className="btn-container">
                        <div className="left-content">
                            {/* <Button className="filter-button" type="primary" size={15}>
                            <img src={filterIcon} alt="filter-icon" /> Filter
                        </Button>
                        <Input className="search-input" prefix={<img src={searchIcon} alt="search-color-icon" />} /> */}
                        </div>
                        <div className="right-content">
                            {
                                props.addNewBTN &&
                                <Button onClick={props.addNewBtnOnClick} className="filter-button" type="success" id="add-btn" size={15}>
                                    Add New
                                </Button>
                            }
                        </div>
                    </div>
                }
                {props.content}
            </div>
        </div>
    );
}

export default DataGrid;