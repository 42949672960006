import { Typography, Button } from "antd";

const { Text } = Typography;

function StepsFooter({
    nextButtonHandler,
    prevButtonHandler,
    currentStep,
    stepsCount,
    ...delegated
}){
    return (
        <div className="steps-footer" {...delegated}>
            {(prevButtonHandler && <Button onClick={prevButtonHandler}>Previous</Button>) || <div></div>}
            <Text className="txt">{currentStep + 1} / {stepsCount}</Text>
            {(nextButtonHandler && <Button onClick={nextButtonHandler}>Next</Button>) || <div></div>}
        </div>
    )
}

export default StepsFooter;