import { Typography } from "antd";
import AutomotiveImage from "../assets/images/automotive.svg";
import LetterOfGuaranteeImage from "../assets/images/letter-of-guarantee.svg";
import DataStorageImage from "../assets/images/data-storage.svg";
import OracleLogo from "../assets/images/oracle-logo.png";
import ElouqaLogo from "../assets/images/elouqa-logo.png";
import DataCenter from "../assets/images/setup-app.png";

const { Text } = Typography;

const SETUP_APPLICATIONS = [
    {
        id: 1,
        name: "IDENGAGER",
        type: "Automotive",
        img: DataCenter
    },
    {
        id: 2,
        name: "IDENGAGER",
        type: "Automotive",
        img: DataCenter
    },
    {
        id: 3,
        name: "IDENGAGER",
        type: "Automotive",
        img: DataCenter
    },
    {
        id: 4,
        name: "IDENGAGER",
        type: "Automotive",
        img: DataCenter
    },
    {
        id: 5,
        name: "IDENGAGER",
        type: "Automotive",
        img: DataCenter
    },
    {
        id: 6,
        name: "IDENGAGER",
        type: "Automotive",
        img: DataCenter
    },
    {
        id: 7,
        name: "IDENGAGER",
        type: "Automotive",
        img: DataCenter
    },
    {
        id: 8,
        name: "IDENGAGER",
        type: "Automotive",
        img: DataCenter
    },
    {
        id: 9,
        name: "IDENGAGER",
        type: "Automotive",
        img: DataCenter
    }
];

const SETUP_INDUSTRIES = [
    {
        id: "automotive",
        title: "IDENGAGER",
        description: "For  Automotive",
        img: AutomotiveImage,
        bg: "#0097C2",
    },
    {
        id: "letter-of-guarantee",
        title: "IDENGAGER",
        description: "For  Letter Of Guarantee",
        img: LetterOfGuaranteeImage,
        bg: "#00C28E"
    },
    {
        id: "field-services",
        title: "IDENGAGER",
        description: "For  Field Services",
        img: DataStorageImage,
        bg: "#004BC2"
    }
];

const SETUP_DATA_SOURCES = [
    {
        id: "oracle-fusion",
        name: (
            <Text>Oracle Fusion</Text>
        ),
        img: OracleLogo,
        fields: [
            {
                label: "Name",
                name: "tenantName",
                placeholder: "Your IDENGAGER Tenant Name",
            },
            {
                label: "Fusion Tenant Url Based Path",
                name: "tenantURL",
                placeholder: "Enter Your Oracle Fusion Tenant URL"
            },
            {
                label: "Fusion Basic Auth User Name",
                name: "authUsername",
                placeholder: "User Name of Your Tenant"
            },
            {
                label: "Fusion Basic Auth Password",
                name: "authPassword",
                placeholder: "Password of Your Tenant"
            }
        ]
    },
    {
        id: "oracle-cpq",
        name: (
            <Text>Oracle CPQ</Text>
        ),
        img: OracleLogo,
        fields: [
            {
                label: "Name",
                name: "tenantName",
                placeholder: "Your IDENGAGER Tenant Name",
            },
            {
                label: "Oracle CPQ Tenant Url Based Path",
                name: "tenantURL",
                placeholder: "Enter Your Oracle CPQ Tenant URL"
            },
            {
                label: "Oracle CPQ Basic Auth User Name",
                name: "authUsername",
                placeholder: "User Name of Your Tenant"
            },
            {
                label: "Oracle CPQ Basic Auth Password",
                name: "authPassword",
                placeholder: "Password of Your Tenant"
            }
        ]
    },
    {
        id: "oracle-elouqa",
        name: (
            <Text>Oracle Elouqa</Text>
        ),
        img: ElouqaLogo,
        fields: [
            {
                label: "Name",
                name: "tenantName",
                placeholder: "Your IDENGAGER Tenant Name",
            },
            {
                label: "Oracle Elouqa Tenant Url Based Path",
                name: "tenantURL",
                placeholder: "Enter Your Oracle Elouqa Tenant URL"
            },
            {
                label: "Oracle Elouqa Basic Auth User Name",
                name: "authUsername",
                placeholder: "User Name of Your Tenant"
            },
            {
                label: "Oracle Elouqa Basic Auth Password",
                name: "authPassword",
                placeholder: "Password of Your Tenant"
            }
        ]
    },
    {
        id: "oracle-e-business-suite",
        name: (
            <Text>
                Oracle E-Business Suite
            </Text>
        ),
        img: OracleLogo,
        fields: [
            {
                label: "Name",
                name: "tenantName",
                placeholder: "Your IDENGAGER Tenant Name",
            },
            {
                label: "Oracle E-Business Suite Tenant Url Based Path",
                name: "tenantURL",
                placeholder: "Enter Your Oracle E-business suite Tenant URL"
            },
            {
                label: "Oracle E-Business Suite Basic Auth User Name",
                name: "authUsername",
                placeholder: "User Name of Your Tenant"
            },
            {
                label: "Oracle E-Business Suite Basic Auth Password",
                name: "authPassword",
                placeholder: "Password of Your Tenant"
            }
        ]
    }
]


export {
    SETUP_APPLICATIONS,
    SETUP_INDUSTRIES,
    SETUP_DATA_SOURCES
}