import './../../assets/css/push-notifications.css';
import DataGrid from './../../components/DataGrid';
import { FormattedMessage } from 'react-intl';
import { Col, Form, Row } from 'antd';
import AudienceTable from '../../components/AudienceTable';
import PushNotificationForm from '../../components/PushNotificationForm';
import NotificationPreview from '../../components/NotificationPreview';
import usePushNotification from './../../hooks/push_notifications';
import { useEffect, useState } from 'react';
import useGetAllAudiences from '../../hooks/get_all_audiences';
import useCreateAudience from '../../hooks/create_audience';
import { withPrivate } from "../../components/PrivateRoute";
import { toast } from 'react-toastify';


function PushNotifications() {
    const { audienceResponse } = useGetAllAudiences();
    const { setAudienceData } = useCreateAudience();
    const [audiences, setAudiences] = useState([]);
    const [selectedAudienceIndex, setSelectedAudienceIndex] = useState(null);
    const { response, error, setNotificationData } = usePushNotification();
    const [formRef] = Form.useForm();

    useEffect(() => {
        setAudiences(audienceResponse);
    }, [audienceResponse]);


    // Handle New Audience Form Submission
    function onSubmitNewAudCallBack(formValues) {
        console.log(formValues);
        let oldAudiences = audiences;
        let newAudience = {
            name: formValues.audience_name,
            createdAt: new Date(),
            query: formValues.query ? JSON.parse(formValues.query) : { deviceType: formValues.platform }
        };
        oldAudiences.push(newAudience);
        setAudiences([...oldAudiences]);
        if (formValues.future_use === true) {
            setAudienceData({ ...newAudience });
        }
    }


    // Handle Form Submission Success
    useEffect(() => {
        if (response) {
            toast(<FormattedMessage id='push-success' defaultMessage="Success" />, { type: 'success' });
            formRef.resetFields();
            setSelectedAudienceIndex(null);
        }
    }, [response]);

    // Handle Form Submission Failure
    useEffect(() => {
        if (error) {
            toast(error, { type: 'error' });
        }
    }, [error]);

    // Handle Push Notification Form Submission
    function onSubmitPushCallBack(formValues) {
        console.log(formValues);
        if (formValues.message_type !== "json") {
            if (selectedAudienceIndex === null) {
                toast(<FormattedMessage id='auidence-required' defaultMessage="Error" />, { type: 'error' });
            }
            else {
                formValues.audience = audiences[selectedAudienceIndex].query;
                setNotificationData(formValues);
            }
        } else {
            setNotificationData(formValues);
        }
    }


    return (
        <DataGrid
            header={<FormattedMessage id='sideMenu-push-notification' defaultMessage="Push Notification" />}
            content={
                <Row justify="space-between">
                    <Col sm={24} md={24} xl={10}>
                        <AudienceTable
                            audiences={audiences}
                            selectedAudienceIndex={selectedAudienceIndex}
                            onAudienceSelect={setSelectedAudienceIndex}
                            onSubmitNewAudience={onSubmitNewAudCallBack}
                        />
                        <PushNotificationForm
                            selectedAudience={selectedAudienceIndex ? audiences[selectedAudienceIndex] : null}
                            formRef={formRef}
                            onSubmit={onSubmitPushCallBack}
                        />
                    </Col>
                    <Col sm={24} md={24} xl={12}>
                        <NotificationPreview />
                    </Col>
                </Row>
            }>
        </DataGrid>
    );
}

export default withPrivate(PushNotifications);