import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { getActiveApp } from "../utils/storage";

export default function useGetAllClasses() {
    const [classes, setClasses] = useState([]);
    const [className, setClassName] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const callAPI = useCallback(async (className) => {
        try {
            const app = getActiveApp();
            const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/classes/";
            const headers = {
                "Idengager-Application-Id": app.backendAppName || app.applicationID,
                "Idengager-Master-Key": app.masterKey,
            };
            setLoading(true);
            const response = await axios.get((url + className), { headers: headers });
            setClasses(response.data.results);
            setClassName(null);
        } catch (err) {
            if (err.response) {
                setError(err.response.data.error);
            }
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        if (className)
            callAPI(className);
    }, [className, callAPI])

    return { classes, setClassName, loadingRecords: loading, error }
}