import Table from './../../components/Table';
import DataGrid from './../../components/DataGrid';
import { columns } from './../../constants/sessions_table_columns';
import React, { useCallback, useEffect, useState } from 'react';
import { default_table_item_per_pages } from './../../constants/table_constants';
import { FormattedMessage } from 'react-intl';
import useGetAllSessions from '../../hooks/get_all_sessions';
import { withPrivate } from "../../components/PrivateRoute";
import { Spin } from 'antd';
import { toast } from 'react-toastify';
import useDeleteSession from '../../hooks/delete_session';

function ListSessions() {
    const { sessions, loading, setReLoadSessions } = useGetAllSessions();
    const { setSessionId, deleteResponse, deleteError } = useDeleteSession();
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);

    const handlePageClick = useCallback((newPageNumber, itemsPerPage) => {
        const newOffset = (newPageNumber * itemsPerPage) % sessions.length;
        const endOffset = newOffset + itemsPerPage;
        setPageCount(Math.ceil(sessions.length / itemsPerPage));
        setCurrentItems(sessions.slice(newOffset, endOffset));
    }, [sessions]);

    useEffect(() => {
        if (sessions && sessions.length > 0) {
            setPageCount(0);
            handlePageClick(0, default_table_item_per_pages);
        } else {
            setCurrentItems([]);
        }
    }, [sessions, handlePageClick]);


    // Handle Session Requests Errors
    useEffect(() => {
        if (deleteError) {
            toast(deleteError, { type: 'error' });
        }
    }, [deleteError]);


    // Handle Session Requests Responses
    useEffect(() => {
        if (deleteResponse) {
            setReLoadSessions(true);
            toast(<FormattedMessage id='delete-success' values={{ name: <FormattedMessage id='session' defaultMessage="Session" /> }} defaultMessage="Success" />, { type: 'success' });
            setPageCount(0);
        }
    }, [deleteResponse, setReLoadSessions]);


    // Handle Call Back Actions From Table Grid
    const onDeleteCallBack = (rule) => {
        setSessionId(rule.objectId);
    }


    return (
        <DataGrid
            header={<FormattedMessage id='dataGrid-sessions-management' defaultMessage="Sessions Management" />}
            content={
                loading ?
                    <div className="spinner">
                        <Spin size="large" />
                    </div>
                    :
                    <Table
                        columns={columns}
                        data={currentItems}
                        pageCount={pageCount}
                        total={sessions.length}
                        onPageChange={handlePageClick}
                        initItemPerPages={default_table_item_per_pages}
                        actions={{
                            // delete: true,
                            // deleteCallBack: onDeleteCallBack,
                            // deleteModelName: <FormattedMessage id='session' defaultMessage="Session" />,
                        }}
                    />
            }>
        </DataGrid>
    );
}

export default withPrivate(ListSessions);