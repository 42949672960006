const color_columns = [
    {
        Header: 'ID',
        accessor: 'id',
    },
    {
        Header: 'Angle',
        accessor: 'angle',
    },
    {
        Header: 'Page ID',
        accessor: 'pageId',
    },
    {
        Header: "Description",
        accessor: "description"
    },
    {
        Header: 'BG Angle',
        accessor: 'bgAngle',
    },
    {
        Header: 'End Color',
        accessor: 'endColor',
        Cell: (props) => {
            return (
                <div className="color-view" style={{ backgroundColor: props.value }}></div>
            );
        }
    },
    {
        Header: 'BG End Color',
        accessor: 'bgEndColor',
        Cell: (props) => {
            return (
                <div className="color-view" style={{ backgroundColor: props.value }}></div>
            );
        }
    },
    {
        Header: 'Image Color',
        accessor: 'imageColor'
    },
    {
        Header: 'Start Color',
        accessor: 'startColor',
        Cell: (props) => {
            return (
                <div className="color-view" style={{ backgroundColor: props.value }}></div>
            );
        }
    },
    {
        Header: 'BG Image Color',
        accessor: 'bgImageColor',
        Cell: (props) => {
            return (
                <div className="color-view" style={{ backgroundColor: props.value }}></div>
            );
        }
    },
    {
        Header: 'Bg Start Color',
        accessor: 'bgStartColor',
        Cell: (props) => {
            return (
                <div className="color-view" style={{ backgroundColor: props.value }}></div>
            );
        }
    },
];

const color_form_fields = [
    {
        name: 'startColor',
        label: 'Start Color Code',
        type: 'color',
        rules: [
            {
                required: true,
                message: 'Please enter start color code',
            },
        ],
        value: '#fff'
    },
    {
        name: 'endColor',
        label: 'End Color Code',
        type: 'color',
        rules: [
            {
                required: true,
                message: 'Please enter end color code',
            },
        ],
        value: '#fff'
    },
    {
        name: 'bgStartColor',
        label: 'BG Start Color Code',
        type: 'color',
        rules: [
            {
                required: true,
                message: 'Please enter BG start color code',
            },
        ],
        value: '#fff'
    },
    {
        name: 'bgEndColor',
        label: 'BG End Color Code',
        type: 'color',
        rules: [
            {
                required: true,
                message: 'Please enter BG end color code',
            },
        ],
        value: '#fff'
    },
    {
        name: 'bgImageColor',
        label: 'BG Image Color Code',
        type: 'color',
        rules: [],
        value: '#fff'
    },
    {
        name: 'imageColor',
        label: 'Image Color Code',
        type: 'color',
        rules: [],
        value: '#fff'
    },
    {
        name: 'angle',
        label: 'Angle',
        type: 'number',
        rules: [
            {
                required: true,
                message: 'Please enter angle',
            },
        ],
        value: ''
    },
    {
        name: 'bgAngle',
        label: 'BG Angle',
        type: 'number',
        rules: [
            {
                required: true,
                message: 'Please enter BG angle',
            },
        ],
        value: ''
    },
    {
        name: 'pageId',
        label: 'Page Id',
        type: 'number',
        rules: [
            {
                required: true,
                message: 'Please enter page ID',
            },
        ],
        value: ''
    },
];

export {
    color_columns,
    color_form_fields,
}