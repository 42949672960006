import { useEffect, useState } from "react";
import useFetchAll from "./fetch_all";
import { getActiveApp } from './../utils/storage';

export default function useGetAllDevices() {
    const { data, loading, setRoute, setHeaders } = useFetchAll();
    const [devices, setDevices] = useState([]);
    const [reLoadDevices, setReLoadDevices] = useState(true);

    useEffect(() => {
        if (data && data.results) {
            setDevices(data.results);
        }
    }, [data]);

    useEffect(() => {
        if (loading === false) {
            setReLoadDevices(false);
        }
    }, [loading]);

    useEffect(() => {
        const app = getActiveApp();
        if (reLoadDevices && app) {
            const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/classes/_Installation?order=-createdAt&include=user";
            const headers = {
                "Idengager-Application-Id": app.backendAppName || app.applicationID,
                "Idengager-Master-Key": app.masterKey,
            };
            setHeaders(headers);
            setRoute(url);
        }
    }, [reLoadDevices, setRoute, setHeaders]);

    return { devices, loading, setReLoadDevices }
}