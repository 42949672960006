import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, message, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import CreateLGForm from '../../components/LG/CreateLGForm';
import { getLettersOfGuarantee, useCreateLetterOfGuarantee } from '../../hooks/useLetterOfGuarantee';

const LetterOfGuaranteePage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { create, loading: createLoading } = useCreateLetterOfGuarantee();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getLettersOfGuarantee();
      setData(result);
    } catch (error) {
      console.error('Error fetching letters of guarantee:', error);
      message.error('Failed to fetch letters of guarantee');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCreate = async (values) => {
    try {
      await create(values);
      message.success('Letter of Guarantee created successfully');
      setIsModalVisible(false);
      fetchData();  // Refresh the data
    } catch (error) {
      console.error('Error creating letter of guarantee:', error);
      message.error('Failed to create letter of guarantee');
    }
  };

  const handleViewDetails = (record) => {
    navigate(`/letter-of-guarantee/${record.objectId}`);
  };

  const columns = [
    {
      title: 'LG Number',
      dataIndex: 'LGNumber',
      key: 'LGNumber',
      sorter: (a, b) => a.LGNumber.localeCompare(b.LGNumber),
    },
    {
      title: 'LG Bank Number',
      dataIndex: 'LGBankNumber',
      key: 'LGBankNumber',
      sorter: (a, b) => a.LGBankNumber.localeCompare(b.LGBankNumber),
    },
    {
      title: 'Origin Type',
      dataIndex: 'originType',
      key: 'originType',
      sorter: (a, b) => a.originType.localeCompare(b.originType),
    },
    {
      title: 'Origin ID',
      dataIndex: 'origin',
      key: 'origin',
      sorter: (a, b) => a.origin.localeCompare(b.origin),
    },
    {
      title: 'Account Type',
      dataIndex: 'accountType',
      key: 'accountType',
      sorter: (a, b) => a.accountType.localeCompare(b.accountType),
    },
    {
      title: 'Account ID',
      dataIndex: 'account',
      key: 'account',
      sorter: (a, b) => a.account.localeCompare(b.account),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: 'Facility Status',
      dataIndex: 'facilityStatus',
      key: 'facilityStatus',
      sorter: (a, b) => a.facilityStatus.localeCompare(b.facilityStatus),
    },
    {
      title: 'LG Type',
      dataIndex: 'LGType',
      key: 'LGType',
      sorter: (a, b) => a.LGType.localeCompare(b.LGType),
    },
    {
      title: 'Bank ID',
      dataIndex: 'bankId',
      key: 'bankId',
      render: (bank) => bank ? bank.objectId : '',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => handleViewDetails(record)}>View Details</Button>
        </Space>
      ),
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div style={{ padding: '24px', paddingTop: '75px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <h2>Letters of Guarantee</h2>
        <Button type="primary" onClick={showModal}>
          Create New LG
        </Button>
      </div>
      <Table 
        columns={columns} 
        dataSource={data} 
        loading={loading} 
        rowKey="objectId" 
        pagination={{ pageSize: 10 }} 
        style={{ marginBottom: '16px' }}
      />
      <Modal
        title="Create New Letter of Guarantee"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <CreateLGForm onSubmit={handleCreate} loading={createLoading} />
      </Modal>
    </div>
  );
};

export default LetterOfGuaranteePage;
