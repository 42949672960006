import { useEffect, useState } from "react";
import { getActiveApp } from "../utils/storage";
import useFetchAll from "./fetch_all";

export default function useGetAllObjects() {
    const { data, loading, setRoute, setHeaders } = useFetchAll();
    const [objects, setObjects] = useState([]);
    const [reLoadObjects, setReLoadObjects] = useState(true);

    useEffect(() => {
        if (data && data.results) {
            let results = [];
            data.results.forEach(element => {
                if (element.className[0] !== "_") {
                    results.push(element);
                }
            });
            setObjects(results);
        }
    }, [data]);

    useEffect(() => {
        if (loading === false) {
            setReLoadObjects(false);
        }
    }, [loading]);

    useEffect(() => {
        const app = getActiveApp();
        if (reLoadObjects && app) {
            const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/schemas";
            const headers = {
                "Idengager-Application-Id": app.backendAppName || app.applicationID,
                "Idengager-Master-Key": app.masterKey,
            };
            setHeaders(headers);
            setRoute(url);
        }
    }, [reLoadObjects, setRoute, setHeaders]);

    return { objects, loadingObjects: loading, setReLoadObjects }
}