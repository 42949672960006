import { useEffect, useState } from "react";
import { getActiveApp } from "../utils/storage";
import useFetchAll from "./fetch_all";

export default function useGetAllJobs() {
     /**
   * Fetches jobs
   * lines - maximum number of lines to fetch
   * since - only fetch lines since this Date
   */

    const { data, loading, setRoute, setHeaders } = useFetchAll();
    const [jobs, setJobs] = useState([]);
    const [reLoadJobs, setReLoadJobs] = useState(true);

    useEffect(() => {
        if (data && data.results) {
            setJobs(data.results);
        }
            // console.job("data"+JSON.stringify([data[0]]));
    }, [data]);

    useEffect(() => {
        if (loading === false) {
            setReLoadJobs(false);
        }
    }, [loading]);

    useEffect(() => {
        const app = getActiveApp();
        if (reLoadJobs && app) {
            const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + '/Classes/_JobStatus' ;
            const headers = {
                "Idengager-Application-Id": app.backendAppName || app.applicationID,
                "Idengager-Master-Key": app.masterKey
            };
            setHeaders(headers);
            setRoute(url);
        }
    }, [reLoadJobs, setRoute, setHeaders]);

    return { jobs, loading, setReLoadJobs }
}