import axios from "axios";
import React from "react";
import { getApiUrlAndHeaders } from "../utils/api";
import useAsync from "./useAsync";

export const getLettersOfGuarantee = async () => {
  const { url, headers } = getApiUrlAndHeaders("/classes/LetterOfGuarantee");
  const response = await axios.get(url, { headers });
  return response.data.results;
};

export function useGetLetterOfGuarantee(id) {
  const async = useAsync();

  const getLetterOfGuarantee = async () => {
    const { url, headers } = getApiUrlAndHeaders("/classes/LetterOfGuarantee/" + id);
    const response = await axios.get(url, { headers });
    return response.data;
  };

  const revalidate = async () => {
    const lg = await getLetterOfGuarantee(id);
    async.setData(lg);
  };

  React.useEffect(() => {
    async.run(getLetterOfGuarantee());
  }, [id]);

  return { ...async, revalidate };
}

export function useCreateLetterOfGuarantee() {
    const { run, ...rest } = useAsync();
  
    const create = async (data) => {
      try {
        const { url, headers } = getApiUrlAndHeaders("/classes/LetterOfGuarantee");
        const response = await run(axios.post(url, data, { headers }));
        console.log('API response:', response);
        return response.data.objectId;
      } catch (error) {
        console.error('Error in createLetterOfGuarantee:', error);
        throw error;
      }
    };
  
    return {
      create,
      ...rest,
    };
  }
export function useUpdateLetterOfGuarantee() {
  const { run, ...rest } = useAsync();

  const update = async (id, data) => {
    const { url, headers } = getApiUrlAndHeaders("/classes/LetterOfGuarantee/" + id);
    const response = await run(axios.put(url, data, { headers }));
    return response.data;
  };

  return {
    update,
    ...rest,
  };
}

export function useRemoveLetterOfGuarantee() {
  const { run, ...rest } = useAsync();

  const remove = async (id) => {
    const { url, headers } = getApiUrlAndHeaders("/classes/LetterOfGuarantee/" + id);
    const response = await run(axios.delete(url, { headers }));
    return response.data;
  };

  return {
    remove,
    ...rest,
  };
}
