import React, { useState } from 'react';
import { Button, Modal, message, Space, Input, InputNumber, Checkbox } from 'antd';
import LCModalContent from './LCModalContent';
import { useUpdateLetterOfCredit } from '../../hooks/useLetterOfCredit';
import moment from 'moment';
const { TextArea } = Input;

const LCFooterActions = ({ id, data, revalidate }) => {
  const { update } = useUpdateLetterOfCredit();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [actionType, setActionType] = useState('');
  const [newAmount, setNewAmount] = useState(null);
  const [newDate, setNewDate] = useState(null);
  const [newTerms, setNewTerms] = useState('');
  const [newBeneficiary, setNewBeneficiary] = useState('');
  const [bankCharges, setBankCharges] = useState(null);
  const [marginIncrease, setMarginIncrease] = useState(null);
  const [includeTaxes, setIncludeTaxes] = useState(false);

  const handleAction = (type) => {
    setActionType(type);
    if (type === 'increaseAmount' || type === 'reduceAmount') {
      setNewAmount(null);
    } else if (type === 'extendDate' || type === 'renewLC') {
      setNewDate(moment(data.expiryDate));
    } else if (type === 'amendLCTerms') {
      setNewTerms(data.terms);
    } else if (type === 'updateBeneficiary') {
      setNewBeneficiary(data.beneficiary);
    } else if (type === 'increaseMarginAmount') {
      setMarginIncrease(null);
    }
    setBankCharges(null);
    setIncludeTaxes(false);
    setIsModalVisible(true);
  };

  const handleModalOk = async () => {
    try {
      setIsModalVisible(false);
      let updatedData = {};
  
      const totalBankCharges = includeTaxes ? calculateTotalTaxableCharges() : bankCharges;
  
      if (actionType === 'increaseAmount' && newAmount !== null) {
        updatedData = {
          amount: data.amount + newAmount,
          bankCharges: totalBankCharges,
          type: 'LC Statement',
          action: 'increase amount'
        };
  
        const pendingRequests = JSON.parse(localStorage.getItem('pendingRequests')) || [];
        pendingRequests.push({ id, ...updatedData });
        localStorage.setItem('pendingRequests', JSON.stringify(pendingRequests));
  
        window.dispatchEvent(new Event('storage'));
        message.success('Request saved. Please confirm from the notification icon.');
        return;
      } else if (actionType === 'extendDate' && newDate !== null) {
        updatedData = {
          expiryDate: newDate.toISOString(),
          bankCharges: totalBankCharges,
          type: 'LC Statement',
          action: 'extend date'
        };
  
        const pendingRequests = JSON.parse(localStorage.getItem('pendingRequests')) || [];
        pendingRequests.push({ id, ...updatedData });
        localStorage.setItem('pendingRequests', JSON.stringify(pendingRequests));
  
        window.dispatchEvent(new Event('storage'));
        message.success('Request saved. Please confirm from the notification icon.');
        return;
      } else if (actionType === 'cancel') {
        updatedData.status = 'Cancelled';
      } else if (actionType === 'reduceAmount' && newAmount !== null) {
        updatedData.amount = data.amount - newAmount;
      } else if (actionType === 'transferLC') {
        // Handle transfer logic here
      } else if (actionType === 'increaseMarginAmount' && marginIncrease !== null) {
        updatedData.marginAmount = (data.marginAmount || 0) + marginIncrease;
      } else if (actionType === 'releaseLC') {
        updatedData.status = 'Released';
      } else if (actionType === 'amendLCTerms' && newTerms !== '') {
        updatedData.terms = newTerms;
      } else if (actionType === 'updateBeneficiary' && newBeneficiary !== '') {
        updatedData.beneficiary = newBeneficiary;
      } else if (actionType === 'renewLC' && newDate !== null) {
        updatedData.expiryDate = newDate.toISOString();
      }
      if (Object.keys(updatedData).length > 0) {
        await update(id, updatedData);
        message.success(`Letter of Credit ${actionType.replace(/([A-Z])/g, ' $1').toLowerCase()} successfully`);
        revalidate();
      } else {
        message.warning('No changes detected');
      }
    } catch (error) {
      message.error(`Failed to ${actionType.replace(/([A-Z])/g, ' $1').toLowerCase()} letter of credit`);
    }
  };
  

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const calculateTotalTaxableCharges = () => {
    if (bankCharges !== null) {
      return bankCharges + (bankCharges * 0.15);
    }
    return 0;
  };

  return (
    <>
      <div style={{ marginTop: 16 }}>
        <Space wrap>
          <Button type="primary" onClick={() => handleAction('extendDate')}>
            Extend Date
          </Button>
          <Button type="primary" onClick={() => handleAction('increaseAmount')}>
            Increase Amount
          </Button>
          <Button type="danger" onClick={() => handleAction('cancel')}>
            Cancel
          </Button>
          <Button type="primary" onClick={() => handleAction('reduceAmount')}>
            Reduce Amount
          </Button>
          <Button type="primary" onClick={() => handleAction('transferLC')}>
            Transfer LC
          </Button>
          <Button type="primary" onClick={() => handleAction('releaseLC')}>
            Release LC
          </Button>
          <Button type="primary" onClick={() => handleAction('amendLCTerms')}>
            Amend LC Terms
          </Button>
          <Button type="primary" onClick={() => handleAction('updateBeneficiary')}>
            Update Beneficiary
          </Button>
          <Button type="primary" onClick={() => handleAction('renewLC')}>
            Renew LC
          </Button>
          <Button type="primary" onClick={() => handleAction('increaseMarginAmount')}>
            Increase Margin Amount
          </Button>
        </Space>
      </div>
      <Modal
        title={`Perform Action: ${actionType.replace(/([A-Z])/g, ' $1')}`}
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        {actionType === 'amendLCTerms' ? (
          <TextArea
            placeholder="Enter new terms"
            value={newTerms}
            onChange={(e) => setNewTerms(e.target.value)}
            rows={4}
          />
        ) : actionType === 'updateBeneficiary' ? (
          <TextArea
            placeholder="Enter new beneficiary"
            value={newBeneficiary}
            onChange={(e) => setNewBeneficiary(e.target.value)}
            rows={4}
          />
        ) : actionType === 'increaseMarginAmount' ? (
          <InputNumber
            min={0}
            placeholder="Enter amount to increase margin"
            value={marginIncrease}
            onChange={(value) => setMarginIncrease(value)}
            style={{ width: '100%' }}
          />
        ) : (
          <>
            <LCModalContent
              actionType={actionType}
              newAmount={newAmount}
              setNewAmount={setNewAmount}
              newDate={newDate}
              setNewDate={setNewDate}
              data={data}
              bankCharges={bankCharges}
              setBankCharges={setBankCharges}
            />
            {bankCharges !== null && (
              <div style={{ marginTop: 16 }}>
                <Checkbox
                  checked={includeTaxes}
                  onChange={(e) => setIncludeTaxes(e.target.checked)}
                >
                  Include Taxes
                </Checkbox>
                {includeTaxes && (
                  <p style={{ fontWeight: 'bold', marginTop: "15px" }}>
                    Total taxable bank charges = {calculateTotalTaxableCharges()}
                  </p>
                )}
              </div>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

export default LCFooterActions;
