import { Col, Row } from 'antd';
import '../../assets/css/configure-app.css';
import SubSideMenu from '../../components/ConfigureApp/SubSideMenu';
import TopNav from '../../components/ConfigureApp/TopNav';
import { withPrivate } from "../../components/PrivateRoute";

function ReleaseApp() {
    return (
        <div className='config-layout'>
            <Row gutter={[0, 0]}>
                <Col xs={0} md={5}>
                    <SubSideMenu activeStep={5} />
                </Col>
                <Col xs={24} md={19}>
                    <div className='config-body'>
                        <TopNav />

                        <div className='body-content'>
                            Content
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default withPrivate(ReleaseApp);