export const form_fields = [
    {
        name: 'logname',
        label: 'Logname',
        type: 'text',
        rules: [
            {
                required: true,
                message: 'Please enter your name!',
            },
        ],
        value: ''
    },
    {
        name: 'email',
        label: 'Email',
        type: 'email',
        rules: [
            {
                type: 'email',
                message: 'The input is not valid E-mail!',
            },
            {
                required: true,
                message: 'Please enter your E-mail!',
            },
        ],
        value: ''
    },
    {
        name: 'password',
        label: 'Password',
        type: 'password',
        rules: [
            {
                required: true,
                message: 'Please enter your password!',
            },
            {
                min: 6,
                message: 'Password must be at least 6 characters',
            },
        ],
        value: ''
    },
    {
        name: 'password_confirmation',
        label: 'Password Confirmation',
        type: 'password',
        rules: [
            {
                required: true,
                message: 'Please confirm your password!',
            },
            ({ getFieldValue }) => ({
                validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                    }

                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                },
            }),
        ],
        value: ''
    },
    {
        name: 'send_newsletters',
        label: 'Send Newsletters',
        type: 'boolean',
        rules: [
        ],
        value: true
    }
];