import { Col, Row } from 'antd';
import themeTemp from '../../../assets/images/theme-temp.png';

function ChooseTemplate({ onSelectTemplate }) {
    return (
        <div className='select-template'>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={8}>
                    <img onClick={() => onSelectTemplate("temp1")} src={themeTemp} alt="theme-temp" />
                </Col>
                <Col xs={24} md={8}>
                    <img onClick={() => onSelectTemplate("temp2")} src={themeTemp} alt="theme-temp" />
                </Col>
                <Col xs={24} md={8}>
                    <img onClick={() => onSelectTemplate("temp3")} src={themeTemp} alt="theme-temp" />
                </Col>
            </Row>
        </div>
    );
}

export default ChooseTemplate;