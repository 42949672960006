import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "../components/AuthProvider";
import { app_in_progress_status_key } from "../constants/apps_constants";

export default function useCreateApp() {
    const url = process.env.REACT_APP_URL + "/idengager/classes/apps";
    const [appData, setAppData] = useState(null);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { user } = useAuth();

    console.log(user);

    const callAPI = useCallback(async (appData) => {
        setResponse(null);
        setError(null);
        try {
            const headers = {
                "Idengager-Application-Id": process.env.REACT_APP_AUTH_APP_ID,
                "Idengager-Master-Key": process.env.REACT_APP_AUTH_MASTER_KEY
            };
            setLoading(true);
            const response = await axios.post(url, appData, { headers: headers });
            setResponse(response.data);
            setAppData(null);
            setError(null);
        } catch (err) {
            if (err.response) {
                setError(err.response.data.error);
            }
        } finally {
            setLoading(false);
        }
    }, [url]);

    useEffect(() => {
        if (appData) {
            let applicationNames = [];
            appData.applications.filter(app => applicationNames.push(app.appName))
            callAPI({
                userId: user.objectId,
                userName: user.username,
                appName: appData.dataSourceConfig.data.tenantName,
                url: appData.dataSourceConfig.data.tenantURL,
                industry: appData.industry,
                status: app_in_progress_status_key,
                appImage: "",
                connectionString: "",
                applications: applicationNames,
                users: [{
                    __type: "Pointer",
                    className: "_User",
                    objectId: user.objectId
                }],
            });
        }
    }, [appData, user, callAPI])

    return { response, error, loading, setAppData }
}