import axios from "axios";
import { useEffect } from "react";
import { getActiveApp } from "../utils/storage";
import useAsync from "./useAsync";

export const getFavorites = async () => {
    const app = getActiveApp();
    const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + '/classes/favorites?include=user,promotion,alert' ;
    const headers = {
        "Idengager-Application-Id": app.backendAppName || app.applicationID,
        "Idengager-Master-Key": app.masterKey
    };
    return (await axios.get(url, { headers: headers })).data.results;
}

export const useFavorites = () => {
    const { run, ...data } = useAsync(); 

    useEffect(() => {
        run(getFavorites());
    }, [run]);

    return data;
}


