
import { BrowserTracing } from "@sentry/tracing";

interface SegmentAnalytics {
  page: (name?: string) => void;
  reset: () => void;
  alias: (newId: string) => void;
  track: (name: string, properties: Record<string, unknown>) => void;
  identify: (userId?: string, traits?: Record<string, unknown>) => void;
  group: (organisationId: string, traits: Record<string, unknown>) => void;
}
interface RudderStackAnalytics {
  page: (name: string) => void;
  reset: () => void;
  ready: () => void;
  load: () => void;
  initialized: boolean;
  alias: (newId: string) => void;
  track: (name: string, properties: Record<string, unknown>) => void;
  identify: (userId?: string, traits?: Record<string, unknown>) => void;
  group: (organisationId: string, traits: Record<string, unknown>) => void;
}


declare global {
  interface Window {
    TRACKING_STRATEGY?: string;
    IDENGAGERCHAT_STORYTIME?: string;
    ISYNC_VERSION?: string;
    API_URL?: string;
    IS_DEMO?: string;
    analytics: SegmentAnalytics;
    rudderanalytics: RudderStackAnalytics;
  }
}
type Config = {
  ui: {
    helpLink: string;
    slackLink: string;
    docsLink: string;
    configurationArchiveLink: string;
    namespaceLink: string;
    normalizationLink: string;
    workspaceId: string;
    tutorialLink: string;
    technicalSupport: string;
  };
  segment: { token: string };
  rudderstack: {token: string; dataplanurl: string};
  idengagerchat: {
    accountId: string;
    baseUrl: string;
    enableStorytime: boolean;
    debug: boolean;
  };
  sentry:{
    dsn: string;
    environment: string;
    release: string;
    integrations: [any];
    tracesSampleRate: number;
  };
  apiUrl: string;
  healthCheckInterval: number;
  isDemo: boolean;
  version?: string;
};

const BASE_DOCS_LINK = "https://docs.isync.idataworkers.com";
const config: Config = {
  ui: {
    technicalSupport: "https://docs.idataworkers.com/faq/technical-support",
    helpLink: "https://idataworkers.com/community",
    slackLink: "https://slack.idataworkers.com",
    docsLink: "https://docs.idataworkers.com",
    normalizationLink: `${BASE_DOCS_LINK}/understanding-isync/connections#isync-basic-normalization`,
    namespaceLink: `${BASE_DOCS_LINK}/understanding-isync/namespaces`,
    configurationArchiveLink:
      "https://docs.idataworkers.com/tutorials/upgrading-isync",
    tutorialLink: "https://www.youtube.com",
    workspaceId: "5ae6b09b-fdec-41af-aaf7-7d94cfc33ef6",
  },
  segment: {
    token:
      window.TRACKING_STRATEGY === "segment"
        ? process.env.REACT_APP_SEGMENT_TOKEN ||
        "oHaVwozgvwA5s4Ixx5WmVbDoML4H4n6N"
        : "",
  },
  rudderstack: {
    token:
      window.TRACKING_STRATEGY === "rudderstack"
        ? process.env.REACT_APP_RUDDER_TOKEN ||
        "1vK4NbOuSMVTaZnUTG8B4PjFeeA"
        : "",
    dataplanurl:
    window.TRACKING_STRATEGY === "rudderstack"
        ? process.env.REACT_APP_RUDDER_DATAPLAN_URL ||
        "https://mivorsahmnofo.dataplane.rudderstack.com"
        : ""
  },
  idengagerchat: {
    accountId: process.env.REACT_APP_IDENGAGER_CHAT_ACCOUNTID || "15a530bd-6ab0-4ccd-8596-465b98767efc",
    baseUrl: process.env.REACT_APP_IDENGAGER_CHAT_BASEURL || "https://beta.chat.idengager.com",
    enableStorytime: window.IDENGAGERCHAT_STORYTIME !== "disabled",
    debug: false
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN || "https://f5bb9d7fe8d6475d8ae282be99485c77@o507169.ingest.sentry.io/6090297",
		environment: process.env.REACT_APP_ENVIRONMENT || "production",
		release: process.env.REACT_APP_SENTRY_RELEASE || "idengager@" + process.env.npm_package_version, // $GIT_SHA 
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.7
  },
  version: window.ISYNC_VERSION,
  apiUrl:
    window.API_URL ||
    process.env.REACT_APP_API_URL ||
    `${window.location.protocol}//${window.location.hostname}:8001/api/v1/`,
  healthCheckInterval: 10000,
  isDemo: window.IS_DEMO === "true"
};

export default config;
