import Table from './../../components/Table';
import DataGrid from './../../components/DataGrid';
import React, { useCallback, useEffect, useState } from 'react';
import { default_table_item_per_pages } from './../../constants/table_constants';
import { FormattedMessage } from 'react-intl';
import useGetAllDevices from '../../hooks/get_all_devices';
import { withPrivate } from "../../components/PrivateRoute";
import { Form, Modal, Spin } from 'antd';
import { toast } from 'react-toastify';
import useDeleteDevice from '../../hooks/delete_device';
import { device_columns, getDeviceFields } from '../../constants/devices_datagrid';
import useUpdateDevice from '../../hooks/update_device';
import useCreateDevice from '../../hooks/create_device';
import MainForm from '../../components/MainForm';

function ListDevices() {
    const { devices, loading, setReLoadDevices } = useGetAllDevices();
    const { storeResponse, storeError, setAddDeviceData } = useCreateDevice(null);
    const { updateError, updateResponse, setUpdatedDeviceData } = useUpdateDevice(null);
    const { setDeviceId, deleteResponse, deleteError } = useDeleteDevice();
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [editableDevice, setEditableDevice] = useState(false);
    const [fields, setFields] = useState([]);
    const [formRef] = Form.useForm();

    const handlePageClick = useCallback((newPageNumber, itemsPerPage) => {
        const newOffset = (newPageNumber * itemsPerPage) % devices.length;
        const endOffset = newOffset + itemsPerPage;
        setPageCount(Math.ceil(devices.length / itemsPerPage));
        setCurrentItems(devices.slice(newOffset, endOffset));
    }, [devices]);

    useEffect(() => {
        if (devices && devices.length > 0) {
            setPageCount(0);
            handlePageClick(0, default_table_item_per_pages);
        } else {
            setCurrentItems([]);
        }
    }, [devices, handlePageClick]);

    const openModal = () => {
        setFields(getDeviceFields());
        setIsVisibleModal(true);
        setEditableDevice(null);
        formRef.resetFields();
    }

    // Handle Device Requests Errors
    useEffect(() => {
        if (storeError) {
            toast(storeError, { type: 'error' });
        }
    }, [storeError]);

    useEffect(() => {
        if (updateError) {
            toast(updateError, { type: 'error' });
        }
    }, [updateError]);

    useEffect(() => {
        if (deleteError) {
            toast(deleteError, { type: 'error' });
        }
    }, [deleteError]);


    // Handle Device Requests Responses  
    useEffect(() => {
        if (storeResponse) {
            setReLoadDevices(true);
            toast(<FormattedMessage id='store-success' values={{ name: <FormattedMessage id='device' defaultMessage="Device" /> }} defaultMessage="Success" />, { type: 'success' });
            setIsVisibleModal(false);
            setPageCount(0);
        }
    }, [storeResponse, setReLoadDevices]);

    useEffect(() => {
        if (updateResponse) {
            setReLoadDevices(true);
            toast(<FormattedMessage id='update-success' values={{ name: <FormattedMessage id='device' defaultMessage="Device" /> }} defaultMessage="Success" />, { type: 'success' });
            setIsVisibleModal(false);
            setPageCount(0);
        }
    }, [updateResponse, setReLoadDevices]);

    useEffect(() => {
        if (deleteResponse) {
            setReLoadDevices(true);
            toast(<FormattedMessage id='delete-success' values={{ name: <FormattedMessage id='device' defaultMessage="Device" /> }} defaultMessage="Success" />, { type: 'success' });
            setPageCount(0);
        }
    }, [deleteResponse, setReLoadDevices]);


    // Handle Call Back Actions From Table Grid
    const onSubmitCallBack = (formValues) => {
        console.log(formValues);
        if (editableDevice) {
            formValues.objectId = editableDevice.objectId
            setUpdatedDeviceData(formValues);
        } else {
            setAddDeviceData(formValues);
        }
    }

    const onDeleteCallBack = (device) => {
        setDeviceId(device.objectId);
    }

    const onEditCallBack = (device) => {
        if (device.user) {
            device.user = JSON.stringify(device.user);
        }
        formRef.setFieldsValue(device);
        setIsVisibleModal(true);
        setEditableDevice(device);
        setFields(getDeviceFields(true));
    }

    return (
        <DataGrid
            header={<FormattedMessage id='dataGrid-devices-configuration' defaultMessage="Devices Configuration" />}
            addNewBTN={true}
            addNewBtnOnClick={openModal}
            content={
                loading ?
                    <div className="spinner">
                        <Spin size="large" />
                    </div>
                    :
                    <>
                        <Table
                            columns={device_columns}
                            data={currentItems}
                            pageCount={pageCount}
                            total={devices.length}
                            onPageChange={handlePageClick}
                            initItemPerPages={default_table_item_per_pages}
                            actions={{
                                delete: true,
                                deleteCallBack: onDeleteCallBack,
                                deleteModelName: <FormattedMessage id='device' defaultMessage="Device" />,
                                edit: true,
                                editCallBack: onEditCallBack
                            }}
                        />
                        <Modal
                            title={<FormattedMessage id='dataGrid-add-btn-title' defaultMessage="Add New" />}
                            visible={isVisibleModal}
                            footer={null}
                            onCancel={() => setIsVisibleModal(false)}>
                            <MainForm
                                formRef={formRef}
                                fields={fields}
                                onSubmit={onSubmitCallBack}
                            />
                        </Modal>
                    </>
            }>
        </DataGrid>
    );
}

export default withPrivate(ListDevices);