import usersHeaderImg from './../../assets/images/users-header-img.svg';
import Header from './../../components/Header';
import ConfigOverviewSlider from './../../components/ConfigOverviewSlider';
import * as ROUTES from './../../constants/routes';
import { Space } from 'antd';
import { useState } from 'react';
import sliderImage1 from './../../assets/images/users-slider-1.png';
import { withPrivate } from "../../components/PrivateRoute";


function Users() {
    const [sliderElemnts] = useState([
        {
            title: "Configure And Create Users",
            image: sliderImage1
        }
    ]);

    return (
        <>
            <Header
                background="#4ACEC7"
                title="idEngager Users"
                buttonTitle="Configure Users"
                buttonUrl={ROUTES.LIST_USERS}
                img={usersHeaderImg}
            />
            <Space className="overview-slider-container">
                <ConfigOverviewSlider
                    elements={sliderElemnts}
                />
            </Space>
        </>
    );
}

export default withPrivate(Users);