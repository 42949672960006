import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { getActiveApp } from "../utils/storage";

export default function useCreateLog() {
    const [logData, setAddLogData] = useState(null);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    function reSetEmpty() {
        setAddLogData(null);
        setResponse(null);
        setError(null);
    }

    const callAPI = useCallback(async (logData) => {
        const app = getActiveApp();
        if (app) {
            try {
                const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/logs";
                const headers = {
                    "Idengager-Application-Id": app.backendAppName || app.applicationID,
                    "Idengager-Master-Key": app.masterKey,
                };
                setLoading(true);
                const response = await axios.post(url, logData, { headers: headers });
                setResponse(response.data);
            } catch (err) {
                if (err.response) {
                    setError(err.response.data.error);
                }
            } finally {
                setLoading(false);
            }
        }
    }, []);

    useEffect(() => {
        if (logData)
            callAPI(logData);
    }, [logData, callAPI])

    return { response, error, loading, setAddLogData, reSetEmpty }
}