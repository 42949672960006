import { Card, Typography } from "antd";
import AsideImage1 from "../../assets/images/aside-img1.svg";
import AsideImage2 from "../../assets/images/aside-img2.svg";
import AsideImage3 from "../../assets/images/aside-img3.svg";
import AsideImage4 from "../../assets/images/aside-img4.svg";
import AsideVideo from "../../assets/images/setup-aside-video.svg";

const { Paragraph } = Typography;

const titles = [
    "Select Your Industry",
    "Select Your Data Soure",
    "Select Your Applications",
    "Setup Verification"
];

const descriptions = [
    "IDENGAGER Products are ready for Industry Specific , User has to start setup by selecting his industry to Automatically prepare IDENGAGER Mobile Applications",
    "IDENGAGER Supports integration with multiple Data Sources Types , Just select your data Source and fill you required information",
    "Select the application features that you need to implement in your IDENGAGER Mobile Application",
    "congratulations , Currently your IDENGAGER Applications preparation has been succeeded.."
];

const images = [
    AsideImage1,
    AsideImage2,
    AsideImage3,
    AsideImage4
]

function Aside({ currentStep }){
    return (
        <Card className="aside-wrapper boarding-card">
            <Paragraph className="aside-title">{titles[currentStep]}</Paragraph>

            <Paragraph className="aside-description">
                {descriptions[currentStep]}
            </Paragraph>

            <div className="aside-img">
                <img 
                    src={images[currentStep]}
                    alt="aside image"
                />

                <div className="bars">
                    {[...new Array(4)].map((_, index) => (
                        <span className={index === currentStep ? "bar active" : "bar"}></span>
                    ))}
                </div>
            </div>

            <div className="aside-video">
                <img 
                    src={AsideVideo}
                    alt="aside video"
                />
            </div>
        </Card>
    )
}

export default Aside;