import { FormattedMessage } from "react-intl";

function SubSideMenu({ activeStep = 1 }) {
    return (
        <div className="app-steps-container">
            <div className={"app-step" + (activeStep === 1 ? " active" : "")}>
                <p><FormattedMessage id='step1' defaultMessage="Step 1" /></p>
                <h4><FormattedMessage id='general-settings' defaultMessage="General Settings" /></h4>
            </div>
            <div className={"app-step" + (activeStep === 2 ? " active" : "")}>
                <p><FormattedMessage id='step2' defaultMessage="Step 2" /></p>
                <h4><FormattedMessage id='app-design' defaultMessage="App Design" /></h4>
            </div>
            <div className={"app-step" + (activeStep === 3 ? " active" : "")}>
                <p><FormattedMessage id='step3' defaultMessage="Step 3" /></p>
                <h4><FormattedMessage id='app-backend' defaultMessage="App Backend" /></h4>
            </div>
            <div className={"app-step" + (activeStep === 4 ? " active" : "")}>
                <p><FormattedMessage id='step4' defaultMessage="Step 4" /></p>
                <h4><FormattedMessage id='analytics' defaultMessage="Analytics" /></h4>
            </div>
            <div className={"app-step" + (activeStep === 5 ? " active" : "")}>
                <p><FormattedMessage id='step5' defaultMessage="Step 5" /></p>
                <h4><FormattedMessage id='release-app' defaultMessage="Release your app" /></h4>
            </div>
        </div>
    );
}

export default SubSideMenu;