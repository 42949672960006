import { useEffect, useState } from "react";
import { getActiveApp } from "../utils/storage";
import useFetchAll from "./fetch_all";

export default function useGetAllAudiences() {
    const { data, loading, setRoute, setHeaders } = useFetchAll();
    const [audienceResponse, setAudienceResponse] = useState([]);
    const [reLoadAudiences, setReLoadAudiences] = useState(true);

    useEffect(() => {
        if (data && data.results) {
            setAudienceResponse(data.results);
        }
    }, [data]);

    useEffect(() => {
        if (loading === false) {
            setReLoadAudiences(false);
        }
    }, [loading]);

    useEffect(() => {
        const app = getActiveApp();
        if (reLoadAudiences && app) {
            const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/push_audiences";
            const headers = {
                "Idengager-Application-Id": app.backendAppName || app.applicationID,
                "Idengager-Master-Key": app.masterKey,
            };
            setHeaders(headers);
            setRoute(url);
        }
    }, [reLoadAudiences, setRoute, setHeaders]);

    return { audienceResponse, setReLoadAudiences }
}
