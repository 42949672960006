import chatbotHeaderImg from './../../assets/images/chatbot-header-img.svg';
import Header from '../../components/Header';
import ConfigOverviewSlider from '../../components/ConfigOverviewSlider';
import { CHAT_BOT_CONFIG } from '../../constants/routes';
import { Space } from 'antd';
import { useState } from 'react';
import sliderImage1 from './../../assets/images/chatbots-slider-1.png';
import { withPrivate } from "../../components/PrivateRoute";


function ChatBot() {
    const [sliderElemnts] = useState([
        {
            title: "Campaign Setup",
            image: sliderImage1
        }
    ]);

    return (
        <>
            <Header
                background="#C27A3A"
                title="idEngager Campaign Setup"
                buttonTitle="Get Start"
                buttonUrl={CHAT_BOT_CONFIG}
                img={chatbotHeaderImg}
            />
            <Space className="overview-slider-container">
                <ConfigOverviewSlider
                    elements={sliderElemnts}
                />
            </Space>
        </>
    );
}

export default withPrivate(ChatBot);