import SideMenu from './SideMenu';
import TopNav from './TopNav';
import { useState } from 'react';
import { Layout } from 'antd';
import ProfileHeader from "./ProfileHeader";

function Master(props) {
    const [collapsed, setCollapsed] = useState(false);
    const { Content } = Layout;

    return (
        <div id="master">
            <Layout style={{ minHeight: '100vh' }}>
                <SideMenu collapsed={collapsed} />

                <Layout className="site-layout">
                    <TopNav collapsed={collapsed} setCollapsed={setCollapsed} />

                    <ProfileHeader />

                    <Content>
                        {props.content}
                    </Content>
                </Layout>
            </Layout>
        </div>
    );
}

export default Master;