import { useEffect, useState } from "react";
import { getActiveApp } from "../utils/storage";
import useFetchAll from "./fetch_all";

export default function useGetAllRules() {
    const { data, loading, setRoute, setHeaders } = useFetchAll();
    const [rules, setRules] = useState([]);
    const [reLoadRules, setReLoadRules] = useState(true);

    useEffect(() => {
        if (data && data.results) {
            setRules(data.results);
        }
    }, [data]);

    useEffect(() => {
        if (loading === false) {
            setReLoadRules(false);
        }
    }, [loading]);

    useEffect(() => {
        const app = getActiveApp();
        if (reLoadRules && app) {
            const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/roles?order=-createdAt";
            const headers = {
                "Idengager-Application-Id": app.backendAppName || app.applicationID,
                "Idengager-Master-Key": app.masterKey,
            };
            setHeaders(headers);
            setRoute(url);
        }
    }, [reLoadRules, setRoute, setHeaders]);

    return { rules, loading, setReLoadRules }
}