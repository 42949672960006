import { Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import '../../assets/css/configure-app.css';
import appImage from '../../assets/images/generate-app-img.png';
import { withPrivate } from "../../components/PrivateRoute";
import { GENERAL_SETTING } from '../../constants/routes';

function ConfigureApp() {
    const navigate = useNavigate();

    return (
        <div className='generate-app'>
            <div className='gen-app-content'>
                <h2 className='gen-app-h'>
                    <FormattedMessage id='gen-app' defaultMessage="Generate your app" />
                </h2>
                <p className='gen-app-p'>
                    <FormattedMessage id='gen-app-desc' defaultMessage="Now you can make your app with a click" />
                </p>
                <img className='gen-app-img' src={appImage} alt="generate-app-img" />
                <Button type="primary" onClick={() => navigate(GENERAL_SETTING)} className="st-button">
                    <FormattedMessage id='gen-app-btn' defaultMessage="Start Now" />
                </Button>
            </div>
        </div>
    );
}

export default withPrivate(ConfigureApp);