import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { getActiveApp } from "../utils/storage";

export default function useCreateDevice() {
    const [deviceData, setAddDeviceData] = useState(null);
    const [storeResponse, setStoreResponse] = useState(null);
    const [storeError, setStoreError] = useState(null);
    const [loading, setLoading] = useState(false);

    const callAPI = useCallback(async (deviceData) => {
        setStoreResponse(null);
        setStoreError(null);
        const app = getActiveApp();
        if (app) {
            try {
                const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/classes/_Installation";
                const headers = {
                    "Idengager-Application-Id": app.backendAppName || app.applicationID,
                    "Idengager-Master-Key": app.masterKey,
                };
                setLoading(true);
                const response = await axios.post(url, deviceData, { headers: headers });
                setStoreResponse(response.data);
            } catch (err) {
                if (err.response) {
                    setStoreError(err.response.data.error);
                }
            } finally {
                setLoading(false);
            }
        }
    }, []);

    useEffect(() => {
        if (deviceData) {
            if (deviceData.user) {
                deviceData.user = JSON.parse(deviceData.user);
            }
            callAPI(deviceData);
        }

    }, [deviceData, callAPI])

    return { storeResponse, storeError, loading, setAddDeviceData }
}