import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { getActiveApp } from "../utils/storage";

export default function useCreateRule() {
    const [ruleData, setRuleData] = useState(null);
    const [storeResponse, setStoreResponse] = useState(null);
    const [storeError, setStoreError] = useState(null);
    const [loading, setLoading] = useState(false);

    const callAPI = useCallback(async (ruleData) => {
        setStoreResponse(null);
        setStoreError(null);
        const app = getActiveApp();
        if (app) {
            try {
                const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/roles";
                const headers = {
                    "Idengager-Application-Id": app.backendAppName || app.applicationID,
                    "Idengager-Master-Key": app.masterKey,
                };
                setLoading(true);
                const response = await axios.post(url, ruleData, { headers: headers });
                setStoreResponse(response.data);
                setRuleData(null);
                setStoreError(null);
            } catch (err) {
                if (err.response) {
                    setStoreError(err.response.data.error);
                }
            } finally {
                setLoading(false);
            }
        }
    }, []);

    useEffect(() => {
        if (ruleData) {
            ruleData.ACL = JSON.parse(ruleData.ACL);
            callAPI(ruleData);
        }
    }, [ruleData, callAPI])

    return { storeResponse, storeError, loading, setRuleData }
}