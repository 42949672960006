import React from 'react';
import { FormattedMessage } from 'react-intl';
import DataGrid from '../../components/DataGrid';
import EnterpriseAlert from '../../components/EnterpriseAlert';
import { withPrivate } from "../../components/PrivateRoute";

function MessagingConfig() {
    return (
        <DataGrid
            header={<FormattedMessage id='dataGrid-Messaging-configuration' defaultMessage="Messaging Configuration" />}
            content={
                <EnterpriseAlert />
            }>
        </DataGrid>
    );
}

export default withPrivate(MessagingConfig);