import eventsHeaderImg from './../../assets/images/labels-header-img.svg';
import Header from './../../components/Header';
import ConfigOverviewSlider from './../../components/ConfigOverviewSlider';
import { LIST_EVENTS } from './../../constants/routes';
import { Space } from 'antd';
import { useState } from 'react';
import sliderImage1 from './../../assets/images/labels-slider-1.png';
import { withPrivate } from "../../components/PrivateRoute";


function Events() {
    const [sliderElemnts] = useState([
        {
            title: "Analytics Events",
            image: sliderImage1
        }
    ]);

    return (
        <>
            <Header
                background="#9A71D1"
                title="idEngager analytics events"
                buttonTitle="Get Start"
                buttonUrl={LIST_EVENTS}
                img={eventsHeaderImg}
            />
            <Space className="overview-slider-container">
                <ConfigOverviewSlider
                    elements={sliderElemnts}
                />
            </Space>
        </>
    );
}

export default withPrivate(Events);