import { useEffect, useState } from "react";
import { getActiveApp } from "../utils/storage";
import useFetchAll from "./fetch_all";

export default function useGetAllLogs() {
     /**
   * Fetches scriptlogs
   * lines - maximum number of lines to fetch
   * since - only fetch lines since this Date
   */
  const level =1;
  const since =1;
    const { data, loading, setRoute, setHeaders } = useFetchAll();
    const [logs, setLogs] = useState([]);
    const [reLoadLogs, setReLoadLogs] = useState(true);

    useEffect(() => {
        if (data)
            setLogs(data);
            // console.log("data"+JSON.stringify([data[0]]));
    }, [data]);

    useEffect(() => {
        if (loading === false) {
            setReLoadLogs(false);
        }
    }, [loading]);

    useEffect(() => {
        const app = getActiveApp();
        if (reLoadLogs && app) {
            // const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + 'scriptlog?level=' + encodeURIComponent(level.toLowerCase()) + '&n=100' + (since?'&startDate=' + encodeURIComponent(since.getTime()):'');
            const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + '/scriptlog';
            const headers = {
                "Idengager-Application-Id": app.backendAppName || app.applicationID,
                "Idengager-Master-Key": app.masterKey
            };
            setHeaders(headers);
            setRoute(url);
        }
    }, [reLoadLogs, setRoute, setHeaders]);

    return { logs, loading, setReLoadLogs }
}