import React, { useState } from 'react';
import { Form, Input, Button, Select, DatePicker, InputNumber, Steps, Row, Col, message } from 'antd';
import moment from 'moment';
const { Step } = Steps;
const { Option } = Select;

const bankOptions = [
  'ALAHLI',
  'SAMBA',
  'SABB',
  'RIYAD BANK',
  'ARAB BANK',
  'SAUDI FRANSI',
  'Al Rajhi',
  'ALBILAD',
  'ALINMA',
];
const loanTypeOptions = ['Short-Term', 'Long-Term'];
const shortTermOptions = ['LC Facility', 'LC refinance', 'Short term'];
const shortTermSubOptions = ['ST', 'Salary', 'ST Mobilization'];
const longTermOptions = ['Long-term', 'LT Mobilization', 'Other'];
const companyOptions = ['Zoman', 'RK'];

const CreateLoanForm = ({ onSubmit, loading }) => {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [loanType, setLoanType] = useState(null);
  const [shortTermType, setShortTermType] = useState(null);

  const next = () => {
    form.validateFields().then(values => {
      setFormData({ ...formData, ...values });
      setCurrentStep(currentStep + 1);
    }).catch(info => {
      console.log('Validate Failed:', info);
    });
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleFinish = (values) => {
    const combinedValues = {
      ...formData,
      ...values,
      loanIssueDate: formData.loanIssueDate ? formData.loanIssueDate.toISOString() : undefined,
      firstDueDate: formData.firstDueDate ? formData.firstDueDate.toISOString() : undefined,
      finalDueDate: values.finalDueDate ? values.finalDueDate.toISOString() : undefined,
      type: 'Loan'
    };
    const pendingRequests = JSON.parse(localStorage.getItem('pendingRequests')) || [];
    localStorage.setItem('pendingRequests', JSON.stringify([...pendingRequests, combinedValues]));
    window.dispatchEvent(new Event('storage'));

    message.info('Your loan request has been added to pending requests. Please check the notifications.');
  };

  const handleLoanTypeChange = (value) => {
    setLoanType(value);
    form.setFieldsValue({ shortTermType: undefined, shortTermSubType: undefined, longTermType: undefined });
  };

  const handleShortTermTypeChange = (value) => {
    setShortTermType(value);
    form.setFieldsValue({ shortTermSubType: undefined });
  };

  const steps = [
    {
      title: 'Basic Info',
      content: (
        <>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="company" label="Company" rules={[{ required: true }]}>
                <Select>
                  {companyOptions.map(company => (
                    <Option key={company} value={company}>{company}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="systemCode" label="System Code" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="bank" label="Bank" rules={[{ required: true }]}>
                <Select>
                  {bankOptions.map(bank => (
                    <Option key={bank} value={bank}>{bank}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="bankID" label="Bank ID" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="englishDescription" label="English Description" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="arabicDescription" label="Arabic Description" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="loanIssueDate" label="Loan Issue Date" rules={[{ required: true }]}>
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="loanReference" label="Loan Bank Reference" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="loanType" label="Loan Type" rules={[{ required: true }]}>
            <Select onChange={handleLoanTypeChange}>
              {loanTypeOptions.map(type => (
                <Option key={type} value={type}>{type}</Option>
              ))}
            </Select>
          </Form.Item>
          {loanType === 'Short-Term' && (
            <Form.Item name="shortTermType" label="Short Term Type" rules={[{ required: true }]}>
              <Select onChange={handleShortTermTypeChange}>
                {shortTermOptions.map(type => (
                  <Option key={type} value={type}>{type}</Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {loanType === 'Short-Term' && shortTermType === 'Short term' && (
            <Form.Item name="shortTermSubType" label="Short Term Sub-Type" rules={[{ required: true }]}>
              <Select>
                {shortTermSubOptions.map(type => (
                  <Option key={type} value={type}>{type}</Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {loanType === 'Long-Term' && (
            <Form.Item name="longTermType" label="Long Term Type" rules={[{ required: true }]}>
              <Select>
                {longTermOptions.map(type => (
                  <Option key={type} value={type}>{type}</Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </>
      ),
    },
    {
      title: 'Additional Info',
      content: (
        <>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="effectiveAnnualInterestRate" label="Effective Annual Interest Rate" rules={[{ required: true }]}>
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="facilityType" label="Facility Type" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="finalDueDate" label="Final Due Date" rules={[{ required: true }]}>
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="project" label="Project" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="numberOfPaymentsPerYear" label="Number of Payments Per Year" rules={[{ required: true }]}>
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="rePaymentAmount" label="Re-Payment Amount" rules={[{ required: true }]}>
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="downPayment" label="Down Payment" rules={[{ required: true }]}>
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="effectiveMonthlyInterestRate" label="Effective Monthly Interest Rate" rules={[{ required: true }]}>
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="remainingValue" label="Remaining Value" rules={[{ required: true }]}>
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="totalAmount" label="Total Amount" rules={[{ required: true }]}>
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="bankCharges" label="Bank Charges" rules={[{ required: true }]}>
                <Input style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="VAT" label="VAT" rules={[{ required: true }]}>
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>
        </>
      ),
    },
  ];

  return (
    <>
      <Steps current={currentStep}>
        {steps.map((item, index) => (
          <Step key={index} title={item.title} />
        ))}
      </Steps>
      <Form form={form} layout="vertical" onFinish={handleFinish} style={{ marginTop: 20 }}>
        {steps[currentStep].content}
        <div className="steps-action" style={{ marginTop: 20 }}>
          {currentStep > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Previous
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button type="primary" onClick={() => next()}>
              Next
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button type="primary" htmlType="submit" loading={loading}>
              {loading ? 'Creating...' : 'Submit'}
            </Button>
          )}
        </div>
      </Form>
    </>
  );
};

export default CreateLoanForm;
