import { CheckCircleTwoTone, InfoCircleTwoTone } from "@ant-design/icons";

export const tours = (navigate) => ({
    select_app: [
        {
            title: "Click Here to get your application Information (Mobile / Portal)",
            target: "#more-details",
        },
        {
            title: "Click Here to Manage/View your Application.",
            target: "#app-2",
            placement: 'top',
            nextButtonProps: {
                onClick: () => {
                    navigate("/home/:id?tour=select_app")
                }
            }
        },
        {
            title: "Click Here to Manage your Properties Marketing Data.",
            description: "If you need to create Marketing Data please create them first on your icloud-ready platform application.",
            target: "#letterofguarantee-sidemenu-item",
        },
        {
            title: "Click Here to View and Manage your Projects.",
            description: "If you need to create Projects please create them first on your icloud-ready platform application and you will be able to find them here.",
            target: "#letterofguarantee-projects",
        },
        {
            title: "Click Here to View and Manage your Properties.",
            description: "If you need to create Properties please create them first on your icloud-ready platform application and you will be able to find them here.",
            target: "#letterofguarantee-stages",
        },
        {
            title: "Click Here to View and Manage your Units.",
            description: "If you need to create Units please create them first on your icloud-ready platform application and you will be able to find them here.",
            target: "#letterofguarantee-units",
        },
        {
            title: "Congratulations ! ",
            description: "Currently you can Manage and View your marketing data that were created and published from iCloud-Ready Platform , also your customers are ready to view these data on mobile application and customer engagement portal.",
            cover: <CheckCircleTwoTone style={{ fontSize: '3rem' }} twoToneColor="#52c41a" />
        },
        {
            title: " ",
            description: "Now we will start the application configuration tutorial , don't be panic its just a few steps and you will be ready to configure your application UI",
            cover: <InfoCircleTwoTone style={{ fontSize: '3rem' }} />,
            nextButtonProps: {
                onClick: () => {
                    navigate("setup/colors?tour=select_app")
                }
            }
        },
        {
            title: "Click Here to Configure your Mobile Application UI",
            target: "#app-setup",
        },
        {
            title: "Click on (Get Start) to configure your Mobile app Colors",
            target: "#app-colors"
        },
        {
            title: "Click here to configure your Mobile app Colors",
            target: "#get-start-btn",
            nextButtonProps: {
                onClick: () => {
                    navigate("/setup/images?tour=select_app")
                }
            }
        },
        {
            title: "Click on (Get Start) to configure your Mobile app Images",
            target: "#app-images"
        },
        {
            title: "Click here to configure your Mobile app Images",
            target: "#get-start-btn",
            nextButtonProps: {
                onClick: () => {
                    navigate("/setup/labels?tour=select_app")
                }
            }
        },
        {
            title: "Click on (Get Start) to configure your Mobile app Labels",
            target: "#app-labels"
        },
        {
            title: "Click here to configure your Mobile app Labels",
            target: "#get-start-btn",
            nextButtonProps: {
                onClick: () => {
                    navigate("/setup/messages?tour=select_app")
                }
            }
        },
        {
            title: "Click on (Get Start) to configure your Mobile app Labels",
            target: "#app-messages"
        },
        {
            title: "Click here to configure your Mobile app Messages",
            target: "#get-start-btn"
        },
        {
            title: "Congratulations ! ",
            description: "Currently you can Configure your Mobile application UI (Colors , Images , Labels and Message).",
            cover: <CheckCircleTwoTone style={{ fontSize: '3rem' }} twoToneColor="#52c41a" />
        },
    ],
    // onboarding: [
    //     {
    //         title: "Click Here to Manage your Properties Marketing Data.",
    //         description: "If you need to create Marketing Data please create them first on your icloud-ready platform application.",
    //         target: "#letterofguarantee-sidemenu-item",
    //         nextButtonProps: {
    //             onClick: () => {
    //                 navigate("/letter-of-guarantee/projects?tour=onboarding");
    //             },
    //         },
    //     },
    //     {
    //         title: "Add a new project",
    //         target: "#add-btn",
    //     },
    //     {
    //         title: "Add a project stage",
    //         target: "#add-stage-btn",
    //         nextButtonProps: {
    //             onClick: () => {
    //                 navigate("/letter-of-guarantee/project-stages?tour=onboarding");
    //             },
    //         },
    //     },
    //     {
    //         title: "Add new stage",
    //         target: "#add-btn",
    //     },
    //     {
    //         title: "Add a new unit",
    //         target: "#add-unit-btn",
    //         nextButtonProps: {
    //             onClick: () => {
    //                 navigate("/letter-of-guarantee/unit-options?tour=onboarding");
    //             },
    //         },
    //     },
    //     {
    //         title: "Add new unit option",
    //         target: "#add-btn",
    //         nextButtonProps: {
    //             onClick: () => {
    //                 navigate("/letter-of-guarantee/unit-features?tour=onboarding");
    //             },
    //         },
    //     },
    //     {
    //         title: "Add new unit feature",
    //         target: "#add-btn",
    //         nextButtonProps: {
    //             onClick: () => {
    //                 navigate("/letter-of-guarantee/unit-gallery?tour=onboarding");
    //             }
    //         }
    //     },
    //     {
    //         title: "Add new unit gallery",
    //         target: "#add-btn",
    //         nextButtonProps: {
    //             onClick: () => {
    //                 navigate("/letter-of-guarantee/payment-plans?tour=onboarding");
    //             }
    //         }
    //     },
    //     {
    //         title: "Add new payment plans",
    //         target: "#add-btn",
    //         nextButtonProps: {
    //             onClick: () => {
    //                 navigate("/letter-of-guarantee/service-requests?tour=onboarding");
    //             }
    //         }
    //     },
    //     {
    //         title: "Manage your service requests",
    //     }
    // ],
    // project: [
    //     {
    //         title: "Add a new project",
    //         target: "#add-btn",
    //     },
    //     {
    //         title: "Add a project stage",
    //         target: "#add-stage-btn",
    //         nextButtonProps: {
    //             onClick: () => {
    //                 navigate("/letter-of-guarantee/project-stages?tour=stages");
    //             },
    //         },
    //     },
    // ],
    // stages: [
    //     {
    //         title: "Add new stage",
    //         target: "#add-btn",
    //     },
    //     {
    //         title: "Add a new unit",
    //         target: "#add-unit-btn",
    //         nextButtonProps: {
    //             onClick: () => {
    //                 navigate("/letter-of-guarantee/unit-options?tour=unit_options");
    //             },
    //         },
    //     },
    // ],
    // unit_options: [
    //     {
    //         title: "Add new unit option",
    //         target: "#add-btn",
    //         nextButtonProps: {
    //             onClick: () => {
    //                 navigate("/letter-of-guarantee/unit-features?tour=unit_features");
    //             },
    //         },
    //     },
    // ],
    // unit_features: [
    //     {
    //         title: "Add new unit feature",
    //         target: "#add-btn",
    //         nextButtonProps: {
    //             onClick: () => {
    //                 navigate("/letter-of-guarantee/unit-gallery?tour=unit_gallery");
    //             }
    //         }
    //     }
    // ],
    // unit_gallery: [
    //     {
    //         title: "Add new unit gallery",
    //         target: "#add-btn",
    //     }
    // ]
});
