// import "antd/dist/antd.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/style.css";
import "./assets/css/home.css";
import "./assets/css/header.css";
import "./assets/css/grid.css";
import "./assets/css/boarding.css";
import "./assets/css/login.css";
import "./assets/css/letterofcredit.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import * as ROUTES from "./constants/routes";

import Master from "./components/Master";
import AppLayout from "./components/ConfigureApp/AppLayout";
import * as PAGES from "./pages/index";
import GlobalTour from "./components/GlobalTour";
import GetStartedButton from "./components/GetStartedButton";
import FloatingNotificationIcon from "./components/FloatingNotificationIcon";

function App() {
    return (
        <div>
            <BrowserRouter>
                <GlobalTour />
                {/* <GetStartedButton /> */}
                <Routes>
                    <Route path={ROUTES.LOGIN} element={<PAGES.Login />} />
                    <Route
                        path={ROUTES.BOARDING}
                        element={<PAGES.Boarding />}
                    />
                    <Route
                        path={ROUTES.CREATE_APP}
                        element={<PAGES.Boarding />}
                    />
                    <Route
                        path={ROUTES.HOME}
                        element={<Master content={<PAGES.Home />} />}
                    />

                    <Route
                        path={ROUTES.DEVICES}
                        element={<Master content={<PAGES.Devices />} />}
                    />
                    <Route
                        path={ROUTES.LIST_DEVICES}
                        element={<Master content={<PAGES.ListDevices />} />}
                    />
                    <Route
                        path={ROUTES.RULES}
                        element={<Master content={<PAGES.Rules />} />}
                    />
                    <Route
                        path={ROUTES.LIST_RULES}
                        element={<Master content={<PAGES.ListRules />} />}
                    />
                    <Route
                        path={ROUTES.SESSIONS}
                        element={<Master content={<PAGES.Sessions />} />}
                    />
                    <Route
                        path={ROUTES.LIST_SESSIONS}
                        element={<Master content={<PAGES.ListSessions />} />}
                    />
                    <Route
                        path={ROUTES.USERS}
                        element={<Master content={<PAGES.Users />} />}
                    />
                    <Route
                        path={ROUTES.LIST_USERS}
                        element={<Master content={<PAGES.ListUsers />} />}
                    />
                    <Route
                        path={ROUTES.OBJECTS}
                        element={<Master content={<PAGES.Objects />} />}
                    />
                    <Route
                        path={ROUTES.MESSAGING}
                        element={<Master content={<PAGES.Messaging />} />}
                    />
                    <Route
                        path={ROUTES.MESSAGING_CONFIG}
                        element={<Master content={<PAGES.MessagingConfig />} />}
                    />
                    <Route
                        path={ROUTES.NOTIFICATION}
                        element={
                            <Master content={<PAGES.NotificationHome />} />
                        }
                    />
                    <Route
                        path={ROUTES.PUSH_NOTIFICATION}
                        element={
                            <Master content={<PAGES.PushNotifications />} />
                        }
                    />
                    <Route
                        path={ROUTES.PUSH_MESSAGES}
                        element={<Master content={<PAGES.PushMessages />} />}
                    />
                    <Route
                        path={ROUTES.CHAT_BOT}
                        element={<Master content={<PAGES.ChatBot />} />}
                    />
                    <Route
                        path={ROUTES.SMS}
                        element={<Master content={<PAGES.SMS />} />}
                    />
                    <Route
                        path={ROUTES.EMAIL}
                        element={<Master content={<PAGES.EMAIL />} />}
                    />
                    <Route
                        path={ROUTES.SURVEY}
                        element={<Master content={<PAGES.SURVEY />} />}
                    />
                    <Route
                        path={ROUTES.CAMPAIGN}
                        element={<Master content={<PAGES.CAMPAIGN />} />}
                    />
                    <Route
                        path={ROUTES.CHAT_BOT_CONFIG}
                        element={<Master content={<PAGES.ChatBotConfig />} />}
                    />
                    <Route
                        path={ROUTES.COLORS}
                        element={<Master content={<PAGES.Colors />} />}
                    />
                    <Route
                        path={ROUTES.LOGS}
                        element={<Master content={<PAGES.Logs />} />}
                    />
                    <Route
                        path={ROUTES.LIST_COLORS}
                        element={<Master content={<PAGES.ListColors />} />}
                    />
                    <Route
                        path={ROUTES.LIST_LOGS}
                        element={<Master content={<PAGES.ListLogs />} />}
                    />
                    <Route
                        path={ROUTES.EVENTS}
                        element={<Master content={<PAGES.Events />} />}
                    />
                    <Route
                        path={ROUTES.LIST_EVENTS}
                        element={<Master content={<PAGES.ListEvents />} />}
                    />
                    <Route
                        path={ROUTES.JOBS}
                        element={<Master content={<PAGES.Jobs />} />}
                    />
                    <Route
                        path={ROUTES.LIST_JOBS}
                        element={<Master content={<PAGES.ListJobs />} />}
                    />
                    <Route
                        path={ROUTES.HOOKS}
                        element={<Master content={<PAGES.Hooks />} />}
                    />
                    <Route
                        path={ROUTES.LIST_HOOKS}
                        element={<Master content={<PAGES.ListHooks />} />}
                    />
                    <Route
                        path={ROUTES.LIST_TRIGGERS}
                        element={<Master content={<PAGES.ListTriggers />} />}
                    />
                    <Route
                        path={ROUTES.IMAGES}
                        element={<Master content={<PAGES.Images />} />}
                    />
                    <Route
                        path={ROUTES.LIST_IMAGES}
                        element={<Master content={<PAGES.ListImages />} />}
                    />
                    <Route
                        path={ROUTES.LABELS}
                        element={<Master content={<PAGES.Labels />} />}
                    />
                    <Route
                        path={ROUTES.LIST_LABELS}
                        element={<Master content={<PAGES.ListLabels />} />}
                    />
                    <Route
                        path={ROUTES.SETUP_MESSAGES}
                        element={<Master content={<PAGES.Messages />} />}
                    />
                    <Route
                        path={ROUTES.SETUP_MESSAGES_LIST}
                        element={<Master content={<PAGES.ListMessages />} />}
                    />
                    <Route
                        path={ROUTES.LIST_APPS}
                        element={<PAGES.ListApps />}
                    />

                    <Route
                        path={ROUTES.LIST_OBJECTS}
                        element={<Master content={<PAGES.ListObjects />} />}
                    />
                    <Route
                        path={ROUTES.PROFILE}
                        element={<Master content={<PAGES.Profile />} />}
                    />
                    <Route
                        path={ROUTES.CONFIGURE_APP}
                        element={<AppLayout content={<PAGES.ConfigureApp />} />}
                    />
                    <Route
                        path={ROUTES.GENERAL_SETTING}
                        element={
                            <AppLayout content={<PAGES.GeneralSettings />} />
                        }
                    />
                    <Route
                        path={ROUTES.APP_DESIGN}
                        element={<AppLayout content={<PAGES.AppDesign />} />}
                    />
                    <Route
                        path={ROUTES.APP_BACKEND}
                        element={<AppLayout content={<PAGES.AppBackend />} />}
                    />
                    <Route
                        path={ROUTES.APP_ANALYTICS}
                        element={<AppLayout content={<PAGES.Analytics />} />}
                    />
                        <Route
                        path={ROUTES.LetterOfGuaranteeDetails}
                        element={<Master content={<PAGES.LetterOfGuaranteeDetails />} />}
                    />
                                            <Route
                        path={ROUTES.LetterOfGuaranteePage}
                        element={<Master content={<PAGES.LetterOfGuaranteePage />} />}
                    />
                                 <Route
                        path={ROUTES.LetterOfCreditDetails}
                        element={<Master content={<PAGES.LetterOfCreditDetails />} />}
                    />
                                            <Route
                        path={ROUTES.LetterOfCreditPage}
                        element={<Master content={<PAGES.LetterOfCreditPage />} />}
                    />
                                                     <Route
                        path={ROUTES.LoanDetails}
                        element={<Master content={<PAGES.LoanDetails />} />}
                    />
                                            <Route
                        path={ROUTES.LoanPage}
                        element={<Master content={<PAGES.LoanPage />} />}
                    />

                    <Route 
                        path={ROUTES.LEADS}
                        element={<Master content={<PAGES.LeadsPage />} />}
                    />
                </Routes>
            </BrowserRouter>
            <FloatingNotificationIcon/>
        </div>
    );
}

export default App;
