import notificationHeaderImg from './../../assets/images/notification-header-img.svg';
import Header2 from './../../components/Header2';
import ConfigOverviewSlider from './../../components/ConfigOverviewSlider';
import { PUSH_NOTIFICATION, PUSH_MESSAGES } from './../../constants/routes';
import { Space } from 'antd';
import { useState } from 'react';
import sliderImage1 from './../../assets/images/notifications-slider-1.png';
import { withPrivate } from "../../components/PrivateRoute";
import "../../assets/css/notification-home.css";

function NotificationHome() {
    const [sliderElemnts] = useState([
        {
            title: "In app Push Notifications Setup",
            image: sliderImage1
        }
    ]);

    return (
        <>
            <Header2
                background="#C27A3A"
                title="idEngager Push Notifications Setup"
                buttonTitle="Get Start"
                buttonTitle2="Past Messages"
                buttonUrl={PUSH_NOTIFICATION}
                buttonUrl2={PUSH_MESSAGES}
                img={notificationHeaderImg}
            />
            <Space className="overview-slider-container">
                <ConfigOverviewSlider
                    elements={sliderElemnts}
                />
            </Space>
        </>
    );
}

export default withPrivate(NotificationHome);