import { Tabs } from "antd";
import iPhone12 from './../assets/images/iphone-12-2.png';
import pixel5 from './../assets/images/pixel5-2.png';
import iPad from './../assets/images/iPad.png';


function NotificationPreview() {

    return (
        <div className="notification-preview">
            <Tabs defaultActiveKey="1" tabBarGutter={0} centered>
                <Tabs.TabPane tab="iPhone 12" key="1">
                    <img className="screen-preview-img" src={iPhone12} alt="iphone-12-preview" />
                </Tabs.TabPane>
                <Tabs.TabPane tab="Pixel 5" key="2">
                    <img className="screen-preview-img" src={pixel5} alt="pixel5-preview" />
                </Tabs.TabPane>
                <Tabs.TabPane tab="iPad" key="3">
                    <img className="ipad-preview-img" src={iPad} alt="iPad-preview" />
                </Tabs.TabPane>
            </Tabs>
        </div>
    );
}

export default NotificationPreview;