import Table from './../../components/Table';
import DataGrid from './../../components/DataGrid';
import React, { useCallback, useEffect, useState } from 'react';
import { default_table_item_per_pages } from './../../constants/table_constants';
import { FormattedMessage } from 'react-intl';
import useGetAllImages from '../../hooks/get_all_images';
import { Form, Modal, Spin } from 'antd';
import MainForm from '../../components/MainForm';
import { withPrivate } from "../../components/PrivateRoute";
import { images_form_fields, image_columns } from '../../constants/images_datagrid';
import { toast } from 'react-toastify';
import useUpdateConfig from '../../hooks/update_config';


function ListImages() {
    const { data, images, loading, setReLoadImages } = useGetAllImages();
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    // Modal & Form 
    const { updateResponse, updateError, setUpdatedConfigData } = useUpdateConfig();
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [editableImage, setEditableImage] = useState(null);
    const [formRef] = Form.useForm();

    const handlePageClick = useCallback((newPageNumber, itemsPerPage) => {
        const newOffset = (newPageNumber * itemsPerPage) % images.length;
        const endOffset = newOffset + itemsPerPage;
        setPageCount(Math.ceil(images.length / itemsPerPage));
        setCurrentItems(images.slice(newOffset, endOffset));
    }, [images]);

    useEffect(() => {
        if (images && images.length > 0) {
            setPageCount(0);
            handlePageClick(0, default_table_item_per_pages);
        } else {
            setCurrentItems([]);
        }
    }, [images, handlePageClick]);

    // Handle User Requests Errors
    useEffect(() => {
        if (updateError) {
            toast(updateError, { type: 'error' });
        }
    }, [updateError]);


    // Handle User Requests Responses
    useEffect(() => {
        if (updateResponse) {
            setReLoadImages(true);
            toast(<FormattedMessage id='update-success' values={{ name: <FormattedMessage id='image' defaultMessage="Image" /> }} defaultMessage="Success" />, { type: 'success' });
            formRef.resetFields();
            setPageCount(0);
        }
    }, [updateResponse, setReLoadImages]);


    // Handle Call Back Actions From Table Grid
    const onSubmitCallBack = (formValues) => {
        if (editableImage) {
            data.params.Images.forEach((element, index) => {
                if (element.imageId === editableImage.imageId) {
                    data.params.Images[index] = {
                        ...element,
                        ...formValues
                    };
                    console.log(element);
                }
            });
            setUpdatedConfigData(data);
        }
        setIsVisibleModal(false);
        toast(<FormattedMessage id='loading' defaultMessage="Loading" />, { type: 'info' });
    }

    const onEditCallBack = (image) => {
        formRef.setFieldsValue(image);
        setIsVisibleModal(true);
        setEditableImage(image);
    }

    const onDeleteCallBack = (image) => {
        data.params.Images.forEach((element, index) => {
            if (element.id === image.id) {
                data.params.Images.splice(index, 1);
            }
        });
        setUpdatedConfigData(data);
        toast(<FormattedMessage id='loading' defaultMessage="Loading" />, { type: 'info' });
    }

    return (
        <DataGrid
            header={<FormattedMessage id='dataGrid-images-configurations' defaultMessage="Images Configurations" />}
            // addNewBTN={true}
            // addNewBtnOnClick={() => setIsVisibleModal(true)}
            content={
                loading ?
                    <div className="spinner">
                        <Spin size="large" />
                    </div>
                    :
                    <>
                        <Table
                            columns={image_columns}
                            data={currentItems}
                            pageCount={pageCount}
                            total={images.length}
                            onPageChange={handlePageClick}
                            initItemPerPages={default_table_item_per_pages}
                            actions={{
                                delete: true,
                                deleteCallBack: onDeleteCallBack,
                                deleteModelName: <FormattedMessage id='image' defaultMessage="Image" />,
                                edit: true,
                                editCallBack: onEditCallBack
                            }}
                        />
                        <Modal
                            title={<FormattedMessage id='dataGrid-add-btn-title' defaultMessage="Add New" />}
                            visible={isVisibleModal}
                            footer={null}
                            onCancel={() => setIsVisibleModal(false)}>
                            <MainForm
                                formRef={formRef}
                                fields={images_form_fields}
                                onSubmit={onSubmitCallBack}
                            />
                        </Modal>
                    </>
            }>
        </DataGrid>
    );
}

export default withPrivate(ListImages);