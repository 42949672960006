import messagingHeaderImg from './../../assets/images/messaging-header-img.svg';
import Header from './../../components/Header';
import ConfigOverviewSlider from './../../components/ConfigOverviewSlider';
import { MESSAGING_CONFIG } from './../../constants/routes';
import { Space } from 'antd';
import { useState } from 'react';
import sliderImage1 from './../../assets/images/messages-slider-1.png';
import { withPrivate } from "../../components/PrivateRoute";


function Messaging() {
    const [sliderElemnts] = useState([
        {
            title: "Configure in App Messaging",
            image: sliderImage1
        }
    ]);

    return (
        <>
            <Header
                background="#C27A3A"
                title="idEngager Engagement Setup"
                buttonTitle="Get Start"
                buttonUrl={MESSAGING_CONFIG}
                img={messagingHeaderImg}
            />
            <Space className="overview-slider-container">
                <ConfigOverviewSlider
                    elements={sliderElemnts}
                />
            </Space>
        </>
    );
}

export default withPrivate(Messaging);