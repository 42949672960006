import React, { useEffect, useReducer, useState } from 'react';
import { Form, Input, Button, Select, DatePicker, Alert, Switch, InputNumber, Row, Col } from 'antd';
import { FormattedMessage } from 'react-intl';
import { SketchPicker } from 'react-color';


function MainForm({ className = "", formRef, formName = "", fields, onSubmit, alertOb, initialValues }) {
    const [colors, setColors] = useState(null);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

    // Color Picker Handle Open/Close
    function colorPickerToogle(name) {
        if (name in colors) {
            setColors({ ...colors, [name]: { ...colors[name], display: !colors[name].display } });
        }
    };

    // Color Hex Update
    function updateColorsHex(name, hex) {
        if (name in colors) {
            setColors({ ...colors, [name]: { ...colors[name], value: hex } });
        }
    };

    useEffect(() => {
        if (fields && fields.length > 0) {
            let colorObj = {};
            fields.forEach(field => {
                if (field.type === 'color') {
                    colorObj[field.name] = {
                        display: false,
                        value: '#fff',
                    };
                }
            });
            setColors(colorObj);
            console.log(fields);
        }
    }, [fields]);

    const onFinish = (values) => {
        onSubmit(values);
    };

    return (
        <Form
            className={"main-form " + className}
            form={formRef}
            name={formName}
            layout="vertical"
            onFinish={onFinish}
            initialValues={initialValues}
        >
            {
                fields.map((field, index) => {
                    return (
                        <>
                            <Form.Item
                                key={index}
                                name={field.name}
                                label={field.label}
                                rules={field.rules}
                                initialValue={field.value}
                                valuePropName={field.type === "boolean" ? "checked" : "value"}
                            >
                                {(() => {
                                    if (field.type === "password") {
                                        return (
                                            <Input.Password placeholder={field.label}  disabled={field.disabled} />
                                        )
                                    } else if (field.type === "dropdown") {
                                        return (
                                            <Select placeholder={field.label}>
                                                {
                                                    field.options.map((option, index2) => {
                                                        return (
                                                            <Select.Option value={option.key} key={index2}>{option.value}</Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        )
                                    } else if (field.type === "date") {
                                        return (
                                            <DatePicker className="datepicker" disabled={field.disabled}  />
                                        )
                                    }
                                    else if (field.type === "boolean") {
                                        return (
                                            <Switch defaultChecked={field.value} disabled={field.disabled}  />
                                        )
                                    }
                                    else if (field.type === "number") {
                                        return (
                                            <InputNumber placeholder={field.label}  disabled={field.disabled}  />
                                        )
                                    }
                                    else if (field.type === "textarea") {
                                        return (
                                            <Input.TextArea placeholder={field.label}  disabled={field.disabled} />
                                        )
                                    }
                                    else if (field.type === "color") {
                                        return (
                                            <Row gutter={[50, 24]}>
                                                <Col span={12}>
                                                    <div className='color-picker-chooser' onClick={() => colorPickerToogle(field.name)}>
                                                        <div className='selected-color' style={{ background: formRef.getFieldValue(field.name) }} />
                                                    </div>
                                                    {
                                                        colors &&
                                                        <div style={{ display: colors[field.name].display ? 'block' : 'none' }} className='color-picker-popover'>
                                                            <div className='color-picker-cover' onClick={() => colorPickerToogle(field.name)} />
                                                            <SketchPicker className='color-picker' color={colors[field.name].value} onChangeComplete={color => {
                                                                formRef.setFieldsValue({
                                                                    [field.name]: color.hex,
                                                                });
                                                                updateColorsHex(field.name, color);
                                                            }} />
                                                        </div>
                                                    }
                                                </Col>
                                            </Row>
                                        )
                                    }
                                    else if (field.type === "image") {
                                        return (
                                            <Input onChange={forceUpdate} placeholder={field.label} disabled={field.disabled}  />
                                        )
                                    }
                                    else {
                                        return (
                                            <Input placeholder={field.label} disabled={field.disabled} />
                                        )
                                    }
                                })()}
                            </Form.Item>
                            {
                                (field.type === "image" && formRef.getFieldValue(field.name)) &&
                                <img className="img-view" src={formRef.getFieldValue(field.name)} />
                            }
                        </>
                    )
                })
            }
            {alertOb &&
                <Form.Item>
                    <Alert message={alertOb.message} type={alertOb.type} />
                </Form.Item>
            }
            <Form.Item style={{ gridColumn: "1 / -1"}}>
                <Button disabled={(alertOb && alertOb.type === "success") ? true : false} type="primary" htmlType="submit" className="main-btn login-form-button">
                    <FormattedMessage id='mainForm-submit-btn-title' defaultMessage="Submit" />
                </Button>
            </Form.Item>
        </Form>
    );
}

export default MainForm;