export const columns = [
    {
        Header: 'ID',
        accessor: 'objectId', // accessor is the "key" in the data
    },
    {
        Header: 'event type',
        accessor: 'eventId',
    },
    {
        Header: 'dimensions',
        accessor: 'dimensions',
        Cell: (props) => {
            return (
                JSON.stringify(props.value)
            );
        }
    },
    {
        Header: 'Created At',
        accessor: 'createdAt',
    }
];