import { BellFilled, HighlightFilled, SettingFilled, YoutubeOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { withPrivate } from '../PrivateRoute';

function TopNav() {
    return (
        <div className="config-navbar">
            <Row gutter={[16, 0]}>
                <Col xs={24} md={16}>
                    <div className='build-app-ref'>
                        <a href='#' target="_blank">
                            <YoutubeOutlined className='utube-icon' /> Learn how to build your app
                        </a>
                    </div>
                </Col>
                <Col xs={24} md={4}>
                    <div className='profile-icons'>
                        <HighlightFilled />
                        <SettingFilled />
                        <BellFilled />
                    </div>
                </Col>
                <Col xs={24} md={4}>
                    <p className='profile-user'>Lara Smith</p>
                </Col>
            </Row >
        </div >
    );
}

export default withPrivate(TopNav);