import { useAuth } from "./AuthProvider";
import BellIcon from "../assets/images/bell.svg";
import SmallAvatar from "../assets/images/small-avatar.png";
import { Dropdown, Menu } from "antd";
import "../assets/css/profile.css";
import { useNavigate } from "react-router-dom";

function ProfileHeader(){
    const { logout, user } = useAuth();
    const navigate = useNavigate();

    if(!user) return null

    const dropdown = (
        <Menu className="avatar-dropdown">
            <Menu.Item onClick={() => navigate("/profile")}>
                Profile
            </Menu.Item>
            
            <Menu.Item onClick={logout} key="logout">
                Logout
            </Menu.Item>
        </Menu>
    );

    return (
        <div className="profile-header">
            <div>
                <div className="notification-wrapper">
                    <img src={BellIcon} />
                </div>
            </div>

            <div>
                <Dropdown overlay={dropdown}>
                    {/* <img src={SmallAvatar} /> */}
                    <div className="user-avatar">
                        <span>{user.username[0]}</span>
                    </div>
                </Dropdown>
            </div>
        </div>
    )
}

export default ProfileHeader; 