import '../../../assets/css/configure-app.css';
import { Col, Form, Row } from 'antd';
import { withPrivate } from "../../../components/PrivateRoute";
import SubSideMenu from "../../../components/ConfigureApp/SubSideMenu";
import TopNav from '../../../components/ConfigureApp/TopNav';
import { useState } from 'react';
import Stepper from '../../../components/ConfigureApp/Stepper';
import { FormattedMessage } from 'react-intl';
import SelectIndustry from './SelectIndustry';
import ChooseTemplate from './ChooseTemplate';
import AppInfo from './AppInfo';

function GeneralSettings() {
    const [stepperActiveStep, setStepperActiveStep] = useState(1);
    const [appInfoFormRef] = Form.useForm();

    // Step 1 Call Back
    const selectIndustryCallBack = (value) => {
        console.log(value);
        setStepperActiveStep(2);
    }

    // Step 2 Call Back
    const selectTempCallBack = (value) => {
        console.log(value);
        setStepperActiveStep(3)
    }

    // Step 3 Call Back 
    const appInfoCallBack = (values) => {
        console.log(values);
        setStepperActiveStep(4)
    }


    return (
        <div className='config-layout'>
            <Row gutter={[0, 0]}>
                <Col xs={0} sm={0} md={0} lg={5}>
                    <SubSideMenu activeStep={1} />
                </Col>
                <Col xs={24} sm={24} md={19}>
                    <div className='config-body'>
                        <TopNav />

                        <div className='config-content'>
                            <Stepper
                                activeStep={stepperActiveStep}
                                setStepperActiveStep={setStepperActiveStep}
                                steps={[
                                    {
                                        title: <FormattedMessage id='select-industry' defaultMessage="Select Industry" />,
                                        content: <SelectIndustry
                                            onSelectIndustry={selectIndustryCallBack}
                                        />,
                                    },
                                    {
                                        title: <FormattedMessage id='choose-template' defaultMessage="Choose Template" />,
                                        content: <ChooseTemplate
                                            onSelectTemplate={selectTempCallBack}
                                        />,
                                    },
                                    {
                                        title: <FormattedMessage id='app-info' defaultMessage="App Info" />,
                                        content: <AppInfo
                                            onSubmitAppInfo={appInfoCallBack}
                                            formRef={appInfoFormRef}
                                        />,
                                    },
                                    {
                                        title: <FormattedMessage id='splash-icon' defaultMessage="Splash & Icon" />,
                                        content: "Content",
                                    },
                                    {
                                        title: <FormattedMessage id='general-view' defaultMessage="General view" />,
                                        content: "Content",
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}

export default withPrivate(GeneralSettings);