import leftArrow from "./../icons/flat-left-arrow.svg";
import rightArrow from "./../icons/flat-right-arrow.svg";
import bottomArrow from './../icons/bottom-arrow.svg';
import { default_table_item_per_pages } from './../constants/table_constants';
import { Menu, Dropdown } from 'antd';
import { useEffect, useState } from "react";

function Paginate(props) {
    const [currentPageCount, setCurrentPageCount] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(props.initItemPerPages);
    const [currentItemsCountFrom, setCurrentItemsCountFrom] = useState(0);
    const [currentItemsCountTo, setCurrentItemsCountTo] = useState(itemsPerPage);

    useEffect(() => {
        if (props.pageCount === 0) {
            setCurrentPageCount(0);
            setItemsPerPage(default_table_item_per_pages);
        }
    }, [props.pageCount, setCurrentPageCount, setItemsPerPage]);

    useEffect(() => {
        var countFrom = currentPageCount !== 0 ? (currentPageCount * itemsPerPage) + 1 : 1;
        var countTo = (countFrom - 1) + itemsPerPage;
        if (countTo > props.total)
            countTo = props.total;
        setCurrentItemsCountFrom(countFrom);
        setCurrentItemsCountTo(countTo);
    }, [currentPageCount, currentItemsCountFrom, itemsPerPage, props.total])

    const handleRowsChange = (newItemPerPage) => {
        setItemsPerPage(newItemPerPage);
        setCurrentPageCount(0);
        props.onPageChange(0, newItemPerPage);
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 0 && newPage < props.pageCount) {
            props.onPageChange(newPage, itemsPerPage);
            setCurrentPageCount(newPage);
        }
    };

    const menu = (
        <Menu>
            <Menu.Item key="rows-10" onClick={e => handleRowsChange(10)}>10</Menu.Item>
            <Menu.Item key="rows-20" onClick={e => handleRowsChange(20)}>20</Menu.Item>
            <Menu.Item key="rows-50" onClick={e => handleRowsChange(50)}>50</Menu.Item>
            <Menu.Item key="rows-100" onClick={e => handleRowsChange(100)}>100</Menu.Item>
        </Menu>
    );

    return (
        <div className="paginate">
            <div>
                <Dropdown overlay={menu} trigger={['click']}>
                    <span className="rows-page-trigger" onClick={e => e.preventDefault()}>
                        Rows per page: {itemsPerPage} <img src={bottomArrow} alt="left-bottom-arrow-icon" />
                    </span>
                </Dropdown>
            </div>
            <div>{currentItemsCountFrom}-{currentItemsCountTo} of {props.total}</div>
            <div className="page-control-container"><img className="page-control" onClick={e => handlePageChange(currentPageCount - 1)} src={leftArrow} alt="flat-left-arrow" /></div>
            <div className="page-control-container"><img className="page-control" onClick={e => handlePageChange(currentPageCount + 1)} src={rightArrow} alt="flat-right-arrow" /></div>
        </div>
    );
}

export default Paginate;