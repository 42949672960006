import './../assets/css/list-apps.css';
import { Row, Col } from 'antd';
import AppCard from './../components/AppCard';
import * as ROUTES from './../constants/routes';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { withPrivate } from "../components/PrivateRoute";
import useGetAllApps from './../hooks/get_all_apps';
import { Spin } from "antd";
import { useEffect, useState } from 'react';
import { reload_apps_interval, app_completed_status_key, app_in_progress_status_key } from '../constants/apps_constants';
import ProfileHeader from "../components/ProfileHeader";
import { PlusOutlined } from '@ant-design/icons';
import GlobalTour from '../components/GlobalTour';
import { OnboardingCard } from '../components/GlobalTour/GlobalTour';
import IdengagerLogo from '../assets/images/IdengagerLogo.svg'
import { useAuth } from '../components/AuthProvider';

function ListApps() {
    const { apps, loading, setReLoadApps } = useGetAllApps();
    const [inprogressExist, setInprogressExist] = useState(false); // Flag for Inprogress check in apps list
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { user } = useAuth()

    console.log(searchParams.get("isToured"))

    // Check If Apps List Have an App With (In Progress) Status & Handle Apps Refresh Every "reload_apps_interval" Minutes
    useEffect(() => {
        if (inprogressExist === false) {
            for (let i = 0; i < apps.length; i++) {
                if (apps[i].appStatus === app_in_progress_status_key) {
                    setInterval(() => {
                        setReLoadApps(true);
                    }, (1000 * 60) * reload_apps_interval);
                    setInprogressExist(true);
                    break
                }
            }
        }
    }, [apps]);

    return (
        <div id="app">
            <div className="profile-header">
                <ProfileHeader />
            </div>
            <OnboardingCard />
            <div className='circle circle-one' />
            <div className='circle circle-two' />
            {/* <ProfileHeader /> */}
            <div className="all-apps">
                <div className="container">
                    <Row gutter={[50, 24]} justify="space-between" align="middle" style={{ marginBottom: '50PX' }}>
                        <Col sm={24} md={6} className="apps-header">
                            <h1>Welcome {user.username}</h1>
                            <p>you have {apps.length} app versions</p>
                        </Col>
                        <Col sm={24} md={6}>
                            <div style={{ textAlign: 'center' }}>
                                <img src={IdengagerLogo} alt="Idengager Logo" />
                            </div>
                        </Col>
                        <Col sm={24} md={6}>
                            <Link to={ROUTES.CREATE_APP} className='new-app-link'>
                                Register New Application
                            </Link>
                        </Col>
                    </Row>
                    <Row justify="center" gutter={[16, 24]}>
                        {
                            loading ?
                                <div className='spinner'>
                                    <Spin size='large' />
                                </div>
                                :
                                apps.map((app, index) => {
                                    return (
                                        <Col sm={24} md={12} xl={8} key={index} style={{ position: 'relative' }}>
                                            <div
                                                style={{ cursor: "pointer", position: 'absolute', width: '100%', height: '100%', top: 0, bottom: 0, left: 0, right: 0, zIndex: 9 }}
                                                // to={ROUTES.BASE_HOME + app.objectId}
                                                disabled={(!app.appStatus || (app.appStatus && app.appStatus !== app_completed_status_key)) ? true : false}
                                                onClick={(e) => {
                                                    if (!app.appStatus || (app.appStatus && app.appStatus !== app_completed_status_key)) {
                                                        e.preventDefault();
                                                        return;
                                                    }

                                                    navigate(ROUTES.BASE_HOME + app.objectId + `?tour=${(searchParams.get("tour") !== null || searchParams.get("isToured") === true) ? "onboarding" : null}`);
                                                }}>
                                            </div>
                                            <AppCard
                                                name={app.appName}
                                                img={app.appImage}
                                                appStatus={app.appStatus}
                                                appId={app.backendAppName || app.applicationID}
                                                appURL={((app.appURL ? app.appURL + "/idengager" : null) || app.tenantUrl)}
                                                masterKey={app.masterKey}
                                                numOfUsers={12345}
                                                numOfLogins={12345}
                                                appPrerviewURL={app.appPrerviewURL}
                                                lifeTime={"250h"}
                                                android={true}
                                                ios={true}
                                                index={index}
                                            />
                                        </Col>
                                    )
                                })
                        }
                    </Row>
                </div>
            </div>
        </div >
    );
}

export default withPrivate(ListApps);
