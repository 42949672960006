import { Button } from 'antd';
import lightbulbIcon from './../icons/lightbulb.svg';
//import minusCircleIcon from './../icons/minus-circle.svg';
//import deleteIcon from './../icons/delete-red-gray.svg';
import greenCheck from './../icons/green-check.svg';
import { FormattedMessage } from 'react-intl';
import { useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import AudienceForm from './AudienceForm';


function AudienceTable({ audiences, selectedAudienceIndex, onAudienceSelect, onSubmitNewAudience }) {
    const [modalVisible, setModalVisible] = useState(false);
    const [reInitForm, setReInitForm] = useState(false);

    return (
        <div className="arrow-after">
            <p className="push-notification-header">
                <FormattedMessage id='pushNotification-header' defaultMessage="New Push Notification" />
            </p>
            <div className='audience-table-container overflow-x-auto main-scroll'>
                <table className="table audience-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            audiences.map((audience, index) => {
                                return (
                                    (audience.query && audience.name) &&
                                    <tr onClick={() => onAudienceSelect(index)} className={"audience-record " + (selectedAudienceIndex === index ? "active" : "")} key={index}>
                                        <td>
                                            <img src={lightbulbIcon} alt="lightbulb-icon" />
                                        </td>
                                        <td>{audience.name ? audience.name : ""}</td>
                                        <td>{new Date(audience.createdAt).toLocaleString()}</td>
                                        <td>
                                            <div className="audience-controller">
                                                {/* <Button className="aud-btn" size="small" type="primary">
                                                    <FormattedMessage id='audience-details-action' defaultMessage="Details" />
                                                </Button>
                                                <img src={deleteIcon} alt="delete-icon" />
                                                <img src={minusCircleIcon} alt="minus-icon" /> */}
                                                {
                                                    selectedAudienceIndex === index &&
                                                    <img src={greenCheck} alt="green-check-icon" />
                                                }
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <div className="new-aud-btn-container">
                    <Button className="new-aud-btn" onClick={() => setModalVisible(true)} size="small" type="primary">
                        <FormattedMessage id='pushNotificationForm-submit-btn' defaultMessage="New Audience" />
                    </Button>
                </div>
            </div>

            <Modal
                className="audience-modal"
                title={<FormattedMessage id='audienceTable-modal-title' defaultMessage="Create Audience" />}
                visible={modalVisible}
                width={720}
                footer={null}
                onCancel={() => setModalVisible(false)}>
                <AudienceForm
                    onSubmit={onSubmitNewAudience}
                    reInitForm={reInitForm}
                    setReInitForm={setReInitForm}
                    onCancel={() => setModalVisible(false)}
                />
            </Modal>
        </div>
    );
}

export default AudienceTable;