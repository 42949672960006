import { useEffect, useState } from "react";
import { getActiveApp } from "../utils/storage";
import useFetchAll from "./fetch_all";

export default function useGetAllSessions() {
    const { data, loading, setRoute, setHeaders } = useFetchAll();
    const [sessions, setSessions] = useState([]);
    const [reLoadSessions, setReLoadSessions] = useState(true);

    useEffect(() => {
        if (data && data.results)
            setSessions(data.results);
    }, [data]);

    useEffect(() => {
        if (loading === false) {
            setReLoadSessions(false);
        }
    }, [loading]);

    useEffect(() => {
        const app = getActiveApp();
        if (reLoadSessions && app) {
            const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/classes/_Session?order=-createdAt&include=user";
            const headers = {
                "Idengager-Application-Id": app.backendAppName || app.applicationID,
                "Idengager-Master-Key": app.masterKey,
            };
            setHeaders(headers);
            setRoute(url);
        }
    }, [reLoadSessions, setRoute, setHeaders]);

    return { sessions, loading, setReLoadSessions }
}