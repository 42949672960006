import './../../assets/css/stepper.css';
import { Button, Steps } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons/lib/icons';

function Stepper({ steps = [], activeStep = 1, setStepperActiveStep }) {
    return (
        <>
            <Steps className="stepper" labelPlacement="vertical" current={activeStep - 1}>
                {steps.map((step, index) => {
                    return (
                        <Steps.Step onClick={() => setStepperActiveStep(index + 1)} key={index} title={step.title && step.title} description={step.description && step.description} />
                    )
                })}
            </Steps>
            <div className="stepper-body">
                {steps[activeStep - 1] && steps[activeStep - 1].content}
            </div>
            <div className="stepper-footer">
                {
                    (activeStep > 1 && steps[activeStep - 1].prevStep) &&
                    <Button onClick={steps[activeStep - 1].prevStep} className="stepper-btn previous-btn">
                        <LeftOutlined />
                    </Button>
                }
                {
                    steps[activeStep - 1].nextStep &&
                    <Button onClick={steps[activeStep - 1].nextStep} className="stepper-btn next-btn">
                        {
                            (activeStep) === steps.length
                                ?
                                "Save"
                                :
                                "Next"
                        } <RightOutlined />
                    </Button>
                }

            </div>
        </>
    );
}

export default Stepper;