import axios from "axios";
import { useEffect } from "react";
import { getActiveApp } from "../utils/storage";
import { getFavorites } from "./get_all_favroites";
import useAsync from "./useAsync";

const getLeads = async () => {
    const app = getActiveApp();
    const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + '/classes/Service_Requests?include=user,catalog' ;
    const headers = {
        "Idengager-Application-Id": app.backendAppName || app.applicationID,
        "Idengager-Master-Key": app.masterKey
    };

    const serviceRequestsLeads = (await axios.get(url, { headers: headers })).data.results.filter(lead => {
        return ["Promotion Lead", "Request Quote"].some(title => lead.Title.includes(title)) 
    }).map(lead => {
        return { ...lead, type: "service_request", StatusTypeCdMeaning: lead.StatusTypeCdMeaning === "Sent" ? "opened" : lead.StatusTypeCdMeaning }
    }).sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
    });

    console.log({ serviceRequestsLeads });

    const favorites = (await getFavorites()).map(lead => {
        return { ...lead, type: "favorite" }
    }).sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
    });

    return [...serviceRequestsLeads, ...favorites];
}

export const useLeads = () => {
    const { run, ...data } = useAsync(); 

    useEffect(() => {
        run(getLeads());
    }, [run]);

    return {
        ...data,
        refresh: () => run(getLeads())
    };
}


