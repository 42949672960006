// hooks/useLGLogs.js
import { useState, useEffect } from 'react';
import axios from 'axios';
import { getApiUrlAndHeaders } from '../utils/api';

export const useGetLGLogs = (lgId) => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        const { url, headers } = getApiUrlAndHeaders(`/classes/LGLogs?where={"lgId":{"__type":"Pointer","className":"LetterOfGuarantee","objectId":"${lgId}"}}`);
        const response = await axios.get(url, { headers });
        const logs = response.data.results;

        // Fetch user details for each log entry
        const logsWithUserDetails = await Promise.all(logs.map(async log => {
          if (log.userId && log.userId !== 'system') {
            const userResponse = await axios.get(getApiUrlAndHeaders(`/users/${log.userId}`).url, { headers });
            log.user = userResponse.data;
          } else {
            log.user = { username: 'system' };
          }
          return log;
        }));

        setLogs(logsWithUserDetails);
      } catch (err) {
        setError(err);
        console.log('Error fetching logs:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchLogs();
  }, [lgId]);

  return { logs, loading, error };
};
