import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, message, Space, Popconfirm } from 'antd';
import { useNavigate } from 'react-router-dom';
import CreateLCForm from '../../components/LC/CreateLCForm';
import { getLettersOfCredit, useCreateLetterOfCredit, useRemoveLetterOfCredit } from '../../hooks/useLetterOfCredit';

const LetterOfCreditPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { create, loading: createLoading } = useCreateLetterOfCredit();
  const { remove } = useRemoveLetterOfCredit();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getLettersOfCredit();
      setData(result);
    } catch (error) {
      console.error('Error fetching letters of credit:', error);
      message.error('Failed to fetch letters of credit');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCreate = async (values) => {
    try {
      await create(values);
      message.success('Letter of Credit created successfully');
      setIsModalVisible(false);
      fetchData();  // Refresh the data
    } catch (error) {
      console.error('Error creating letter of credit:', error);
      message.error('Failed to create letter of credit');
    }
  };

  const handleDelete = async (id) => {
    try {
      await remove(id);
      message.success('Letter of Credit deleted successfully');
      fetchData();  // Refresh the data
    } catch (error) {
      console.error('Error deleting letter of credit:', error);
      message.error('Failed to delete letter of credit');
    }
  };

  const handleViewDetails = (record) => {
    navigate(`/letter-of-credit/${record.objectId}`);
  };

  const columns = [
    {
      title: 'LC Number',
      dataIndex: 'LCNumber',
      key: 'LCNumber',
      sorter: (a, b) => a.LCNumber.localeCompare(b.LCNumber),
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      key: 'vendor',
      sorter: (a, b) => a.vendor.localeCompare(b.vendor),
    },
    {
      title: 'Issued Date',
      dataIndex: 'issuedDate',
      key: 'issuedDate',
      sorter: (a, b) => new Date(a.issuedDate) - new Date(b.issuedDate),
      render: (text) => text ? new Date(text).toLocaleDateString() : 'N/A',
    },
    {
      title: 'Payment Method',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      sorter: (a, b) => a.paymentMethod.localeCompare(b.paymentMethod),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => a.amount - b.amount,
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      sorter: (a, b) => new Date(a.expiryDate) - new Date(b.expiryDate),
      render: (text) => text ? new Date(text).toLocaleDateString() : 'N/A',
    },
    {
      title: 'Issuing Bank',
      dataIndex: 'issuingBank',
      key: 'issuingBank',
    },
    {
      title: 'Beneficiary',
      dataIndex: 'beneficiary',
      key: 'beneficiary',
    },
    {
      title: 'Terms',
      dataIndex: 'terms',
      key: 'terms',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => handleViewDetails(record)}>View Details</Button>
          <Popconfirm
            title="Are you sure you want to delete this Letter of Credit?"
            onConfirm={() => handleDelete(record.objectId)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div style={{ padding: '24px', paddingTop: '75px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <h2>Letters of Credit</h2>
        <Button type="primary" onClick={showModal}>
          Create New LC
        </Button>
      </div>
      <Table 
        columns={columns} 
        dataSource={data} 
        loading={loading} 
        rowKey="objectId" 
        pagination={{ pageSize: 10 }} 
        style={{ marginBottom: '16px' }}
      />
      <Modal
        title="Create New Letter of Credit"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <CreateLCForm onSubmit={handleCreate} loading={createLoading} />
      </Modal>
    </div>
  );
};

export default LetterOfCreditPage;
