import { Card, Col, Descriptions, Modal, QRCode, Row, Space } from 'antd';
import androidIcon from './../icons/andriod-app-icon.svg';
import iosIcon from './../icons/ios-app-icon.svg';
import logoPlaceholder from './../assets/images/placeholder-logo.png';
import { Spin } from 'antd';
import { LoadingOutlined, CloseOutlined, EyeOutlined } from '@ant-design/icons';
import { app_completed_status_key, app_failed_status_key, app_in_progress_status_key } from '../constants/apps_constants';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import EyeLogo from '../assets/images/EyeLogo.svg'
import chrome from '../assets/images/google-chrome.svg'
import andorid from '../assets/images/andorid.svg'
import ios from '../assets/images/ios.svg'
import googlePlay from '../assets/images/googlePlay.svg'
import appStore from '../assets/images/appStore.svg'

function AppCard(props) {
    const [isDetailsModalOpen, setIsDetailsModalOneOpen] = useState(false);
    const [isQRCodeModalOpen, setIsQRCodeModalOpen] = useState(false);
    const ua = navigator.userAgent
    let qrCodeLink
    if (/iPad|iPhone|iPod/.test(ua)) {
        qrCodeLink = "https://apps.apple.com/ph/app/idengager-real-estate/id6443878456"
    } else {
        qrCodeLink = "https://play.google.com/store/apps/details?id=com.icloudready.idengager.realestateDemo"
    }

    const showDetailsModal = () => {
        setIsDetailsModalOneOpen(true);
    };
    const showQRCodeModal = () => {
        setIsQRCodeModalOpen(true);
    };
    const handleOk = () => {
        setIsDetailsModalOneOpen(false);
        setIsQRCodeModalOpen(false);
    };
    const handleCancel = () => {
        setIsDetailsModalOneOpen(false);
        setIsQRCodeModalOpen(false);
    };

    return (
        <Card className="app-card" size="small" id={`app-${props.index + 1}`}>
            {
                props.appStatus === app_in_progress_status_key &&
                <div className="app-status-container">
                    <Spin className='inprogress-status' indicator={<LoadingOutlined className='spinner-icon' />} />
                    <h4 className="app-name">
                        <FormattedMessage id='appCard-creationInProgress' defaultMessage="Creation In Progress" />
                    </h4>
                </div>
            }
            {
                props.appStatus === app_failed_status_key &&
                <div className="app-status-container">
                    <div className='failed-status'>
                        <CloseOutlined className='close-icon' />
                    </div>
                    <h4 className="app-name">
                        <FormattedMessage id='appCard-creationFailed' defaultMessage="Creation Failed" />
                    </h4>
                </div>
            }
            {
                props.appStatus === app_completed_status_key &&
                <>
                    <Space className='app-name'>
                        <img className="app-icon align-center" src={(props.img && props.img !== "") ? props.img : logoPlaceholder} alt="Application-Icon" />
                        <h4 className="app-name" style={(!props.name) ? { marginTop: "90px" } : {}}>{props.name}</h4>
                    </Space>

                    {/* {
                        props.appStatus &&
                        <h5 className="app-status">({props.appStatus})</h5>
                    } */}
                    <Space className="app-stat">
                        <div>
                            <h6>Users</h6>
                            <p>{props.numOfUsers}</p>
                        </div>
                        <div>
                            <h6>Logins</h6>
                            <p>{props.numOfLogins}</p>
                        </div>
                        <div>
                            <h6>Life Time</h6>
                            <p>{props.lifeTime}</p>
                        </div>
                    </Space>

                    <Row className="app-btns" gutter={[12, 12]}>
                        <Col md={18} sm={24} style={{ zIndex: 99 }}>
                            <button id="more-details" className='details-btn' onClick={showDetailsModal}>More Details</button>
                        </Col>
                        <Col md={5} sm={24} style={{ marginLeft: 'auto', zIndex: 99 }}>
                            <button className='QRCode-btn' onClick={showQRCodeModal}>
                                <img src={EyeLogo} alt="Eye" />
                            </button>
                        </Col>
                    </Row>
                    <Modal
                        title="App Details"
                        open={isDetailsModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={null}
                        width="700px"
                        className="details-model"
                    >
                        <Row gutter={[16, 16]} style={{ padding: '1rem 0' }}>
                            <Col span={4}>
                                <div className='model-image'>
                                    <img className="app-icon align-center" src={(props.img && props.img !== "") ? props.img : logoPlaceholder} alt="Application-Icon" />
                                </div>
                            </Col>
                            <Col span={20}>
                                <Descriptions layout="vertical" colon={false}>
                                    <Descriptions.Item label="Users">{props.numOfUsers}</Descriptions.Item>
                                    <Descriptions.Item label="LifeTime">{props.numOfLogins}</Descriptions.Item>
                                    <Descriptions.Item label="Logins">{props.lifeTime}</Descriptions.Item>
                                    <Descriptions.Item label="App ID">{props.appId}</Descriptions.Item>
                                    <Descriptions.Item label="App Key" span={2}>{props.masterKey}</Descriptions.Item>
                                    <Descriptions.Item label="App Url" span={2}>{props.appURL}</Descriptions.Item>
                                    <Descriptions.Item>
                                        <button
                                            className='copy-btn'
                                            onClick={() => { navigator.clipboard.writeText(props.appURL) }}>Copy Url</button>
                                    </Descriptions.Item>

                                </Descriptions>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col span={8}>
                                <a href="https://idengagerweb.herokuapp.com/" target='_blank' rel="noreferrer">
                                    <button className='model-footer-btn'>
                                        <img src={chrome} alt="" />
                                        <span>Redirect to Portal</span>
                                    </button>
                                </a>
                            </Col>
                            <Col span={8}>
                                <a href="https://play.google.com/store/apps/details?id=com.icloudready.idengager.realestateDemo" target='_blank' rel="noreferrer">
                                    <button className='model-footer-btn'>
                                        <img src={andorid} alt="" />
                                        <span>Download Android </span>
                                    </button>
                                </a>
                            </Col>
                            <Col span={8}>
                                <a href="https://apps.apple.com/ph/app/idengager-real-estate/id6443878456" target='_blank' rel="noreferrer">
                                    <button className='model-footer-btn'>
                                        <img src={ios} alt="" />
                                        <span>Download Ios</span>
                                    </button>
                                </a>
                            </Col>
                        </Row>
                    </Modal>
                    <Modal
                        open={isQRCodeModalOpen}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={null}
                        className='QRcode-model'
                    >
                        <div className='QRcode-model-details'>
                            <h3>Scan to open app preview</h3>
                            <p>Download app then you can <br />
                                preview it on your phone
                            </p>
                            <div className='QRcode-model-code'>
                                <QRCode value={qrCodeLink} />
                            </div>
                            <div className='QRcode-model-btns'>
                                <img src={googlePlay} alt="Google Play" />
                                <img src={appStore} alt="App Store" />
                            </div>
                        </div>
                    </Modal>
                    <div className='circle circle-three' />


                    {/* <Space className="app-connection">
                        <div>
                            <h6>app Id</h6>
                            <p>{props.appId}</p>
                        </div>
                        <div>
                            <h6>app key</h6>
                            <p>{props.masterKey}</p>
                        </div>
                    </Space> */}
                    {/* <Space className="app-connection">
                        <div>
                            <h6>app url</h6>
                            <p>{props.appURL}</p>
                        </div>
                    </Space> */}
                    {/* <Space className="app-connection">
                        <div>
                            <a href={props.appPrerviewURL}>
                                <h6>copy app preview url</h6>
                            </a>
                        </div>
                    </Space> */}
                </>
            }

            {/* <Space className="app-os">
                {
                    props.android &&
                    <div className="icon-container">
                        <img src={androidIcon} alt="android-icon" />
                    </div>
                }
                {
                    props.ios &&
                    <div className="icon-container">
                        <img src={iosIcon} alt="ios-icon" />
                    </div>
                }
            </Space> */}
        </Card>
    );
}

export default AppCard;