import objectsHeaderImg from './../../assets/images/objects-header-img.svg';
import Header from './../../components/Header';
import ConfigOverviewSlider from './../../components/ConfigOverviewSlider';
import * as ROUTES from './../../constants/routes';
import { Space } from 'antd';
import { useState } from 'react';
import sliderImage1 from './../../assets/images/objects-slider-1.png';
import { withPrivate } from "../../components/PrivateRoute";


function Objects() {
    const [sliderElemnts] = useState([
        {
            title: "Configure and Setup Industrial Objects",
            image: sliderImage1
        }
    ]);

    return (
        <>
            <Header
                background="#4ACEC7"
                title="idEngager Objects"
                buttonTitle="Configure Objects"
                buttonUrl={ROUTES.LIST_OBJECTS}
                img={objectsHeaderImg}
            />
            <Space className="overview-slider-container">
                <ConfigOverviewSlider
                    elements={sliderElemnts}
                />
            </Space>
        </>
    );
}

export default withPrivate(Objects);