import { Button, Form, Input, Radio, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import platformAndroid from './../icons/platform-android.svg';
import platformIos from './../icons/platform-ios.svg';
import platformWindowsPhone from './../icons/platform-windows-phone.svg';

const initialJson = `
{
    "deviceType": "ios"
}
`.trim();

function AudienceForm({ onSubmit, onCancel, reInitForm, setReInitForm }) {
    const [form] = Form.useForm();
    const [platform, setPlatform] = useState(null);
    const [query, setQuery] = useState(initialJson);
    const [acceptQuery, setAcceptQuery] = useState(false);

    useEffect(() => {
        if (reInitForm) {
            form.resetFields();
            setReInitForm(false);
        }
    }, [reInitForm, setReInitForm, form]);
    
    const changePlatform = (e) => {
        setPlatform(e.target.value);
    }

    const onFinish = (values) => {
        onSubmit({
            ...values,
            query: acceptQuery ? query : null
        });
        onCancel();
        setReInitForm(true);
    };

    return (
        <Form
            className="audience-form"
            form={form}
            name="audience-form"
            layout="vertical"
            onFinish={onFinish}
        >
            <Form.Item
                name="audience_name"
                label={<h3 className="label">Audience Name</h3>}
                rules={[{ required: true, message: 'Please enter audience name' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item name="platform" label={<h3 className="label">Which platforms should be included?</h3>} rules={[{ required: !acceptQuery, message: 'Please select the platform' }]}>
                <Radio.Group onChange={changePlatform} disabled={acceptQuery}>
                    <Radio className={"platform-option " + ((platform === "android") ? "active" : "")} value="android">
                        <div className="platform-container">
                            <img className="android-platform" src={platformAndroid} alt="android-platform-icon" />
                            <p>Android</p>
                        </div>
                    </Radio>
                    <Radio className={"platform-option " + ((platform === "ios") ? "active" : "")} value="ios">
                        <div className="platform-container">
                            <img src={platformIos} alt="ios-platform-icon" />
                            <p>IOS</p>
                        </div>
                    </Radio>
                    <Radio className={"platform-option " + ((platform === "iosx") ? "active" : "")} value="iosx">
                        <div className="platform-container">
                            <img src={platformIos} alt="ios-platform-icon" />
                            <p>iOSX</p>
                        </div>
                    </Radio>
                    <Radio className={"platform-option " + ((platform === "tvos") ? "active" : "")} value="tvos">
                        <div className="platform-container">
                            <img src={platformIos} alt="ios-platform-icon" />
                            <p>tvOS</p>
                        </div>
                    </Radio>
                    <Radio className={"platform-option " + ((platform === "windows_phone") ? "active" : "")} value="windows_phone">
                        <div className="platform-container">
                            <img src={platformWindowsPhone} alt="windows-platform-icon" />
                            <p>Windows Phone</p>
                        </div>
                    </Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item
                label={
                    <h3 className="label query-label">
                        <span>Or write your own query</span>
                        <Switch onChange={checked => setAcceptQuery(checked)} />
                    </h3>
                }
            >
                {
                    <Input.TextArea
                        disabled={!acceptQuery}
                        value={query}
                        onChange={e => setQuery(e.target.value)}
                        className={acceptQuery ? "query-textarea" : "query-textarea collapsed"}
                        rows={4}
                    />
                }
            </Form.Item>

            <Form.Item name="future_use" label={<h3 className="label">Save this audience for future use?</h3>} initialValue={false}>
                <Switch />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="button" onClick={onCancel} className="create-audience-btn cancel-audience-btn">
                    <FormattedMessage id='audienceForm-cancel-btn-title' defaultMessage="Cancel" />
                </Button>

                <Button type="primary" htmlType="submit" className="create-audience-btn">
                    <FormattedMessage id='audienceForm-create-btn-title' defaultMessage="Create" />
                </Button>
            </Form.Item>
        </Form>
    );
}

export default AudienceForm;