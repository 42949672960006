import React from "react";
import { Steps } from "antd";
import { clamp } from "../utils/helpers";

const { Step } = Steps;

function StepsContainer({
    currentStep = 0,
    stepsClassName = "steps",
    contentClassName = "",
    children,
    ...delegated
}){
    const limitCurrentStep = clamp(0, currentStep, children.length - 1);

    const content = (
        React
        .Children
        .map(children, (child, index) => index === limitCurrentStep && child)
    );

    return (
        <div className="steps-container" {...delegated}>
            <Steps 
                current={currentStep} 
                labelPlacement="vertical" 
                className={stepsClassName}
            >
                {
                    React.Children.map(children, ({ props })=> (
                        <Step title={props.title} description={props.description} key={props.title} />
                    ))
                }
            </Steps>

            <div className={contentClassName}>
                {content}
            </div>
        </div>
    )
}

export default StepsContainer;