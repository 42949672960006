import realStateIcon from '../../../icons/real-state.svg';
import serviceIcon from '../../../icons/repair-tool.svg';
import automotiveIcon from '../../../icons/drive.svg';
import { FormattedMessage } from 'react-intl';

function SelectIndustry({ onSelectIndustry }) {
    return (
        <div className='select-industry'>
            <p className='industry-label'>
                <FormattedMessage id='choose-industry' defaultMessage="Choose Industry" />
            </p>
            <div className='industries-container'>
                <div onClick={() => onSelectIndustry("Real State")} className='industry'>
                    <img src={realStateIcon} alt="real-state" />
                    <p className='title'>
                        <FormattedMessage id='real-state' defaultMessage="Real State" />
                    </p>
                </div>
                <div onClick={() => onSelectIndustry("Services")} className='industry'>
                    <img src={serviceIcon} alt="services" />
                    <p className='title'>
                        <FormattedMessage id='services' defaultMessage="Services" />
                    </p>
                </div>
                <div onClick={() => onSelectIndustry("Automotive")} className='industry'>
                    <img src={automotiveIcon} alt="automotive" />
                    <p className='title'>
                        <FormattedMessage id='automotive' defaultMessage="Automotive" />
                    </p>
                </div>
            </div>
        </div>
    );
}

export default SelectIndustry;