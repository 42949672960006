import Table from './../../components/Table';
import DataGrid from './../../components/DataGrid';
import React, { useCallback, useEffect, useState } from 'react';
import { default_table_item_per_pages } from './../../constants/table_constants';
import { FormattedMessage } from 'react-intl';
import useGetAllColors from '../../hooks/get_all_colors';
import { Form, Modal, Spin } from 'antd';
import MainForm from '../../components/MainForm';
import useCreateColor from '../../hooks/create_color';
import { withPrivate } from "../../components/PrivateRoute";
import { toast } from 'react-toastify';
import useUpdateConfig from '../../hooks/update_config';
import { color_columns, color_form_fields } from '../../constants/colors_datagrid';


function ListColors() {
    const { data, colors, loading, setReLoadColors } = useGetAllColors();
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    // Modal & Form 
    const { storeResponse, storeError, setColorData } = useCreateColor();
    const { updateResponse, updateError, setUpdatedConfigData } = useUpdateConfig();
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [editableColor, setEditableColor] = useState(null);
    const [formRef] = Form.useForm();

    const handlePageClick = useCallback((newPageNumber, itemsPerPage) => {
        const newOffset = (newPageNumber * itemsPerPage) % colors.length;
        const endOffset = newOffset + itemsPerPage;
        setPageCount(Math.ceil(colors.length / itemsPerPage));
        setCurrentItems(colors.slice(newOffset, endOffset));
    }, [colors]);

    useEffect(() => {
        if (colors && colors.length > 0) {
            setPageCount(0);
            handlePageClick(0, default_table_item_per_pages);
        } else {
            setCurrentItems([]);
        }
    }, [colors, handlePageClick]);

    // Handle User Requests Errors
    useEffect(() => {
        if (storeError) {
            toast(storeError, { type: 'error' });
        }
    }, [storeError]);

    useEffect(() => {
        if (updateError) {
            toast(updateError, { type: 'error' });
        }
    }, [updateError]);

    // Handle User Requests Responses
    useEffect(() => {
        if (storeResponse) {
            toast(<FormattedMessage id='store-success' values={{ name: <FormattedMessage id='color' defaultMessage="Color" /> }} defaultMessage="Success" />, { type: 'success' });
            setPageCount(0);
            setReLoadColors(true);
            formRef.resetFields();
        }
    }, [storeResponse, setReLoadColors]);

    useEffect(() => {
        if (updateResponse) {
            setReLoadColors(true);
            toast(<FormattedMessage id='update-success' values={{ name: <FormattedMessage id='color' defaultMessage="Color" /> }} defaultMessage="Success" />, { type: 'success' });
            formRef.resetFields();
            setPageCount(0);
        }
    }, [updateResponse, setReLoadColors]);

    // Handle Call Back Actions From Table Grid
    const onSubmitCallBack = (formValues) => {
        if (editableColor) {
            data.params.Colors.forEach((element, index) => {
                if (element.objectId === editableColor.objectId & element.id === editableColor.id){
                    data.params.Colors[index] = {
                        ...element,
                        ...formValues
                    };
                    console.log(element);
                }
            });
            setUpdatedConfigData(data);
            toast(<FormattedMessage id='loading' defaultMessage="Loading" />, { type: 'info' });
        } else {
            setColorData(formValues);
        }
        setIsVisibleModal(false);
    }

    const onEditCallBack = (color) => {
        formRef.setFieldsValue(color);
        setIsVisibleModal(true);
        setEditableColor(color);
    }

    const onDeleteCallBack = (color) => {
        data.params.Colors.forEach((element, index) => {
            if (element.id === color.id) {
                data.params.Colors.splice(index, 1);
            }
        });
        setUpdatedConfigData(data);
        toast(<FormattedMessage id='loading' defaultMessage="Loading" />, { type: 'info' });
    }


    return (
        <DataGrid
            header={<FormattedMessage id='dataGrid-colors-configurations' defaultMessage="Colors Configurations" />}
            // addNewBTN={true}
            // addNewBtnOnClick={() => setIsVisibleModal(true)}
            content={
                loading ?
                    <div className="spinner">
                        <Spin size="large" />
                    </div>
                    :
                    <>
                        <Table
                            columns={color_columns}
                            data={currentItems}
                            pageCount={pageCount}
                            total={colors.length}
                            onPageChange={handlePageClick}
                            initItemPerPages={default_table_item_per_pages}
                            actions={{
                                delete: true,
                                deleteCallBack: onDeleteCallBack,
                                deleteModelName: <FormattedMessage id='color' defaultMessage="Color" />,
                                edit: true,
                                editCallBack: onEditCallBack
                            }}
                        />
                        <Modal
                            title={<FormattedMessage id='dataGrid-add-btn-title' defaultMessage="Add New" />}
                            visible={isVisibleModal}
                            footer={null}
                            onCancel={() => setIsVisibleModal(false)}>
                            <MainForm
                                formName="store-color"
                                formRef={formRef}
                                fields={color_form_fields}
                                onSubmit={onSubmitCallBack}
                            />
                        </Modal>
                    </>
            }>
        </DataGrid>
    );
}

export default withPrivate(ListColors);