import { Button, Card, Spin, Typography } from "antd";
import { useEffect } from "react";
import SetupComplete from "../../assets/images/setup-complete.svg"
import useUpdateUser from "../../hooks/update_user";
import * as auth from './../../utils/auth';
import { useNavigate } from 'react-router-dom';
import { LIST_APPS } from './../../constants/routes';
import { useAuth } from "../AuthProvider";

const { Paragraph } = Typography;

function Complete() {
    const { updateResponse, loading, setUpdatedUserData } = useUpdateUser();
    const navigate = useNavigate();
    const { user } = useAuth();

    useEffect(() => {
        if (updateResponse) {
            auth.setCurrentUser({ ...user, onboarding: false });
            navigate(LIST_APPS);
        }
    }, [updateResponse]);

    const handleFinish = () => {
        setUpdatedUserData({ ...user, onboarding: false });
    }

    return (
        <>
            <Card className="boarding-card complete-wrapper">
                <div className="img">
                    <img src={SetupComplete} alt="setup complete" />
                </div>

                <Paragraph className="desc">
                    Please wait until your <span>IDENGAGER</span> Automotive <strong>Applications creation is Done</strong>
                </Paragraph>
            </Card>

            <div className="steps-footer" style={{ justifyContent: "end" }}>
                <Button disabled={loading} onClick={handleFinish}>
                    {
                        loading ? <Spin size="medium" className="spinner" /> : "Finish"
                    }
                </Button>
            </div>
        </>
    )
}

export default Complete;