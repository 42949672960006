import React from "react";
import { Card, Typography, Input } from "antd";
import { SETUP_DATA_SOURCES } from "../../constants/boarding";

const { Text } = Typography;

function DataSourceConfig({
    dataSourceConfig,
    setDataSourceConfig,
    error
}){
    const handleChange = e => {
        const data = {...dataSourceConfig.data, [e.target.name]: e.target.value};
        setDataSourceConfig({...dataSourceConfig, data });
    }

    const selectDataSourceType = sourceId => {
        setDataSourceConfig({ type: sourceId, data: {} });
    }

    const dataSource = React.useMemo(() => SETUP_DATA_SOURCES.find(source => source.id === dataSourceConfig.type))

    return (
        <div className="data-source-wrapper">
            {error.type && <Text className="top-error" type="danger">*{error.type}</Text>}
            <ul>
                {
                    SETUP_DATA_SOURCES.map(source => (
                        <Card
                            className={dataSourceConfig.type === source.id ? "boarding-card source-item hoverable active" : "boarding-card hoverable source-item"}
                            onClick={() => selectDataSourceType(source.id)}
                            key={source.id} 
                        >
                            <img src={source.img} alt={source.id} className="img" />
                            {source.name}
                        </Card>
                    ))
                }
            </ul>

            <form className="data-source-form boarding-card">
                {
                    dataSource.fields.map(field => (
                        <div className="form-group" key={field.name}>
                            <label htmlFor={field.name}>{field.label}</label>
                            <Input
                                id={field.name}
                                name={field.name}
                                type="text"
                                placeholder={field.placeholder}
                                value={dataSourceConfig.data[field.name]}
                                onChange={handleChange}
                            />                                  
                            {error[field.name] && <Text type="danger">{error[field.name]}</Text>}
                        </div>    
                    ))
                }
            </form>
        </div>
    )
}

export default DataSourceConfig;