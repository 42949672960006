import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { getActiveApp } from "../utils/storage";

export default function useUpdateConfig() {
    const [updatedConfigData, setUpdatedConfigData] = useState(null);
    const [updateResponse, setUpdateResponse] = useState(null);
    const [updateError, setUpdateError] = useState(null);
    const [loading, setLoading] = useState(false);

    const callAPI = useCallback(async (updatedConfigData) => {
        setUpdateResponse(null);
        setUpdateError(null);
        const app = getActiveApp();
        if (app) {

            try {
                const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/config";
                const headers = {
                    "Idengager-Application-Id": app.backendAppName || app.applicationID,
                    "Idengager-Master-Key": app.masterKey,
                };
                setLoading(true);
                const response = await axios.put((url), updatedConfigData, { headers: headers });
                setUpdateResponse(response.data);
                setUpdateError(null);
            } catch (err) {
                if (err.response) {
                    setUpdateError(err.response.data.error);
                }
            } finally {
                setLoading(false);
                setUpdatedConfigData(null);
            }
        }
    }, []);

    useEffect(() => {
        if (updatedConfigData) {
            callAPI(updatedConfigData);
        }
    }, [updatedConfigData, callAPI])

    return { updateResponse, updateError, loading, setUpdatedConfigData }
}