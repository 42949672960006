import axios from "axios";
import React from "react";
import { getApiUrlAndHeaders } from "../utils/api";
import useAsync from "./useAsync";

export const getLettersOfCredit = async () => {
  const { url, headers } = getApiUrlAndHeaders("/classes/LetterOfCredit");
  const response = await axios.get(url, { headers });
  return response.data.results;
};

export function useGetLetterOfCredit(id) {
  const async = useAsync();

  const getLetterOfCredit = async () => {
    const { url, headers } = getApiUrlAndHeaders("/classes/LetterOfCredit/" + id);
    const response = await axios.get(url, { headers });
    return response.data;
  };

  const revalidate = async () => {
    const lc = await getLetterOfCredit(id);
    async.setData(lc);
  };

  React.useEffect(() => {
    async.run(getLetterOfCredit());
  }, [id]);

  return { ...async, revalidate };
}

export function useCreateLetterOfCredit() {
  const { run, ...rest } = useAsync();

  const create = async (data) => {
    try {
      const { url, headers } = getApiUrlAndHeaders("/classes/LetterOfCredit");
      const response = await run(axios.post(url, data, { headers }));
      console.log('API response:', response);
      return response.data.objectId;
    } catch (error) {
      console.error('Error in createLetterOfCredit:', error);
      throw error;
    }
  };

  return {
    create,
    ...rest,
  };
}

export function useUpdateLetterOfCredit() {
  const { run, ...rest } = useAsync();

  const update = async (id, data) => {
    const { url, headers } = getApiUrlAndHeaders("/classes/LetterOfCredit/" + id);
    const response = await run(axios.put(url, data, { headers }));
    return response.data;
  };

  return {
    update,
    ...rest,
  };
}

export function useRemoveLetterOfCredit() {
  const { run, ...rest } = useAsync();

  const remove = async (id) => {
    const { url, headers } = getApiUrlAndHeaders("/classes/LetterOfCredit/" + id);
    const response = await run(axios.delete(url, { headers }));
    return response.data;
  };

  return {
    remove,
    ...rest,
  };
}
