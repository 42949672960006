import axios from "axios";
import { useCallback, useEffect, useState } from "react";

export default function useGetAllSubApps() {
    const url = process.env.REACT_APP_URL + `/idengager/classes/apps?where={"template":true}`;
   // const url = process.env.REACT_APP_DUMMY_DATA_URL + "/dummy/sub-apps.json";
    const [subApps, setSubApps] = useState([]);
    const [industryName, setIndustryName] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const callAPI = useCallback(async (industryName) => {
        try {
            const headers = {
                "Idengager-Application-Id": process.env.REACT_APP_AUTH_APP_ID,
                "Idengager-Master-Key": process.env.REACT_APP_AUTH_MASTER_KEY
            };
            setLoading(true);
            const response = await axios.get((url), { headers: headers });
            setSubApps(response.data.results);
            setIndustryName(null);
        } catch (err) {
            if (err.response) {
                setError(err.response.data.error);
            }
        } finally {
            setLoading(false);
        }
    }, [url]);

    useEffect(() => {
        if (industryName)
            callAPI(industryName);
    }, [industryName, callAPI])

    return { subApps, setIndustryName, loading, error }
}