import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import DataGrid from '../../components/DataGrid';
import { withPrivate } from "../../components/PrivateRoute";
import useGetSetupMessages from '../../hooks/get_setup_messages';
import { Form, Modal, Spin } from 'antd';
import MainForm from '../../components/MainForm';
import { default_table_item_per_pages } from '../../constants/table_constants';
import useUpdateConfig from '../../hooks/update_config';
import { toast } from 'react-toastify';
import Table from '../../components/Table';
import { messages_columns, message_setup_form_fields } from '../../constants/messages_datagrid';

function ListMessages() {
    const { data, messages, loading, setReLoadMessages } = useGetSetupMessages();
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    // Modal & Form 
    const { updateResponse, updateError, setUpdatedConfigData } = useUpdateConfig();
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [editableMessage, setEditableMessage] = useState(null);
    const [formRef] = Form.useForm();

    const handlePageClick = useCallback((newPageNumber, itemsPerPage) => {
        const newOffset = (newPageNumber * itemsPerPage) % messages.length;
        const endOffset = newOffset + itemsPerPage;
        setPageCount(Math.ceil(messages.length / itemsPerPage));
        setCurrentItems(messages.slice(newOffset, endOffset));
    }, [messages]);

    useEffect(() => {
        if (messages && messages.length > 0) {
            setPageCount(0);
            handlePageClick(0, default_table_item_per_pages);
        } else {
            setCurrentItems([]);
        }
    }, [messages, handlePageClick]);

    // Handle User Requests Errors
    useEffect(() => {
        if (updateError) {
            toast(updateError, { type: 'error' });
        }
    }, [updateError]);


    // Handle User Requests Responses
    useEffect(() => {
        if (updateResponse) {
            setReLoadMessages(true);
            toast(<FormattedMessage id='update-success' values={{ name: <FormattedMessage id='message' defaultMessage="Message" /> }} defaultMessage="Success" />, { type: 'success' });
            formRef.resetFields();
            setPageCount(0);
        }
    }, [updateResponse, setReLoadMessages]);


    // Handle Call Back Actions From Table Grid
    const onSubmitCallBack = (formValues) => {
        if (editableMessage) {
            data.params.Messages.forEach((element, index) => {
                if (element.id === editableMessage.id) {
                    data.params.Messages[index] = {
                        ...element,
                        ...formValues
                    };
                    console.log(element);
                }
            });
            setUpdatedConfigData(data);
            toast(<FormattedMessage id='loading' defaultMessage="Loading" />, { type: 'info' });
        }
        setIsVisibleModal(false);
    }

    const onEditCallBack = (message) => {
        formRef.setFieldsValue(message);
        setIsVisibleModal(true);
        setEditableMessage(message);
    }

    const onDeleteCallBack = (message) => {
        data.params.Messages.forEach((element, index) => {
            if (element.id === message.id) {
                data.params.Messages.splice(index, 1);
            }
        });
        setUpdatedConfigData(data);
        toast(<FormattedMessage id='loading' defaultMessage="Loading" />, { type: 'info' });
    }


    return (
        <DataGrid
            header={<FormattedMessage id='dataGrid-messages-configuration' defaultMessage="Messages Configuration" />}
            content={
                loading ?
                    <div className="spinner">
                        <Spin size="large" />
                    </div>
                    :
                    <>
                        <Table
                            columns={messages_columns}
                            data={currentItems}
                            pageCount={pageCount}
                            total={messages.length}
                            onPageChange={handlePageClick}
                            initItemPerPages={default_table_item_per_pages}
                            actions={{
                                delete: true,
                                deleteCallBack: onDeleteCallBack,
                                deleteModelName: <FormattedMessage id='message' defaultMessage="Message" />,
                                edit: true,
                                editCallBack: onEditCallBack
                            }}
                        />
                        <Modal
                            title={<FormattedMessage id='dataGrid-add-btn-title' defaultMessage="Add New" />}
                            visible={isVisibleModal}
                            footer={null}
                            onCancel={() => setIsVisibleModal(false)}>
                            <MainForm
                                formRef={formRef}
                                fields={message_setup_form_fields}
                                onSubmit={onSubmitCallBack}
                            />
                        </Modal>
                    </>
            }>
        </DataGrid>
    );
}

export default withPrivate(ListMessages);