import Table from './../../components/Table';
import DataGrid from './../../components/DataGrid';
import React, { useCallback, useEffect, useState } from 'react';
import { default_table_item_per_pages } from './../../constants/table_constants';
import { FormattedMessage } from 'react-intl';
import useGetAllRules from '../../hooks/get_all_rules';
import Modal from 'antd/lib/modal/Modal';
import MainForm from '../../components/MainForm';
import useCreateRule from '../../hooks/create_rule';
import { withPrivate } from "../../components/PrivateRoute";
import { Form, Spin } from 'antd';
import { toast } from 'react-toastify';
import useDeleteRule from '../../hooks/delete_rule';
import useUpdateRule from '../../hooks/update_rule';
import { rule_columns, rule_form_fields } from '../../constants/rules_datagrid';

function ListRules() {
    const { rules, loading, setReLoadRules } = useGetAllRules();
    const [currentItems, setCurrentItems] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    // Modal & Form State
    const { storeResponse, storeError, setRuleData } = useCreateRule();
    const { updateError, updateResponse, setUpdatedRuleData } = useUpdateRule();
    const { setRuleId, deleteResponse, deleteError } = useDeleteRule();
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [fields] = useState(rule_form_fields);
    const [editableRule, setEditableRule] = useState(false);
    const [formRef] = Form.useForm();

    const handlePageClick = useCallback((newPageNumber, itemsPerPage) => {
        const newOffset = (newPageNumber * itemsPerPage) % rules.length;
        const endOffset = newOffset + itemsPerPage;
        setPageCount(Math.ceil(rules.length / itemsPerPage));
        setCurrentItems(rules.slice(newOffset, endOffset));
    }, [rules]);

    useEffect(() => {
        if (rules && rules.length > 0) {
            setPageCount(0);
            handlePageClick(0, default_table_item_per_pages);
        } else {
            setCurrentItems([]);
        }
    }, [rules, handlePageClick]);

    const openModal = () => {
        setIsVisibleModal(true);
        setEditableRule(null);
        formRef.resetFields();
    }

    // Handle Rule Requests Errors
    useEffect(() => {
        if (storeError) {
            toast(storeError, { type: 'error' });
        }
    }, [storeError]);

    useEffect(() => {
        if (updateError) {
            toast(updateError, { type: 'error' });
        }
    }, [updateError]);

    useEffect(() => {
        if (deleteError) {
            toast(deleteError, { type: 'error' });
        }
    }, [deleteError]);


    // Handle Rule Requests Responses
    useEffect(() => {
        if (storeResponse) {
            setReLoadRules(true);
            toast(<FormattedMessage id='store-success' values={{ name: <FormattedMessage id='rule' defaultMessage="Rule" /> }} defaultMessage="Success" />, { type: 'success' });
            setIsVisibleModal(false);
            setPageCount(0);
        }
    }, [storeResponse, setReLoadRules]);

    useEffect(() => {
        if (updateResponse) {
            setReLoadRules(true);
            toast(<FormattedMessage id='update-success' values={{ name: <FormattedMessage id='user' defaultMessage="User" /> }} defaultMessage="Success" />, { type: 'success' });
            setIsVisibleModal(false);
            setPageCount(0);
        }
    }, [updateResponse, setReLoadRules]);

    useEffect(() => {
        if (deleteResponse) {
            setReLoadRules(true);
            toast(<FormattedMessage id='delete-success' values={{ name: <FormattedMessage id='rule' defaultMessage="Rule" /> }} defaultMessage="Success" />, { type: 'success' });
            setPageCount(0);
        }
    }, [deleteResponse, setReLoadRules]);


    // Handle Call Back Actions From Table Grid
    const onSubmitCallBack = (formValues) => {
        if (editableRule) {
            formValues.objectId = editableRule.objectId
            setUpdatedRuleData(formValues);
        } else {
            setRuleData(formValues);
        }
    }

    const onDeleteCallBack = (rule) => {
        setRuleId(rule.objectId);
    }

    const onEditCallBack = (rule) => {
        rule.ACL = JSON.stringify(rule.ACL);
        formRef.setFieldsValue(rule);
        setIsVisibleModal(true);
        setEditableRule(rule);
    }

    return (
        <DataGrid
            header={<FormattedMessage id='dataGrid-rules-management' defaultMessage="Rules Management" />}
            addNewBTN={true}
            addNewBtnOnClick={openModal}
            content={
                loading ?
                    <div className="spinner">
                        <Spin size="large" />
                    </div>
                    :
                    <>
                        <Table
                            columns={rule_columns}
                            data={currentItems}
                            pageCount={pageCount}
                            total={rules.length}
                            onPageChange={handlePageClick}
                            initItemPerPages={default_table_item_per_pages}
                            actions={{
                                delete: true,
                                deleteCallBack: onDeleteCallBack,
                                deleteModelName: <FormattedMessage id='rule' defaultMessage="Rule" />,
                                edit: true,
                                editCallBack: onEditCallBack
                            }}
                        />
                        <Modal
                            title={<FormattedMessage id='dataGrid-add-btn-title' defaultMessage="Add New" />}
                            visible={isVisibleModal}
                            footer={null}
                            onCancel={() => setIsVisibleModal(false)}>
                            <MainForm
                                formRef={formRef}
                                fields={fields}
                                onSubmit={onSubmitCallBack}
                            />
                        </Modal>
                    </>
            }>
        </DataGrid>
    );
}

export default withPrivate(ListRules);