import { Navigate, useLocation } from "react-router-dom";
import * as ROUTES from "../constants/routes";
import * as auth from './../utils/auth';
import { useAuth } from "./AuthProvider";

function withPrivate(Route) {
    return function (props) {
        const { user } = useAuth();
        // const { user } = useAuth();
        const { pathname } = useLocation();

        if (!user || !user.admin) {
            return <Navigate to={ROUTES.LOGIN} />
        }

        if (user.onboarding && !pathname.startsWith(ROUTES.BOARDING)) {
            return <Navigate to={ROUTES.BOARDING} />
        }

        return <Route {...props} />
    }
}

export {
    withPrivate
}