import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { getActiveApp } from "../utils/storage";

export default function useCreateTrigger() {
    const [triggerData, setAddTriggerData] = useState(null);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    function reSetEmpty() {
        setAddTriggerData(null);
        setResponse(null);
        setError(null);
    }

    const callAPI = useCallback(async (triggerData) => {
        const app = getActiveApp();
        if (app) {
            try {
                const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/triggers";
                const headers = {
                    "Idengager-Application-Id": app.backendAppName || app.applicationID,
                    "Idengager-Master-Key": app.masterKey,
                };
                setLoading(true);
                const response = await axios.post(url, triggerData, { headers: headers });
                setResponse(response.data);
            } catch (err) {
                if (err.response) {
                    setError(err.response.data.error);
                }
            } finally {
                setLoading(false);
            }
        }
    }, []);

    useEffect(() => {
        if (triggerData)
            callAPI(triggerData);
    }, [triggerData, callAPI])

    return { response, error, loading, setAddTriggerData, reSetEmpty }
}