import { useEffect, useState } from "react";
import { getActiveApp } from "../utils/storage";
import useFetchAll from "./fetch_all";

export default function useGetAllTriggers() {
     /**
   * Fetches triggers
   * lines - maximum number of lines to fetch
   * since - only fetch lines since this Date
   */

    const { data, loading, setRoute, setHeaders } = useFetchAll();
    const [triggers, setTriggers] = useState([]);
    const [reLoadTriggers, setReLoadTriggers] = useState(true);

    useEffect(() => {
        if (data) {
            setTriggers(data);
        }
            // console.trigger("data"+JSON.stringify([data[0]]));
    }, [data]);

    useEffect(() => {
        if (loading === false) {
            setReLoadTriggers(false);
        }
    }, [loading]);

    useEffect(() => {
        const app = getActiveApp();
        if (reLoadTriggers && app) {
            const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + '/hooks/triggers' ;
            const headers = {
                "Idengager-Application-Id": app.backendAppName || app.applicationID,
                "Idengager-Master-Key": app.masterKey
            };
            setHeaders(headers);
            setRoute(url);
        }
    }, [reLoadTriggers, setRoute, setHeaders]);

    return { triggers, loading, setReLoadTriggers }
}