import { isEmpty } from "./helpers";
import { SETUP_DATA_SOURCES } from "../constants/boarding";

function validateDataSourceForm({
    type,
    data
}){
    const error = {};

    const { fields } = SETUP_DATA_SOURCES.find(source => source.id === type);

    if(!type){
        error.type = "please select your data source type";
    }

    for(let { name } of fields){
        if(!data[name]) error[name] = `${name} is required`;
    }

    return {
        isValid: isEmpty(error),
        error
    }
}

export default validateDataSourceForm;