import React from "react";
import useAsync from "../hooks/useAsync";
import * as auth from "../utils/auth";
import { Spin } from "antd";

const AuthContext = React.createContext();
AuthContext.displayName = "AuthContext";

function bootstrap() {
    const user = auth.getCurrentUser();

    if (!user) return null;

    //auth.setAuthHeader(user.sessionToken);

    return user;
}

function AuthProvider(props) {
    const {
        data: user,
        error,
        isLoading,
        isIdle,
        isError,
        isSuccess,
        status,
        setData,
        run
    } = useAsync({ data: bootstrap(), status: "resolved" });

    // used if token required to validate
    // or if the webste is server side rendered
    // React.useEffect(
    //     () => {
    //         const bootstrapPromise = bootstrap();
    //         run(bootstrapPromise);
    //     },
    //     [run]
    // )

    const login = React.useCallback(
        data => {
            return auth.login(data).then(setData);
        },
        []
    );

    const logout = React.useCallback(
        () => {
            auth.logout();
            setData(null);
        }
    );

    const value = {
        login,
        logout,
        user
    };

    if (isLoading || isIdle) {
        return (
            <div className="full-page-spinner">
                <Spin size="large" />
            </div>
        )
    }

    if (isError) {
        return (
            <div className="full-page-error">
                {error.message}
            </div>
        )
    }

    if (isSuccess) {
        return (
            <AuthContext.Provider value={value} {...props} />
        )
    }

    throw new Error(`Unhandled status: ${status}`);
}

export function useAuth() {
    const value = React.useContext(AuthContext);

    if (!value) {
        throw new Error("useAuth must be used inside an AuthProvider");
    }

    return value;
}

export default AuthProvider;