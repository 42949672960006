import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { getActiveApp } from "../utils/storage";

export default function useDeleteDevice() {
    const [deviceId, setDeviceId] = useState(null);
    const [deleteResponse, setDeleteResponse] = useState(null);
    const [deleteError, setDeleteError] = useState(null);
    const [loading, setLoading] = useState(false);

    const callAPI = useCallback(async (deviceId) => {
        setDeleteResponse(null);
        setDeleteError(null);
        const app = getActiveApp();
        if (app) {
            try {
                const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/classes/_Installation/";
                const headers = {
                    "Idengager-Application-Id": app.backendAppName || app.applicationID,
                    "Idengager-Master-Key": app.masterKey,
                };
                setLoading(true);
                const response = await axios.delete((url + deviceId), { headers: headers });
                setDeleteResponse(response.data);
                setDeleteError(null);
            } catch (err) {
                if (err.response) {
                    setDeleteError(err.response.data.error);
                }
            } finally {
                setLoading(false);
                setDeviceId(null);
            }
        }
    }, []);

    useEffect(() => {
        if (deviceId) {
            callAPI(deviceId);
        }
    }, [deviceId, callAPI])

    return { deleteResponse, deleteError, loading, setDeviceId }
}