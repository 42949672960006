import React from "react";
import { Card, Typography } from "antd";
import { SETUP_INDUSTRIES } from "../../constants/boarding";

const { Text } = Typography;

function IndustryOptions({
    selectIndustry,
    selectedIndustry
}){
    return (
        <>
            <div className="industry-options-wrapper">
                {
                    SETUP_INDUSTRIES.map(industry => (
                        <Card 
                            className={selectedIndustry === industry.id ? "boarding-card industry-item hoverable active" : "boarding-card hoverable industry-item"}
                            key={industry.id} 
                            onClick={() => selectIndustry(industry.id)}
                        >
                            <div className="img">
                                <div className="img-bg" style={{backgroundColor: industry.bg}}></div>
                                <img 
                                    src={industry.img}
                                    alt={industry.description} 
                                />
                            </div>
                            <Text className="title">{industry.title}</Text>
                            <Text className="description">{industry.description}</Text>
                        </Card>
                    ))
                }
            </div>
        </>
    )
}

export default IndustryOptions;