import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import NotificationModal from './NotificationModal';

const FloatingNotificationIcon = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hasPendingRequests, setHasPendingRequests] = useState(false);

  useEffect(() => {
    const checkPendingRequests = () => {
      const pendingRequests = JSON.parse(localStorage.getItem('pendingRequests')) || [];
      setHasPendingRequests(pendingRequests.length > 0);
    };

    checkPendingRequests();
    window.addEventListener('storage', checkPendingRequests);

    return () => {
      window.removeEventListener('storage', checkPendingRequests);
    };
  }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <div style={{
      position: 'fixed',
      bottom: '90px',
      right: '20px',
      zIndex: 9999
    }}>
      <div style={{ position: 'relative' }}>
        {hasPendingRequests && (
          <div
            style={{
              position: 'absolute',
              top: '-5px',
              left: '-5px',
              width: '20px',
              height: '20px',
              borderRadius: '50%',
              backgroundColor: 'red',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '14px',
              fontWeight: 'bold',
              zIndex: 1
            }}
          >
            !
          </div>
        )}
        <Button
          shape="circle"
          icon={<BellOutlined style={{ fontSize: '24px' }} />}
          size="large"
          onClick={showModal}
          style={{ 
            backgroundColor: '#1890ff', 
            color: '#fff',
            width: '55px',
            height: '55px'
          }}
        />
      </div>
      <NotificationModal visible={isModalVisible} onClose={handleModalClose} />
    </div>
  );
};

export default FloatingNotificationIcon;
