import React from 'react';
import { Table, Spin, Alert } from 'antd';
// import { useGetLCTransactions } from '../../hooks/useLCTransactions';

const LGTransactionsTable = ({ lcId }) => {
//   const { transactions, loading, error } = useGetLCTransactions(lcId);

//   if (loading) return <Spin />;
//   if (error) return <Alert message="Error" description={error.message} type="error" showIcon />;

  const columns = [
    { title: 'LC Amount', dataIndex: 'lcAmount', key: 'lcAmount' },
    { title: 'Financial Transactions', dataIndex: 'financialTransactions', key: 'financialTransactions' },
    { title: 'Balance', dataIndex: 'balance', key: 'balance' },
  ];
//   dataSource={transactions}
  return <Table columns={columns}  rowKey="transactionId" />;
};

export default LGTransactionsTable;
