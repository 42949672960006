import { useEffect, useState } from "react";
import useFetchAll from "./fetch_all";
import { getActiveApp } from "../utils/storage";

export default function useGetSetupMessages() {
    const { data, loading, setRoute, setHeaders } = useFetchAll();
    const [messages, setMessages] = useState([]);
    const [reLoadMessages, setReLoadMessages] = useState(true);

    useEffect(() => {
        if (data && data.params && data.params.Messages) {
            setMessages(data.params.Messages);
        }
    }, [data]);

    useEffect(() => {
        if (loading === false) {
            setReLoadMessages(false);
        }
    }, [loading]);

    useEffect(() => {
        const app = getActiveApp();
        if (reLoadMessages && app) {
            const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/config";
            const headers = {
                "Idengager-Application-Id": app.backendAppName || app.applicationID,
                "Idengager-Master-Key": app.masterKey,
            };
            setHeaders(headers);
            setRoute(url);
        }
    }, [reLoadMessages, setRoute, setHeaders]);

    return { data, messages, loading, setReLoadMessages }
}