import { useCallback, useEffect, useState } from "react";
import axios from "axios";

export default function useCreateColor() {
    const url = process.env.REACT_APP_APP_URL + "/classes/Colors";
    const [colorData, setColorData] = useState(null);
    const [storeResponse, setStoreResponse] = useState(null);
    const [storeError, setStoreError] = useState(null);
    const [loading, setLoading] = useState(false);

    const callAPI = useCallback(async (colorData) => {
        try {
            const headers = {
                "Idengager-Application-Id": "mobidevlabs",
                "Idengager-Master-Key": "mobidevlabsMaster"
            };
            setLoading(true);
            const response = await axios.post(url, colorData, { headers: headers });
            setStoreResponse(response.data);
            setStoreError(null);
        } catch (err) {
            if (err.response) {
                setStoreError(err.response.data.error);
            }
        } finally {
            setLoading(false);
            setColorData(null);
        }
    }, [url]);

    useEffect(() => {
        if (colorData) {
            callAPI(colorData);
        }
    }, [colorData, callAPI])

    return { storeResponse, storeError, loading, setColorData }
}