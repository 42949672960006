import React from "react";
import { useAuth } from "../components/AuthProvider";
import { withPrivate } from "../components/PrivateRoute";
import LargeAvatar from "../assets/images/large-avatar.png";
import SmallAvatar from "../assets/images/small-avatar.png";
import CompanyAvatar from "../assets/images/company-avatar.png";
import BellIcon from "../assets/images/bell.svg";
import { Form, Input, Dropdown, Menu, MenuItem } from "antd";
import "../assets/css/profile.css";
import { Link } from "react-router-dom";
import ProfileHeader from "../components/ProfileHeader";

function UserInfo(){
    const { user } = useAuth();
    const [editable, setEditable] = React.useState(false);
    const [infoForm] = Form.useForm();
    const [companyForm] = Form.useForm();

    const toggle = () => setEditable(!editable);

    const handleSave = () => {
        toggle();
        console.log({
            ...infoForm.getFieldsValue(),
            ...companyForm.getFieldsValue()
        })
    }

    const handleCancel = () => {
        toggle();

        infoForm.resetFields();
        companyForm.resetFields();
    }

    return (
       <div className="user-info-wrapper">
            <Form 
                className="user-info-form"
                name="user-info"
                form={infoForm}
                initialValues={{
                    fullName: user.username,
                    username: user.username,
                    email: user.email,
                    mobile: "",
                    subscription: "Type 1",
                    activate: "Activate"
                }}
            >
                <Form.Item
                    label="Full Name"
                    name="fullName"
                >
                    <Input readOnly={!editable} />
                </Form.Item>

                <Form.Item
                    label="Username"
                    name="username"
                >
                    <Input readOnly={!editable} />
                </Form.Item>

                <Form.Item
                    label="Email Address"
                    name="email"
                >
                    <Input readOnly={true} />
                </Form.Item>

                {/* <Form.Item
                    label="Mobile Number"
                    name="mobile"
                >
                    <Input placeholder="+201098675637" readOnly={!editable} />
                </Form.Item>

                <Form.Item
                    label="Subscription Type"
                    name="subscription"
                >
                    <Input readOnly={!editable} />
                </Form.Item>

                <Form.Item
                    label="Activate"
                    name="activate"
                >
                    <Input readOnly={!editable} />
                </Form.Item>
                
                <Form.Item className="change-password">
                    <Link to="/change-password">Change Password</Link>
                </Form.Item> */}
            </Form>

            <div className="divider"></div>

            <div className="company-information">
                <h3>Company Information</h3>

                <div className="avatar-wrapper">
                    <img src={CompanyAvatar} />

                    <span className="add-avatar-btn avatar-action">
                        Upload a photo
                    </span>
                </div>

                <Form
                    className="user-info-form"
                    company="company"
                    form={companyForm}
                    initialValues={{
                        companyName: ""
                    }}
                >
                    <Form.Item label="Company" name="companyName">
                        <Input placeholder="xyz" readOnly={!editable} />
                    </Form.Item>
                </Form>
            </div>

            <div className="toggle-btns">
                {
                    editable ?
                        <>
                            <button className="cancel-edit user-info-btn" onClick={handleCancel}>
                                Cancel
                            </button>

                            <button className="save-edit user-info-btn" onClick={handleSave}>
                                Save
                            </button>
                        </>
                        :
                        <button onClick={toggle} className="user-info-btn">
                            Edit
                        </button>
                }
            </div>
       </div>
    )
}

function Profile(){
    const { user } = useAuth();
    
    return (
        <div className="profile-wrapper">
            <ProfileHeader />

            <div className="profile-content">
                <h2>Edit Profile</h2>

                <div className="avatar-wrapper">
                    {/* <img src={LargeAvatar} /> */}
                    <div className="user-avatar large">
                        <span>{user.username[0]}</span>
                    </div>

                    <span className="remove-avatar-btn avatar-action">
                        Remove
                    </span>
                </div>

                <UserInfo />
            </div>    
        </div>
    )
}

export default withPrivate(Profile);