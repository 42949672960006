// src/pages/Loan/LoanPage.js
import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, message, Space, Popconfirm } from 'antd';
import { useNavigate } from 'react-router-dom';
import CreateLoanForm from '../../components/Loan/CreateLoanForm';
import { getLoans, useCreateLoan, useRemoveLoan } from '../../hooks/useLoan';

const LoanPage = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { create, loading: createLoading } = useCreateLoan();
  const { remove } = useRemoveLoan();
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getLoans();
      setData(result);
    } catch (error) {
      console.error('Error fetching loans:', error);
      message.error('Failed to fetch loans');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCreate = async (values) => {
    try {
      await create(values);
      message.success('Loan created successfully');
      setIsModalVisible(false);
      fetchData();  // Refresh the data
    } catch (error) {
      console.error('Error creating loan:', error);
      message.error('Failed to create loan');
    }
  };

  const handleDelete = async (id) => {
    try {
      await remove(id);
      message.success('Loan deleted successfully');
      fetchData();  // Refresh the data
    } catch (error) {
      console.error('Error deleting loan:', error);
      message.error('Failed to delete loan');
    }
  };

  const handleViewDetails = (record) => {
    navigate(`/loan/${record.objectId}`);
  };

  const columns = [
    {
      title: 'System Code',
      dataIndex: 'systemCode',
      key: 'systemCode',
      sorter: (a, b) => a.systemCode.localeCompare(b.systemCode),
    },
    {
      title: 'Bank',
      dataIndex: 'bank',
      key: 'bank',
      sorter: (a, b) => a.bank.localeCompare(b.bank),
    },
    {
      title: 'English Description',
      dataIndex: 'englishDescription',
      key: 'englishDescription',
      sorter: (a, b) => a.englishDescription.localeCompare(b.englishDescription),
    },
    {
      title: 'Arabic Description',
      dataIndex: 'arabicDescription',
      key: 'arabicDescription',
      sorter: (a, b) => a.arabicDescription.localeCompare(b.arabicDescription),
    },
    {
      title: 'Loan Issue Date',
      dataIndex: 'loanIssueDate',
      key: 'loanIssueDate',
      sorter: (a, b) => new Date(a.loanIssueDate) - new Date(b.loanIssueDate),
      render: (text) => text ? new Date(text).toLocaleDateString() : 'N/A',
    },
    {
      title: 'Loan Type',
      dataIndex: 'loanType',
      key: 'loanType',
      sorter: (a, b) => a.loanType.localeCompare(b.loanType),
    },
    {
      title: 'Bank Facility',
      dataIndex: 'bankFacility',
      key: 'bankFacility',
      sorter: (a, b) => a.bankFacility.localeCompare(b.bankFacility),
    },
    {
      title: 'First Due Date',
      dataIndex: 'firstDueDate',
      key: 'firstDueDate',
      sorter: (a, b) => new Date(a.firstDueDate) - new Date(b.firstDueDate),
      render: (text) => text ? new Date(text).toLocaleDateString() : 'N/A',
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      key: 'vendor',
      sorter: (a, b) => a.vendor.localeCompare(b.vendor),
    },
    {
      title: 'Number Of Years',
      dataIndex: 'numberOfYears',
      key: 'numberOfYears',
      sorter: (a, b) => a.numberOfYears - b.numberOfYears,
    },
    {
      title: 'Payment Amount',
      dataIndex: 'paymentAmount',
      key: 'paymentAmount',
      sorter: (a, b) => a.paymentAmount - b.paymentAmount,
    },
    {
      title: 'Interest Rate',
      dataIndex: 'interestRate',
      key: 'interestRate',
      sorter: (a, b) => a.interestRate - b.interestRate,
    },
    {
      title: 'Sabor Rate',
      dataIndex: 'saborRate',
      key: 'saborRate',
      sorter: (a, b) => a.saborRate - b.saborRate,
    },
    {
      title: 'Loan Reference',
      dataIndex: 'loanReference',
      key: 'loanReference',
      sorter: (a, b) => a.loanReference.localeCompare(b.loanReference),
    },
    {
      title: 'Present Value',
      dataIndex: 'presentValue',
      key: 'presentValue',
      sorter: (a, b) => a.presentValue - b.presentValue,
    },
    {
      title: 'Loan Status',
      dataIndex: 'loanStatus',
      key: 'loanStatus',
      sorter: (a, b) => a.loanStatus.localeCompare(b.loanStatus),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => handleViewDetails(record)}>View Details</Button>
          <Popconfirm
            title="Are you sure you want to delete this Loan?"
            onConfirm={() => handleDelete(record.objectId)}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div style={{ padding: '24px', paddingTop: '75px' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
        <h2>Loans</h2>
        <Button type="primary" onClick={showModal}>
          Create New Loan
        </Button>
      </div>
      <Table 
        columns={columns} 
        dataSource={data} 
        loading={loading} 
        rowKey="objectId" 
        pagination={{ pageSize: 10 }} 
        style={{ marginBottom: '16px' }}
      />
      <Modal
        title="Create New Loan"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <CreateLoanForm onSubmit={handleCreate} loading={createLoading} />
      </Modal>
    </div>
  );
};

export default LoanPage;
