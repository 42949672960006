import sessionsHeaderImg from './../../assets/images/sessions-header-img.svg';
import Header from './../../components/Header';
import ConfigOverviewSlider from './../../components/ConfigOverviewSlider';
import * as ROUTES from './../../constants/routes';
import { Space } from 'antd';
import { useState } from 'react';
import sliderImage1 from './../../assets/images/sessions-slider-1.png';
import { withPrivate } from "../../components/PrivateRoute";


function Sessions() {
    const [sliderElemnts] = useState([
        {
            title: "Configure Users Sessions",
            image: sliderImage1
        }
    ]);

    return (
        <>
            <Header
                background="#4ACEC7"
                title="idEngager Sessions"
                buttonTitle="Configure Sessions"
                buttonUrl={ROUTES.LIST_SESSIONS}
                img={sessionsHeaderImg}
            />
            <Space className="overview-slider-container">
                <ConfigOverviewSlider
                    elements={sliderElemnts}
                />
            </Space>
        </>
    );
}

export default withPrivate(Sessions);