import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { getActiveApp } from "../utils/storage";

export default function useCreateClass() {
    const [classData, setClassData] = useState(null);
    const [storeObjName, setStoreObjName] = useState(null);
    const [storeResponse, setStoreResponse] = useState(null);
    const [storeError, setStoreError] = useState(null);
    const [loading, setLoading] = useState(false);

    const callAPI = useCallback(async (storeObjName, classData) => {
        setStoreResponse(null);
        setStoreError(null);
        const app = getActiveApp();
        if (app) {
            try {
                const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/classes/";
                const headers = {
                    "Idengager-Application-Id": app.backendAppName || app.applicationID,
                    "Idengager-Master-Key": app.masterKey,
                };
                setLoading(true);
                const response = await axios.post(url + storeObjName, classData, { headers: headers });
                setStoreResponse(response.data);
                setStoreObjName(null);
                setClassData(null);
            } catch (err) {
                if (err.response) {
                    setStoreError(err.response.data.error);
                }
            } finally {
                setLoading(false);
            }
        }
    }, []);

    useEffect(() => {
        if (storeObjName && classData) {
            if (classData.ACL) {
                classData.ACL = JSON.parse(classData.ACL);
            }
            callAPI(storeObjName, classData);
        }
    }, [storeObjName, classData, callAPI])

    return { storeResponse, storeError, loading, setStoreObjName, setClassData }
}