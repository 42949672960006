import { useEffect, useState } from "react";
import useFetchAll from "./fetch_all";
import { getActiveApp } from "../utils/storage";

export default function useGetAllColors() {
    const { data, loading, setRoute, setHeaders } = useFetchAll();
    const [colors, setColors] = useState([]);
    const [reLoadColors, setReLoadColors] = useState(true);

    useEffect(() => {
        if (data && data.params && data.params.Colors) {
            setColors(data.params.Colors);
        }
    }, [data]);

    useEffect(() => {
        if (loading === false) {
            setReLoadColors(false);
        }
    }, [loading]);

    useEffect(() => {
        const app = getActiveApp();
        if (reLoadColors && app) {
            const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/config";
            const headers = {
                "Idengager-Application-Id": app.backendAppName || app.applicationID,
                "Idengager-Master-Key": app.masterKey,
            };
            setHeaders(headers);
            setRoute(url);
        }
    }, [reLoadColors, setRoute, setHeaders]);

    return { data, colors, loading, setReLoadColors }
}