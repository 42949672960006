const rule_columns = [
    {
        Header: 'ID',
        accessor: 'objectId'
    },
    {
        Header: 'Name',
        accessor: 'name'
    },
    {
        Header: 'Users',
        accessor: 'users',
        Cell: (props) => {
            return (
                JSON.stringify(props.value)
            );
        }
    },
    {
        Header: 'Roles',
        accessor: 'roles',
        Cell: (props) => {
            return (
                JSON.stringify(props.value)
            );
        }
    },
    {
        Header: 'ACL',
        accessor: 'ACL',
        Cell: (props) => {
            return (
                JSON.stringify(props.value)
            );
        }
    },
    {
        Header: 'Created At',
        accessor: 'createdAt'
    }
];


const rule_form_fields = [
    {
        name: 'name',
        label: 'Rule Name',
        type: 'text',
        rules: [
            {
                required: true,
                message: 'Please enter rule name',
            },
        ],
        value: ''
    },
    {
        name: 'ACL',
        label: 'ACL',
        type: 'textarea',
        rules: [
            {
                required: true,
                message: 'Please enter rule ACL',
            },
        ],
        value: ''
    }
];

export {
    rule_columns,
    rule_form_fields
}