import { Empty } from 'antd';
import React from 'react';

function EnterpriseAlert(props) {
    return (
        <div className="enterprise-alert-container">
            <Empty
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{
                    height: 100,
                }}
                description={<p className="enterprise-alert">This Feature is available only in Enterprise Subscription</p>} />
        </div>
    );
}

export default EnterpriseAlert;