import { useState } from "react";
import { Button, Tour } from "antd";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { tours } from "./GlobalTour.constants";
import onboardingImg from "../../assets/images/onboarding.webp";
import "./GlobalTour.styles.css";

function GlobalTour() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const tour = searchParams.get("tour");
    const steps = tour && tour !== "null" ? tours(navigate)[tour] || [] : [];

    const handleClose = () => {
        setSearchParams({ isToured: true });
    };

    return (
        <>
            <Tour
                open={!!tour}
                steps={steps.map((step) => {
                    return {
                        ...step,
                        target: () => document.querySelector(step.target),
                    };
                })}
                onClose={handleClose}
                placement="right"
            />
        </>
    );
}

export function OnboardingCard() {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const [isOpened, setIsOpened] = useState(pathname === "/");

    const handleSkip = () => {
        setIsOpened(false);
    };

    const handleStart = () => {
        setIsOpened(false);
        navigate("/?tour=select_app");
    };

    return (
        <div
            className="OnboardingCard"
            style={{
                display: isOpened ? "block" : "none",
            }}
        >
            <div>
                <img
                    className="OnboardingCard__img"
                    src={onboardingImg}
                    alt="onboarding"
                />
            </div>

            <div className="OnboardingCard__body">
                <div className="OnboardingCard__title">
                    Welcome to Bankors
                </div>

                <div className="OnboardingCard__desc">
                    Take a few moments to learn how to best use our features.
                    You will reap the benefits of increased efficiency later.
                </div>

                <div className="OnboardingCard__footer">
                    <Button onClick={handleSkip}>Skip</Button>
                    <Button type="primary" onClick={handleStart}>
                        Start
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default GlobalTour;
