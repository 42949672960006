import React from "react";
import { getActiveApp } from "../utils/storage";
import useAsync from "./useAsync";
import axios from "axios";

function useRunJob(jobName){
    const { run, ...state } = useAsync();

    const runJob = React.useCallback(
        parameters => {
            const app = getActiveApp();

            if(app){
                let url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + `/jobs/${jobName}`;

                const headers = {
                    "Idengager-Application-Id": app.backendAppName || app.applicationID,
                    "Idengager-Master-Key": app.masterKey,
                };

                const promise = axios.post(url, parameters, { headers });

                run(promise);
            }
        },
        []
    );

    return {
        run: runJob,
        ...state
    }
}

export default useRunJob