import { useCallback, useEffect, useState } from "react"
import axios from "axios"

export default function useFetchAll(url = null, heads = null) {
    const [route, setRoute] = useState(url);
    const [headers, setHeaders] = useState(heads);
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchResult = useCallback(async (route) => {
        try {
            setLoading(true)
            const response = await axios.get(route, { headers: headers })
            setData(response.data)
            setRoute(null);
            setHeaders(null);
        } catch (err) {
            setError(err)
        } finally {
            setLoading(false)
        }
    }, [headers])

    useEffect(() => {
        if (route)
            fetchResult(route);
    }, [route, fetchResult])

    return { data, error, loading, setRoute, setHeaders, fetchResult }
}