const messages_columns = [
    {
        Header: 'ID',
        accessor: 'id',
    },
    {
        Header: "Description",
        accessor: "description"
    },
    {
        Header: 'Act',
        accessor: 'act',
    },
    {
        Header: 'Message',
        accessor: 'message',
    },
    {
        Header: 'Alert Header',
        accessor: 'alertHeader',
    },
    {
        Header: 'Alert Button1',
        accessor: 'alertButton1',
    },
    {
        Header: 'Alert Button2',
        accessor: 'alertButton2',
    }
];


const message_setup_form_fields = [
    {
        name: 'act',
        label: 'Act',
        type: 'text',
        rules: [],
        value: ''
    },
    {
        name: 'message',
        label: 'Message',
        type: 'text',
        rules: [
            {
                required: true,
                message: 'Please enter message',
            },
        ],
        value: ''
    },
    {
        name: 'alertHeader',
        label: 'Alert Header',
        type: 'text',
        rules: [
            {
                required: true,
                message: 'Please enter alert header',
            },
        ],
        value: ''
    },
    {
        name: 'alertButton1',
        label: 'Alert Button1',
        type: 'text',
        rules: [
            {
                required: true,
                message: 'Please enter alert button1',
            },
        ],
        value: ''
    },
    {
        name: 'alertButton2',
        label: 'Alert Button2',
        type: 'text',
        rules: [
            {
                required: true,
                message: 'Please enter alert button2',
            },
        ],
        value: ''
    }
];

export {
    messages_columns,
    message_setup_form_fields
}