import { Alert, Button, Col, Form, Input, Row, Select, Switch } from "antd";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

const initialJson = `{
    "data": {
        "alert": "type_message_here"
    },
    "where": {
        "deviceToken": ""
    }
}`;

function PushNotificationForm({ formRef, onSubmit, selectedAudience }) {
    const [appBadge, setAppBadge] = useState(false);
    const [localizeMessage, setLocalizeMessage] = useState(false);

    const onSelectMessageType = (value) => {
        if (value === "json") {
            formRef.setFieldsValue({
                message: initialJson
            });
        } else {
            formRef.setFieldsValue({
                message: ""
            });
        }
    }

    const onFinish = (values) => {
        onSubmit(values);
    };

    return (
        <Form
            className="push-notification-form"
            form={formRef}
            name="push-notification-form"
            layout="vertical"
            onFinish={onFinish}
        >
            <div className="arrow-after">
                <div className="form-group">
                    <Row align="middle">
                        <Col xs={24} sm={12}>
                            <h3 className="label">
                                <FormattedMessage id='messageTypeLabel' defaultMessage="Select Message Type" />
                            </h3>
                        </Col>
                        <Col xs={24} sm={8} offset={4} className="message-type-container">
                            <Form.Item name="message_type" rules={[{ required: true, message: 'Please enter messsage type' }]} initialValue="text">
                                <Select onSelect={onSelectMessageType}>
                                    <Select.Option value="text">
                                        <FormattedMessage id='text' defaultMessage="Text" />
                                    </Select.Option>
                                    <Select.Option value="json">
                                        <FormattedMessage id='json' defaultMessage="JSON" />
                                    </Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="arrow-after">
                <div className="form-group">
                    <h3 className="label">
                        <FormattedMessage id='messageBody' defaultMessage="Message Body" />
                    </h3>
                    <Form.Item name="message" rules={[{ required: true, message: 'Please enter notification message' }]} initialValue="">
                        <Input.TextArea className="notification-colorful" rows={4} />
                    </Form.Item>
                </div>
            </div>

            <div className="arrow-after">
                <div className="switch-container">
                    <div className="inline-form-group">
                        <Row align="middle">
                            <Col span={12}>
                                <h3 className="label">
                                    <FormattedMessage id='appBadge' defaultMessage="App Badge" />
                                </h3>
                            </Col>
                            <Col span={4} offset={6}>
                                <Form.Item name="app_badge" initialValue={false}>
                                    <Switch disabled onChange={(value) => setAppBadge(value)} /*defaultChecked*/ />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                    <hr />
                    <div className="inline-form-group">
                        <Row align="middle">
                            <Col span={12}>
                                <h3 className="label">
                                    <FormattedMessage id='localizeMessage' defaultMessage="Localize Message" />
                                </h3>
                            </Col>
                            <Col span={4} offset={6}>
                                <Form.Item name="localize_message" initialValue={false}>
                                    <Switch disabled onChange={(value) => setLocalizeMessage(value)} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>

            <div className="form-group">
                <Row align="middle">
                    <Col span={12}>
                        <div className="custom-border">
                            <div className="notification-details">
                                <h3 className="label">
                                    <FormattedMessage id='audience' defaultMessage="Audience" />:
                                </h3>
                                <p>
                                    <FormattedMessage id='sendingTo' defaultMessage="Sending To" />:
                                    <span>{(selectedAudience && selectedAudience.name) ? selectedAudience.name : ""}</span></p>
                            </div>
                            <div className="notification-details">
                                <h3 className="label">
                                    <FormattedMessage id='message' defaultMessage="Message" />:
                                </h3>
                                <p>
                                    <FormattedMessage id='badge' defaultMessage="Badge" />:
                                    <span>{appBadge ? "ON" : "OFF"}</span>
                                </p>
                                <p>
                                    <FormattedMessage id='localization' defaultMessage="Localization" />:
                                    <span>{localizeMessage ? "ON" : "OFF"}</span>
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col span={8} offset={4}>
                        <div className="notification-details">
                            <h3 className="label">
                                <FormattedMessage id='delivery' defaultMessage="DELIVERY" />:
                            </h3>
                            <p><FormattedMessage id='time' defaultMessage="Time" />: <span>Immediatly</span></p>
                            <p><FormattedMessage id='timeZone' defaultMessage="Time Zone" />: <span>GMT</span></p>
                            <p><FormattedMessage id='expiration' defaultMessage="Expiration" />: <span>Never</span></p>
                        </div>
                    </Col>
                </Row>
            </div>

            <Form.Item>
                <Button type="primary" htmlType="submit" className="flex push-notification-button">
                    <FormattedMessage id='pushNotification-submit-btn-title' defaultMessage="Push" />
                </Button>
            </Form.Item>
        </Form>
    );
}

export default PushNotificationForm;