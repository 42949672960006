import React from 'react';
import { Table, Spin, Alert } from 'antd';
import { useGetLoanLogs } from '../../hooks/useLoanLogs';

const LoanLogList = ({ loanId }) => {
  const { logs, loading, error } = useGetLoanLogs(loanId);

  if (loading) return <Spin />;
  if (error) return <Alert message="Error" description={error.message} type="error" showIcon />;

  const columns = [
    { title: 'Action', dataIndex: 'action', key: 'action' },
    { title: 'Old Value', dataIndex: 'oldValue', key: 'oldValue' },
    { title: 'New Value', dataIndex: 'newValue', key: 'newValue' },
    { title: 'Timestamp', dataIndex: 'timestamp', key: 'timestamp', render: (text) => new Date(text.iso).toLocaleString() },
    { title: 'User', dataIndex: 'user', key: 'user', render: (user) => user.username },
    { title: 'Description', dataIndex: 'description', key: 'description' },
  ];

  return <Table columns={columns} dataSource={logs} rowKey="objectId" />;
};

export default LoanLogList;
