import React from "react";
import axios from "axios";
import { clamp } from "../utils/helpers";
import StepsContainer from "../components/StepsContainer";
import validateDataSourceForm from "../utils/validateDataSourceForm";
import {
    StepsFooter,
    Aside,
    Industry as FirstStep,
    DataSource as SecondStep,
    Applications as ThirdStep,
    Complete as FourthStep
} from "../components/Boarding";
import { withPrivate } from "../components/PrivateRoute";
import useCreateApp from "../hooks/create_app";
import useGetAllSubApps from "../hooks/get_all_sub_apps";
import { useAuth } from "../components/AuthProvider";

function createApp({
    user,
    industry,
    tenantUrl,
    appImage,
    appName
}) {
    const data = {
        user: {
            __type: "Pointer",
            className: "_User",
            objectId: user.objectId
        },
        userId: user.objectId,
        masterKey: "sampleMaster",
        appName,
        url: "/idengager",
        userName: user.username,
        industry,
        appImage,
        appStatus: "Completed",
        application: ["App1", "App2", "App3"],
        connectionString: "",
        tenantUrl,
        applicationID: "sample"
    };

    return axios({
        url: process.env.REACT_APP_URL + "/idengager/classes/apps",
        method: "POST",
        data,
        headers: {
            'Content-Type': 'application/json',
            "Idengager-Application-Id": process.env.REACT_APP_AUTH_APP_ID,
            "Idengager-Master-Key": process.env.REACT_APP_AUTH_MASTER_KEY
        },
    })
}

const appsData = [
    {
        industry: "Services",
        appImage: "https://res.cloudinary.com/icloud-ready-media/image/upload/v1642335469/idEngager%20Services/app-icon-services_k84wgx.png",
        tenantUrl: "https://idengagers4.herokuapp.com/idengager",
        appName: "icloud Services Mobile App"
    },
    {
        industry: "Realestate",
        appImage: "https://res.cloudinary.com/icloud-ready-media/image/upload/v1642335146/idEngager-Real-Estate/app-icon_zqjuml.png",
        tenantUrl: "https://idengager-br-master.herokuapp.com/idengager",
        appName: "icloud Realestate Mobile App"
    },
    {
        appName: "icloud Automotive Mobile App",
        industry: "Automotive",
        appImage: "https://res.cloudinary.com/icloud-ready-media/image/upload/v1642334459/idEngager%20Automotive/App_Icon_utxvop.png",
        tenantUrl: "https://api.idengager.com/idengager"
    }
];

function createApps(user) {
    let promises = appsData.map(app => createApp({ user, ...app }));
    return Promise.all(promises);
}

const initialSetupState = {
    industry: null,
    dataSourceConfig: {
        type: "oracle-fusion",
        data: {}
    },
    applications: [],
    currentStep: 0,
    isLoading: false,
    error: {}
};

const MAX_STEPS_COUNT = 4;

function Setup() {
    const [state, setState] = React.useReducer((s, a) => ({ ...s, ...a }), initialSetupState);
    const { response, setAppData } = useCreateApp();
    const { subApps, setIndustryName } = useGetAllSubApps();
    const { user } = useAuth();

    React.useEffect(
        () => {
            const handler = e => {
                e.preventDefault();
                e.returnValue = '';
            };

            window.addEventListener("beforeunload", handler);

            return () => {
                window.removeEventListener("beforeunload", handler);
            }
        }
    );

    const setStep = React.useCallback(
        increment => {
            const step = clamp(0, state.currentStep + increment, MAX_STEPS_COUNT - 1)
            setState({ currentStep: step });
        },
        [state.currentStep]
    );

    const selectIndustry = React.useCallback(
        industry => {
            setIndustryName(industry);
            setState({ industry });
            setStep(1);
        },
        []
    );

    const setDataSourceConfig = React.useCallback(
        configData => {
            const dataSourceConfig = { ...state.dataSourceConfig, ...configData };
            setState({ dataSourceConfig });
        },
        [state.dataSourceConfig]
    );

    const selectApplications = React.useCallback(
        selectedApps => {
            setState({ applications: selectedApps });
        },
        [state.applications]
    );

    const handleDataSourceConfig = React.useCallback(
        () => {
            setState({ error: {} });

            const { isValid, error } = validateDataSourceForm(state.dataSourceConfig);

            if (isValid) {
                return setStep(1);
            }

            setState({ error });
        },
        [state.dataSourceConfig]
    );

    const handleApplications = React.useCallback(
        () => {
            console.log(state);
            if (state.isLoading) return;

            setState({ error: {} });

            if (state.applications.length) {
                setState({ isLoading: true });
                console.log(state);
                setAppData(state);
                // createApps(user).then(results => {
                //     setState({ isLoading: false });
                //     setStep(1);
                // });
            } else {
                setState({ error: { applications: "select your application" } });
            }
        },
        [state.applications]
    );

    React.useEffect(() => {
        if (response) {
            setState({ isLoading: false });
            setStep(1);
        }
    }, [response]);


    const previousStep = () => setStep(-1);

    const nextButtonHandlers = [null, handleDataSourceConfig, handleApplications];

    const prevButtonHandlers = [null, previousStep, previousStep];

    return (
        <div className="boarding-page">
            <div className="boarding-wrapper">
                <div className="boarding-content">
                    <StepsContainer currentStep={state.currentStep}>
                        <FirstStep
                            title="Select industry"
                            // description="Select your target industry"
                            selectIndustry={selectIndustry}
                            selectedIndustry={state.industry}
                        />

                        <SecondStep
                            title="Select data source"
                            // description="Your data source type"
                            setDataSourceConfig={setDataSourceConfig}
                            dataSourceConfig={state.dataSourceConfig}
                            error={state.error}
                        />

                        <ThirdStep
                            title="Applications"
                            applicationList={subApps}
                            // description="Select Your Applications"
                            selectApplications={selectApplications}
                            selectedApplications={state.applications}
                            isLoading={state.isLoading}
                            error={state.error}
                        />

                        <FourthStep
                            title="Setup Verification"
                        // description="Complete setup"
                        />
                    </StepsContainer>

                    <StepsFooter
                        prevButtonHandler={prevButtonHandlers[state.currentStep]}
                        nextButtonHandler={nextButtonHandlers[state.currentStep]}
                        currentStep={state.currentStep}
                        stepsCount={MAX_STEPS_COUNT}
                    />
                </div>

                <Aside currentStep={state.currentStep} />
            </div>

            <div className="boarding-bg"></div>
        </div>
    )
}

export default withPrivate(Setup);