import { Checkbox, Card, Typography } from "antd";
import { convertArrayToMapById } from "../../utils/helpers";
import { Spin } from "antd";

const { Text, Paragraph } = Typography;

function Applications({
    applicationList,
    selectApplications,
    selectedApplications,
    isLoading,
    error
}) {
    const set = new Set(selectedApplications.map(app => app.templateId));

    const handleSelectAll = e => {
        const applications = e.target.checked ? applicationList : [];
        selectApplications(applications);
    }

    const handleSelectApp = (isChecked, app) => {
        const newApplications = isChecked ? [...selectedApplications, app] : selectedApplications.filter(a => a.templateId !== app.templateId);
        selectApplications(newApplications);
    }

    const loading = (
        <Card className="complete-wrapper">
            <Spin size="large" className="boarding-spinner" />

            <Paragraph className="desc">
                Please wait until your <span>IDENGAGER</span> Automotive <strong>Applications creation is Done</strong>
            </Paragraph>
        </Card>
    );

    if (isLoading) return loading;

    return (
        <div className="app-options-wrapper">

            <Checkbox className="select-all" onChange={handleSelectAll}>Select All</Checkbox>

            <ul className="app-list">
                {error.applications && <Text type="danger" className="top-error">*{error.applications}</Text>}
                {
                    applicationList.map(app => (
                        <Card className="app-item boarding-card" key={app.templateId}>
                            <div className="app-logo">
                                <img src={app.appImage} alt={app.appName} />
                            </div>

                            <div className="app-description">
                                <Text>{app.appName}</Text>
                                <Text strong>{app.industry}</Text>
                            </div>

                            <Checkbox
                                className="select-app"
                                checked={set.has(app.templateId)}
                                onChange={e => handleSelectApp(e.target.checked, app)}
                            />
                        </Card>
                    ))
                }
            </ul>
        </div>
    )
}

export default Applications;