import React from "react";
import { Spin } from "antd";
import CheckIcon from "../../assets/images/check.svg";
import PlayIcon from "../../assets/images/play-icon.svg";
import { withPrivate } from "../../components/PrivateRoute";
import { SearchBar, Table, Tabs, ScheduleDropdown } from "../../components/Integration";
import "../../assets/css/integration-list.css";
import useGetAllHooks from "../../hooks/get_all_hooks";
import useGetAllTrigger from "../../hooks/get_all_triggers";

// Just for now

const filterItems = [
    { label: "All", value: "all" },
    { label: "Daily", value: "daily" },
];

const hooksScheduleColumns = [
    {
        title: "",
        width: "32px",
        render: (_, hook) => (
            <div className="icon-wrapper enabled-status">
                <img src={CheckIcon} />
            </div>
        )
    },
    {
        title: "Job Name",
        width: "240px",
        render: (_, { functionName }) => (
            <div className="box gray-box">{functionName}</div>
        )
    },
    {
        title: "URL",
        width: "260px",
        render: (_, { url }) => (
            <div className="box gray-box">{url}</div>
        )
    },
    {
        title: "Last Run",
        width: "215px",
        render: (_, hook) => (
            <div className="box white-box">15-03-2022 12:30 AM</div>
        )
    },
    {
        title: "Schedule",
        width: "135px",
        render: (_, hook) => (
            <ScheduleDropdown className="box white-box schedules" />
        )
    },
    {
        title: "Run Condition",
        width: "200px",
        render: (_, hook) => (
            <div className="box black-box">02:30 am</div>
        )
    },
    {
        title: "",
        width: "24px",
        render: (_, hook) => (
            <div className="icon-wrapper run-status-icon">
                <img src={CheckIcon} />
            </div>
        )
    },
    {
        title: "",
        width: "130px",
        render: (_, { status = "succeeded" }) => (
            <div className={`box run-status ${status}`}>{status}</div>
        )
    },
    {
        title: "",
        width: "24px",
        render: (_, hook) => (
            <div className="icon-wrapper play-icon">
                <img src={PlayIcon} />
            </div>
        )
    },
    {
        title: "",
        width: "135px",
        render: (_, hook) => (
            <div className="run-details-btn">Run Details</div>
        )
    }
];

const hooksTriggerColumns = [
    {
        title: "",
        width: "32px",
        render: (_, job) => (
            <div className="icon-wrapper enabled-status">
                <img src={CheckIcon} />
            </div>
        )
    },
    {
        title: "Trigger Name",
        width: "240px",
        render: (_, { triggerName }) => (
            <div className="box gray-box">{triggerName}</div>
        )
    },
    {
        title: "URL",
        width: "260px",
        render: (_, { url }) => (
            <div className="box gray-box">{url}</div>
        )
    },
    {
        title: "Class Name",
        width: "260px",
        render: (_, { className }) => (
            <div className="box white-box">{className}</div>
        )
    },
    {
        title: "Event",
        width: "215px",
        render: (_, job) => (
            <div className="box white-box">After Save</div>
        )
    },
    {
        title: "Last Run Status",
        width: "130px",
        render: (_, { status = "succeeded" }) => (
            <div className={`box run-status ${status}`}>{status}</div>
        )
    }
];

function ListHooks(){
    const [tab, setTab] = React.useState("schedules");
    const [searchValue, setSearchValue] = React.useState("");
    const [filterValue, setFilterValue] = React.useState("all");

    const { hooks, loading: hooksSchedulesLoading } = useGetAllHooks();

    const { triggers, loading: hooksTriggersLoading } = useGetAllTrigger();

    console.log(triggers);

    if(hooksSchedulesLoading || hooksTriggersLoading){
        return (
            <div className="loader">
                <Spin size="large" />
            </div>
        )
    }

    return (
        <div className="hooks-list integration-list">
            <h2>Hooks Management</h2>

            <Tabs
                tab={tab}
                onChangeTab={setTab}
            />

            <div className="content">
                <SearchBar
                    searchValue={searchValue}
                    filterValue={filterValue}
                    onSearchChange={setSearchValue}
                    onFilterChange={setFilterValue}
                    filterItems={filterItems}
                />

                {
                    tab === "schedules" ?
                        <Table columns={hooksScheduleColumns} dataSource={hooks} rowKey="hook-schedule" key="schedules" />
                        :
                        <Table columns={hooksTriggerColumns} dataSource={triggers} rowKey="hook-trigger" key="trigger" />
                }
            </div>
        </div>
    )
}

export default withPrivate(ListHooks);