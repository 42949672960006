import { useEffect, useState } from "react";
import { getActiveApp } from "../utils/storage";
import useFetchAll from "./fetch_all";

export default function useGetAllPushMessages() {
     /**
   * Fetches pushMessages
   * lines - maximum number of lines to fetch
   * since - only fetch lines since this Date
   */

    const { data, loading, setRoute, setHeaders } = useFetchAll();
    const [pushMessages, setPushMessages] = useState([]);
    const [reLoadPushMessages, setReLoadPushMessages] = useState(true);

    useEffect(() => {
        if (data && data.results) {
            setPushMessages(data.results);
        }
            // console.pushMessage("data"+JSON.stringify([data[0]]));
    }, [data]);

    useEffect(() => {
        if (loading === false) {
            setReLoadPushMessages(false);
        }
    }, [loading]);

    useEffect(() => {
        const app = getActiveApp();
        if (reLoadPushMessages && app) {
            const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + '/Classes/_PushStatus?order=-createdAt' ;
            const headers = {
                "Idengager-Application-Id": app.backendAppName || app.applicationID,
                "Idengager-Master-Key": app.masterKey
            };
            setHeaders(headers);
            setRoute(url);
        }
    }, [reLoadPushMessages, setRoute, setHeaders]);

    return { pushMessages, loading, setReLoadPushMessages }
}