import { Button, Form, Input, Row, Col } from 'antd';
import searchIcon from './../icons/search-color-icon.svg';
import trashIcon from './../icons/red-trash.svg';
import addIcon from './../icons/fi-rr-plus.svg';
import greenCheckIcon from './../icons/green-check.svg';
import minusCircleIcon from './../icons/minus-circle.svg';
import lightbulbIcon from './../icons/lightbulb.svg';
import { useEffect, useState } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { FormattedMessage } from 'react-intl';
import { EditOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';


function ObjectsContainer({ defActiveObject, objects, onClickDelete, onObjectSelect, setObjectData }) {
    const [selectedIndexes, setSelectedIndexes] = useState([]);
    const [activeObject, setActiveObject] = useState(defActiveObject);
    const [isVisibleModal, setIsVisibleModal] = useState(false);
    const [formRef] = Form.useForm();

    useEffect(() => {
        setActiveObject(defActiveObject);
    }, [defActiveObject])

    function selectObject(index) {
        var oldSelectedIndexes = [...selectedIndexes];
        if (!selectedIndexes.includes(index))
            oldSelectedIndexes.push(index);
        else {
            var indx = oldSelectedIndexes.findIndex(element => element === index);
            oldSelectedIndexes.splice(indx, 1);
        }
        setSelectedIndexes(oldSelectedIndexes);
    }

    function selectActiveObject(index) {
        console.log(index);
        // setActiveObject(index);
        onObjectSelect(index);
    }

    function onSubmitCallBack(formValues) {
        console.log(formValues);
        setObjectData(formValues);
        formRef.resetFields();
        setIsVisibleModal(false);
    }

    return (
        <div className="objects-container">
            <div className="obj-header">
                <img onClick={() => onClickDelete(selectedIndexes)} src={trashIcon} alt='trash-icon' />
                <img onClick={() => setIsVisibleModal(true)} src={addIcon} alt='add-icon' />
                <Input className="search-input" prefix={<img src={searchIcon} alt="search-color-icon" />} />
            </div>
            <div className="obj-body main-scroll">
                {
                    (objects && activeObject) &&
                    objects.map((object, index) => {
                        return (
                            <div className={"obj-content " + ((activeObject === index) || (activeObject.className === object.className) ? "selected" : "")} key={index}>
                                <div className="obj-title" onClick={() => selectActiveObject(index)}>
                                    <img src={lightbulbIcon} alt="lightbulb-icon" />
                                    <p>{object.className}</p>
                                </div>
                                <img onClick={() => selectObject(index)} className="selection-img" src={selectedIndexes.includes(index) ? greenCheckIcon : minusCircleIcon} alt="selection-icon" />
                            </div>
                        )
                    })
                }
            </div>
            <Modal
                className='add-object-modal'
                title={<FormattedMessage id='add-object-btn-title' defaultMessage="Add New" />}
                visible={isVisibleModal}
                footer={null}
                onCancel={() => setIsVisibleModal(false)}>
                <Form
                    className="main-form"
                    form={formRef}
                    layout="vertical"
                    onFinish={onSubmitCallBack}
                >
                    <div className='objform-content'>
                        <div className='objcontent-icon'>
                            <span>
                                <EditOutlined />
                            </span>
                            <FormattedMessage id='content' defaultMessage="Content" />
                        </div>
                        <div className='objcontent-border'>
                            <Form.Item
                                name="object_name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter object name',
                                    },
                                ]}>
                                <Input className='obj-name' placeholder="Object Name" />
                            </Form.Item>
                        </div>
                    </div>

                    <div className='objform-content'>
                        <div className='objcontent-icon'>
                            <span>
                                <EditOutlined />
                            </span>
                            <div className='wd-100'>
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        <FormattedMessage id='columns' defaultMessage="Columns" />
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className='objcontent-border'>
                            <Form.List
                                name="object_columns"
                                rules={[
                                    {
                                        validator: async (_, names) => {
                                            if (!names || names.length < 1) {
                                                return Promise.reject(new Error('Please enter at least 1 column'));
                                            }
                                        },
                                    },
                                ]}
                            >
                                {(fields, { add, remove }, { errors }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Form.Item
                                                required={false}
                                                key={field.key}
                                            >
                                                <Row gutter={[16, 0]}>
                                                    <Col span={22}>
                                                        <Form.Item
                                                            {...field}
                                                            validateTrigger={['onChange', 'onBlur']}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    whitespace: true,
                                                                    message: "Please enter column's name or delete this field.",
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder="Column Name" />
                                                        </Form.Item>
                                                    </Col>
                                                    {fields.length > 0 ? (
                                                        <Col span={2}>
                                                            <span onClick={() => remove(field.name)} className='obj-actions'>
                                                                <MinusOutlined />
                                                            </span>
                                                        </Col>
                                                    ) : null}
                                                </Row>
                                            </Form.Item>
                                        ))}
                                        <Form.Item>
                                            <Button
                                                type="dashed"
                                                onClick={() => add()}
                                                style={{ width: '50%' }}
                                                icon={<PlusOutlined />}
                                            >
                                            </Button>
                                            <Form.ErrorList errors={errors} />
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </div>
                    </div>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="obj-button">
                            <FormattedMessage id='create' defaultMessage="Create" />
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default ObjectsContainer;