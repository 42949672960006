import React from "react";
import useAsync from "./useAsync";
import axios from "axios";
import { getActiveApp } from "../utils/storage";

const getAppInfo = async () => {
    const app = getActiveApp();
    const url = ((app.appURL? app.appURL+"/idengager" : null) || app.tenantUrl) + "/appInfo";
    const res = await axios.get(url, {
        headers: { 
            "Idengager-Application-Id": app.backendAppName || app.applicationID,
            "Idengager-Master-Key": app.masterKey,    
        }
    });
    return res.data;
}

function useGetAppInfo(){
    const {
        data,
        isLoading,
        run
    } = useAsync();

    React.useEffect(
        () => {
            run(getAppInfo())
        },
        []
    );

    return {
        data,
        isLoading
    }
}

export default useGetAppInfo;