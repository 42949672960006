import { useCallback, useEffect, useState } from "react";
import axios from "axios";

export default function useUpdateUser() {
    const [updatedUserData, setUpdatedUserData] = useState(null);
    const [updateResponse, setUpdateResponse] = useState(null);
    const [updateError, setUpdateError] = useState(null);
    const [loading, setLoading] = useState(false);

    const callAPI = useCallback(async (updatedUserData) => {
        setUpdateResponse(null);
        setUpdateError(null);
        try {
            const url = process.env.REACT_APP_URL + "/idengager/users/" + updatedUserData.objectId
            const headers = {
                "Idengager-Application-Id": process.env.REACT_APP_AUTH_APP_ID,
                "Idengager-Master-Key": process.env.REACT_APP_AUTH_MASTER_KEY
            };
            setLoading(true);
            const response = await axios.put((url), updatedUserData, { headers: headers });
            setUpdateResponse(response.data);
            setUpdateError(null);
        } catch (err) {
            if (err.response) {
                setUpdateError(err.response.data.error);
            }
        } finally {
            setLoading(false);
            setUpdatedUserData(null);
        }
    }, []);

    useEffect(() => {
        if (updatedUserData) {
            delete updatedUserData.createdAt
            delete updatedUserData.sessionToken
            delete updatedUserData.updatedAt
            callAPI(updatedUserData);
        }
    }, [updatedUserData, callAPI])

    return { updateResponse, updateError, loading, setUpdatedUserData }
}