export const columns = [

    {
        Header: 'className',
        accessor: 'className',
    },
    {
        Header: 'url',
        accessor: 'url',
    },
    {
        Header: 'triggerName',
        accessor: 'triggerName',
    }
];