const image_columns = [
    {
        Header: 'ID',
        accessor: 'id',
    },
    {
        Header: "Description",
        accessor: "description"
    },
    {
        Header: "Image Attribute 1",
        accessor: "imageAttr1",
        Cell: props => {
            return (
                <div className="color-view" style={{ backgroundColor: props.value }}></div>
            );
        }
    },
    {
        Header: 'Image ID',
        accessor: 'imageId',
    },
    {
        Header: 'Image URL',
        accessor: 'imageUrl',
        // Will Be Uncommented When Preview Image
        Cell: (props) => {
            console.log(props);
            return (
                props.value && 
                <a href={props.value}>
                    <img src={props.value} style={{
                        width: "25px",
                        height: "25px",
                        borderRadius: "50%"
                    }} />
                </a>
            );
        },
    },
    {
        Header: 'Object ID',
        accessor: 'objectId',
    },
    {
        Header: 'Language ID',
        accessor: 'languageId',
    },
    {
        Header: 'Image Type ID',
        accessor: 'imageTypeId',
    }
];

const images_form_fields = [
    {
        name: 'imageUrl',
        label: 'Image URL',
        type: 'image',
        rules: [
            {
                required: true,
                message: 'Please enter image url',
            },
        ],
        value: ''
    },
    {
        name: 'languageId',
        label: 'Language Id',
        type: 'text',
        rules: [
            {
                required: true,
                message: 'Please enter language id',
            },
        ],
        value: ''
    },
    {
        name: 'imageTypeId',
        label: 'Image Type Id',
        type: 'text',
        rules: [
            {
                required: true,
                message: 'Please enter image type id',
            },
        ],
        value: ''
    },
    {
        name: 'imageAttr1',
        label: 'Image Attribute 1',
        type: 'text',
        rules: [
            {
                required: true,
                message: 'Please enter image attribute 1',
            },
        ],
        value: ''
    },
    {
        name: 'imageAttr2',
        label: 'Image Attribute 2',
        type: 'text',
        rules: [
            {
                required: true,
                message: 'Please enter image attribute 2',
            },
        ],
        value: ''
    },
    {
        name: 'imageAttr3',
        label: 'Image Attribute 3',
        type: 'text',
        rules: [
            {
                required: true,
                message: 'Please enter image attribute 3',
            },
        ],
        value: ''
    },
    {
        name: 'imageAttr4',
        label: 'Image Attribute 4',
        type: 'text',
        rules: [
            {
                required: true,
                message: 'Please enter image attribute 4',
            },
        ],
        value: ''
    },
    {
        name: 'imageAttr5',
        label: 'Image Attribute 5',
        type: 'text',
        rules: [
            {
                required: true,
                message: 'Please enter image attribute 5',
            },
        ],
        value: ''
    },
];

export {
    image_columns,
    images_form_fields
}