export const getLeadInterest = (lead) => {
    if(lead.catalog) {
        return {
            type: "Catalog",
            description: lead.catalog.RecordName
        }
    }

    if(lead.promotion) {
        return {
            type: "Promotion",
            description: lead.promotion.RecordName
        }
    }

    if(lead.alert) {
        return {
            type: "Alert",
            description: lead.alert.RecordName
        }
    }

    return {
        type: "...",
        description: "..."
    }
}